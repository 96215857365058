import { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { faSearch, faArrowRotateRight, faAdd, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../utils";
import WarehouseWrapper from "../WarehouseWrapper";
import InputField from "../../../../../components/form/InputField";
import TableStd from '../../../../../components/ui/TableStd';
import PaginationU from '../../../../../components/ui/PaginationU';
import LimitU from '../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../components/ui/DataNotFound';
import ButtonU from "../../../../../components/ui/ButtonU";
import SortU from '../../../../../components/ui/SortU';
import Filter from "./Filter";
import ToastU from "../../../../../components/ui/ToastU";
import Status from "../../../../../components/ui/Status";
import DropdownU from "../../../../../components/ui/DropdownU";
import Create from "./Create";
import { useTranslation } from "react-i18next";
import Keys from "../../../../../utils/constants";

const Audits = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [audits, setAudits] = useState([]);
    const [auditsCount, setAuditsCount] = useState();
    const [modalCreate, setModalCreate] = useState(false);

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['updatedAt', 'DESC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('WAREHOUSE'), link: '/warehouse/warehouses' } ,
        { title: t('AUDITS'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getAudits();
        }
    }, [ready, query]);
    
    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }
    
    const getAudits = () => {
        setLoading(true);
        axiosPrivate.get('/audits', { params: query }).then((res) => {
            if (res.status == 200) {
                setAudits(res.data.data);
                setAuditsCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery({...query, filter: { invoiceNumber: find }});
    }, [find]);

    const createNew = () => { setModalCreate(true) }
    
    const refresh = () => { getAudits() }

    const modelProps = [ 
        { 
            name: <SortU title={t('NUMBER')} attr={'number'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Link to={`/warehouse/audits/${prop.id}`} className='no-decor'>{prop.number}</Link>) } 
        },
        {
            name: <SortU title={t('TOTAL')} attr={'total'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.total ? prop?.total?.toFixed(2) : 0} {Keys.TMT}</>) }
        },
        { 
            name: t('RESPONSIBLE'),
            value: (prop) => { return(<>{prop.user?.fullName}</>) } 
        },
        {
            name: <SortU title={t('STATUS')} attr={'closed'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Status status={prop.closed}/>) }
        },
        {
            name: <SortU title={t('NOTE')} attr={'note'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.note}</>) }
        },
        {
            name: <SortU title={t('UPDATED_AT')} attr={'updatedAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.updatedAt)}</>) }
        },
        {
            name: <SortU title={t('REGISTRATION_DATE')} attr={'createdAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.createdAt)}</>) }
        },
        {
            name: "",
            value: (prop) => {
                const options = [
                    {
                        name: t('SHOW'),
                        icon: faEye,
                        color: "text-dark",
                        onClick: () => { navigate(`/warehouse/audits/${prop.id}`) },
                    }
                ]
                return (
                    <DropdownU className='d-flex align-items-center justify-content-center' toggleClass={"card-8-outline py-1 px-1"} icon={faEllipsisV} items={options}/>
                )
            }
        }
    ];

    return (
        <WarehouseWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('AUDITS')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('AUDIT')} ${t('NUMBER')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    <ButtonU onSubmit={createNew} className={'border-success fg-green-500'} icon={faAdd} text={t('ADD')}/>
                </div>
            </div>
            { auditsCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={audits} count={auditsCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={auditsCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            <Create
                modalShow={modalCreate}
                setModalShow={setModalCreate}
                toast={toast}
                setToast={setToast}
                setLoading={setLoading}
            /><ToastU toast={toast} setToast={setToast}/>
        </WarehouseWrapper>
    );
}

export default Audits;