import Keys from "../../utils/constants";
import { useTranslation } from 'react-i18next';

const SelectLabeled = ({...props}) => {
    const { t } = useTranslation();

    const handleChange = (event) => {
        event.preventDefault();
        props.setValue(event.target.value);
    }

    return (
        <div className={`d-flex flex-column my-1 ${props.className}`}>
            <div className="d-flex flex-row mb-1">
                <span className="medium font-13 fg-dark me-1">{props.label}</span>
                <span className="medium font-13 fg-red-500">{props.required && '*'}</span>
            </div>
            <select
                size={props.size}
                value={props.value}
                onChange={handleChange}
                className={`input-field py-1 font-13 bg-light`}>
                <option value={""} >{'...'}</option>
                { props.items.map((item) => {
                    return (<option value={item.id} key={item.id}>{item.name}</option>)
                }) }
            </select>
        </div>
    );
}

export default SelectLabeled;