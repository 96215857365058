import { useEffect, useState } from "react";
import useAuth from "../../../../../hooks/useAuth";
import Utils from "../../../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import Keys from "../../../../../utils/constants";
import DataWrapper from "../DataWrapper";
import ToastU from "../../../../../components/ui/ToastU";
import AdminVerification from "../../../../../components/ui/AdminVerification";
import Option from "./../../../components/Option";
import icUsersRed from "../../../../../assets/svg/ic_users_red.svg";
import icTagUserBlue from "../../../../../assets/svg/ic_tag_user_blue.svg";
import icDocumentPink from "../../../../../assets/svg/ic_document_pink.svg";
import icDbGreen from "../../../../../assets/svg/ic_db_green.svg";
import { useTranslation } from "react-i18next";

const Administration = () => {
    const { t } = useTranslation();
    const { ready, auth } = useAuth();
    const [allowed, setAllowed] = useState(false);
    const { tempUnlocked } = useAuth();
    const [adminVerificationModal, setAdminVerificationModal] = useState(false);

    useEffect(() => {
        if (ready) setAllowed(Utils.allowedRole(auth, '1007', 'read'));
    }, [ready, allowed]);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        if (!tempUnlocked) setAdminVerificationModal(true);
    }, [tempUnlocked]);

    const breadcrumbs = [
        { title: t('SETTINGS'), link: '/settings/profile'}, { title: t('DATABASE'), link: '/settings/data/general'}, { title: t('ADMINISTRATION'), active: true }
    ]

    const options = [
        { id: 'users', name: t('USERS'), icon: icUsersRed, link: '/settings/data/administration/users'},
        { id: 'roles', name: t('ROLES'), icon: icTagUserBlue, link: '/settings/data/administration/roles'},
        { id: 'logs', name: t('LOGS'), icon: icDocumentPink, link: '/settings/data/administration/logs'},
        { id: 'backup', name: t('BACKUP'), icon: icDbGreen, link: '/settings/data/administration/backup'},
    ]

    return (
        <DataWrapper breadcrumbs={breadcrumbs}>
            <div className="d-flex flex-row justify-content-between">
                <span></span>
                <div className="d-flex align-items-center mt-3 me-3">
                    <span className="badge bg-red-400 p-2 text-white">
                        <FontAwesomeIcon className="me-2" icon={faTriangleExclamation}/>
                        {t('SECTION_FOR_ADMIN')}
                    </span>
                </div>
            </div>
            {(tempUnlocked && allowed) &&
                <div className="row p-3">
                    { options.map((option) => {
                        return (<Option key={option.id} item={option}/>)
                    })}
                </div>
            }
            <ToastU toast={toast} setToast={setToast}/>
            <AdminVerification
                show={adminVerificationModal}
                onHide={setAdminVerificationModal}
                appName={Keys.ADMINISTRATION}
                toast={toast}
                setToast={setToast}
            />
            <div className="mb-5"></div>
        </DataWrapper>
    );
}

export default Administration;