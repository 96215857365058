import { Link } from "react-router-dom";
import Keys from "../../../../../utils/constants";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

const CardTotal = ({...props}) => {
    const { t } = useTranslation();
    
    return (
        <div className="col-md-3">
            <div className="d-flex flex-column align-items-center rounded-1 shadow px-4 pb-4 pt-2">
                <div className="d-flex flex-row justify-content-between col-md-12">
                    <span className="fg-gray-100 font-13">{props.leftTitle}</span>
                    <Link to={props.link}><FontAwesomeIcon icon={faArrowUpRightFromSquare} className="font-12 fg-gray-100"/></Link>
                </div>
                <img src={props.icon} className={`wh-35 rounded-circle p-2 ${props.bg}`} />
                <span className="bold fs-1 fg-dark">
                    {(props.value ? props.value : 0)}
                </span>
                <span className="text-secondary">{props.title}</span>
            </div>
        </div>
    );
}

export default CardTotal;