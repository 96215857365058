import Keys from "../../../../../../utils/constants";

const InputNumPad = ({...props}) => {

    const handleChange = (event) => {
        event.preventDefault();
        const input = event.target.value;
        props.setValue(input);

        if (input && Keys.DIGITS.includes(input.at(-1))) {
            switch(input.at(-1)) {
                case '0': 
                    if (input.at(0) == '0' && input.at(1) == '0')
                        props.setValue(input.slice(0, -1));
                    break;
                case '.': 
                    if (input.at(0) == '.')
                        props.setValue(input.slice(0, -1));
                    else if (input.length > 1 && props.value.includes('.'))
                        props.setValue(input.slice(0, -1));
                    break;
                default:
                    if (input.at(0) == '0' && input.at(1) != '.')
                        props.setValue(input.slice(0, -1));
                    break;
            }
        } 
        else props.setValue(input.slice(0, -1));
    }

    return (
        <input
            type={props.type}
            ref={props.innerRef}
            value={props.value}
            onChange={handleChange}
            className={`rounded-1 border px-2 py-1 my-1 font-20 text-end input-inner-shadow ${props.bg ? props.bg : 'bg-gray-50'}`}
            placeholder={'[F2]'}
            disabled={props.lockNumPad}/>
    );
}

export default InputNumPad;