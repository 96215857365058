import { useEffect, useState } from "react";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import useAuth from "../../../../../../hooks/useAuth";
import ModalU from '../../../../../../components/ui/ModalU';
import Keys from "../../../../../../utils/constants";
import icArrowRighLeft from "../../../../../../assets/svg/ic_arrow_right_left.svg";
import AdminVerification from "../../../../../../components/ui/AdminVerification";
import ToastU from "../../../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";

const ModalDiscount = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate(); 
    const sale = props.sale;
    const { setTempUnlocked } = useAuth();
    const [adminVerificationModal, setAdminVerificationModal] = useState(false);
    
    const [inPercent, setInPercent] = useState();
    const [inAmount, setInAmount] = useState();
    const [isPercentInput, setIsPercentInput] = useState(false);
    const [isAmountInput, setIsAmountInput] = useState(false);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        if (sale.id && isPercentInput) {
            const discount = (inPercent / 100) * sale.total;
            setInAmount(discount.toFixed(2));
            setIsPercentInput(false);
        }
    }, [inPercent]);

    useEffect(() => {
        if (sale.id && isAmountInput) {
            const discount = (inAmount / sale.total) * 100;
            setInPercent(discount.toFixed(2));
            setIsAmountInput(false);
        }
    }, [inAmount]);

    const handlePercentChange = (event) => {
        const value = event.target.value;
        if (value > 100) return;
        setInPercent(value);
        setIsPercentInput(true);
    };

    const handleAmountChange = (event) => {
        const value = event.target.value;
        if (value > sale.total) return;
        setInAmount(event.target.value);
        setIsAmountInput(true);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!inPercent || !inAmount) return;
        setAdminVerificationModal(true);
    }

    const setOnSuccess = () => {
        setTempUnlocked(false);

        const data = { discountExtra: parseFloat(inPercent) };
        axiosPrivate.put('/sales/update/'+sale.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.refresh()});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('DISCOUNT')}
            actionbtntext={t('CONFIRM')}
            actionbtnclassname={"bg-orange-400 font-14 medium text-white border-warning"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit} className="row d-flex flex-row align-items-center justify-content-center bg-red-400 py-4 mx-0">
                <div className={`d-flex flex-column my-1 col-md-5`}>
                    <span className="medium font-14 text-white mb-1">{t('PERCENT')} (%)</span>
                    <input type={'number'} value={inPercent} onChange={handlePercentChange} className={`rounded-2 border px-2 py-1 font-14 bg-gray-50'}`} placeholder={'10'}/>
                </div>
                <div className="d-flex justify-content-center col-md-1">
                    <img src={icArrowRighLeft} alt="arrow" className="wh-20 bg-white rounded-circle p-1"/>
                </div>
                <div className={`d-flex flex-column my-1 col-md-5`}>
                    <span className="medium font-14 text-white mb-1">{t('AMOUNT')} ({Keys.TMT})</span>
                    <input type={'number'} value={inAmount} onChange={handleAmountChange} className={`rounded-2 border px-2 py-1 font-14 bg-gray-50'}`} placeholder={'5'}/>
                </div>
            </form>
            <ToastU toast={toast} setToast={setToast}/>
            <AdminVerification
                show={adminVerificationModal}
                onHide={setAdminVerificationModal}
                appName={Keys.CP}
                setOnSuccess={setOnSuccess}
                toast={toast}
                setToast={setToast}
            />
        </ModalU>
    );
}

export default ModalDiscount