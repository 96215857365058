import React, { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Keys from '../../utils/constants';
import Config from '../../config';
import Utils from '../../utils';
import { useTranslation } from 'react-i18next';
import PaymentTypes from './PaymentTypes';

const PrintReceipt = ({...props}) => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const PAYMENT_TYPES = PaymentTypes();
    
    const [contact, setContact] = useState({});
    const sale = props.sale;
    const saleItems = props.saleItems;
    const saleItemsCount = props.saleItems ? props.saleItems.length : 0;

    useEffect(() => {
        if (ready) {
            getContact();
        }
    }, [ready]);

    const getContact = () => {
        const query = { limit:1, filter: { id: Config.COMPANY_CONTACT_ID } }

        axiosPrivate.get('/contacts', { params: query }).then((res) => {
            if (res.status == 200) {
                setContact(res.data.data[0]);
            }
        });
    }

    return (
        <div id='receipt' className='receipt visible-on-print'>
            <div className='d-flex flex-column'>
                <span className='bold font-16 text-center mb-2'>{contact?.company}</span>
                <div className='d-flex flex-row justify-content-between pt-1' style={{borderTop: "dashed 1px black"}}>
                    <span className='font-12 medium'>{t('SALE_NO')}: {sale?.number}</span>
                    <span className='font-12'>{Utils.getTimestampDateHm(sale?.updatedAt)}</span>
                </div>
                <div className='d-flex flex-row justify-content-between'>
                    <span className='font-12'>{t('PAYMENT_TYPE')}:</span>
                    <span className='font-12'>{PAYMENT_TYPES.get(sale?.paymentType)}</span>
                </div>
                <div className='d-flex flex-row justify-content-between'>
                    <span className='font-12'>{t('CASHIER')}:</span>
                    <span className='font-12'>{sale?.cashier?.firstName}</span>
                </div>
                {sale.note && <div className='d-flex flex-row justify-content-between'>
                    <span className='font-12'>{t('NOTE')}:</span>
                    <span className='font-12 ms-2'>{sale?.note}</span>
                </div>}
            </div>
            <table className='my-1 w-100'>
                <thead style={{borderTop: "dashed 1px black"}}>
                    <tr>
                        <th className='small'>{t('NAME')}</th>
                        <th className='small'>{t('QUANTITY')}</th>
                        <th className='small'>{t('PRICE')}</th>
                        <th className='small'>{t('TOTAL')}</th>
                    </tr>
                </thead>
                <tbody className='my-1' style={{borderTop: "dashed 1px black", borderBottom: "dashed 1px black"}}>
                    {saleItemsCount > 0 && saleItems.map((item) => {
                        return(
                            <tr key={item.id}>
                                <td className='pt-1'>{item?.product?.name}</td>
                                <td className='py-1'>x{item?.quantity?.toFixed(2)} {item?.unit?.shortName}</td>
                                <td className='py-1'>{item?.sellingPrice?.toFixed(2)}</td>
                                <td className='py-1'>{item?.total?.toFixed(2)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className='d-flex flex-column'>
                <div className='d-flex flex-row justify-content-between'>
                    <span className='font-12'>{t('PAID')}:</span>
                    <span className='font-12'>{sale?.paid ? parseFloat(sale?.paid)?.toFixed(2) : 0} {Keys.TMT}</span>
                </div>
                <div className='d-flex flex-row justify-content-between'>
                    <span className='font-12'>{t('YOUR_CHANGE')}:</span>
                    <span className='font-12'>{sale?.change ? parseFloat(sale?.change)?.toFixed(2) : 0} {Keys.TMT}</span>
                </div>
                <div className='d-flex flex-row justify-content-between'>
                    <span className='font-12'>{t('DISCOUNT')}:</span>
                    <span className='font-12'>{sale?.discount ? parseFloat(sale?.discount)?.toFixed(2) : 0} {Keys.TMT}</span>
                </div>
                <hr className='my-1'/>
                <div className='d-flex flex-row justify-content-between'>
                    <span className='font-12 medium'>{t('TOTAL')} {PAYMENT_TYPES.get(sale?.paymentType)}:</span>
                    <span className='font-14 bold'>{sale?.total?.toFixed(2)} {Keys.TMT}</span>
                </div>
            </div>
            <div className='d-flex flex-column align-items-center justify-content-center' style={{borderTop: "dashed 1px black"}}>
                <span className='font-12 medium text-center px-5 pt-1'>{t('THANK_YOU_FOR_SHOPPING')}</span>
                <span className='font-10 px-2 text-center'>{contact?.addressLine}</span>
                <span className='font-10 px-2'>{Keys.PHONE_CODE} {contact?.phoneNumber}</span>
                <span className='font-10 px-2'>{contact?.website}</span>
            </div>
        </div>
    );
}

export default PrintReceipt;