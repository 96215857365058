import ReactApexChart from 'react-apexcharts';
import { useTranslation } from "react-i18next";
import Keys from '../../../../../utils/constants';

const SaleAreaChart = ({...props}) => {
  const { t } = useTranslation();

  const series = [{
    name: t('SALES'),
    data: props?.seriesTotals || []
  }]

  const options = {
    chart: {
      type: 'area',
      height: 350,
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    
    title: {
      text: t('SALES_BY_DATE'),
      align: 'left',
      style: {
        color:  '#505050'
      },
    },
    subtitle: {
      text: `${t('TOTAL_DAILY_SALE')} (${Keys.TMT})`,
      align: 'left',
      style: {
        color:  '#505050'
      },
    },
    labels: props?.seriesDates || [],
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      opposite: true
    },
    legend: {
      horizontalAlign: 'left'
    }
  };


  return (
    <div className="col-md-6" id="chart">
      <ReactApexChart options={options} series={series} type="area" height={270} className={'shadow-sm'}/>
    </div>
  );
}

export default SaleAreaChart;