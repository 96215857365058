import { useEffect } from 'react';
import useAuth from './hooks/useAuth';
import useRefreshToken from './hooks/useRefreshToken';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import PageNotFound from './pages/PageNotFound';
import SomethinWentWrong from './pages/SomethingWentWrong';
import Login from './pages/Login/Login';

import Dashboard from './pages/Main/pages/Dashboard';

import Profile from './pages/Settings/pages/Profile';
import PrivacyAndSecurity from './pages/Settings/pages/PrivacyAndSecurity';
import Appearance from './pages/Settings/pages/Appearance';

import SettingsDataAdministration from './pages/Settings/pages/Data/Administration';
import Users from './pages/Settings/pages/Data/Administration/Users';
import User from './pages/Settings/pages/Data/Administration/Users/User';
import UserCreate from './pages/Settings/pages/Data/Administration/Users/User/Create';
import Logs from './pages/Settings/pages/Data/Administration/Logs';
import Roles from './pages/Settings/pages/Data/Administration/Roles';
import Backup from './pages/Settings/pages/Data/Administration/Backup';

import SettingsDataGeneral from './pages/Settings/pages/Data/General';
import Brands from './pages/Settings/pages/Data/General/Brands';
import Categories from './pages/Settings/pages/Data/General/Categories';
import Countries from './pages/Settings/pages/Data/General/Countries';
import Districts from './pages/Settings/pages/Data/General/Districts';
import Manufacturers from './pages/Settings/pages/Data/General/Manufacturers';
import Provinces from './pages/Settings/pages/Data/General/Provinces';
import Units from './pages/Settings/pages/Data/General/Units';

import Products from './pages/Main/pages/Product/Products';
import Product from './pages/Main/pages/Product/Products/Product';
import ProductCreate from './pages/Main/pages/Product/Products/Product/Create';
import ProductUpdate from './pages/Main/pages/Product/Products/Product/Update';

import Invoices from './pages/Main/pages/Invoice/Invoices';
import Invoice from './pages/Main/pages/Invoice/Invoices/Invoice';
import InvoiceCreate from './pages/Main/pages/Invoice/Invoices/Create';
import InvoiceItems from './pages/Main/pages/Invoice/InvoiceItems';
import InvoiceReturns from './pages/Main/pages/Invoice/InvoiceReturns';
import InvoiceReturnCreate from './pages/Main/pages/Invoice/InvoiceReturns/Create';

import Warehouses from './pages/Main/pages/Warehouse/Warehouses';
import WrittenOffProducts from './pages/Main/pages/Warehouse/WrittenOffProducts';
import WrittenOffProductCreate from './pages/Main/pages/Warehouse/WrittenOffProducts/Create';
import Audits from './pages/Main/pages/Warehouse/Audits';
import Audit from './pages/Main/pages/Warehouse/Audits/Audit';

import Branches from './pages/Main/pages/Company/Branches';
import Suppliers from './pages/Main/pages/Company/Suppliers';
import Contacts from './pages/Main/pages/Company/Contacts';

import Sales from './pages/Main/pages/Finance/Sales';
import Sale from './pages/Main/pages/Finance/Sales/Sale';
import SaleItems from './pages/Main/pages/Finance/SaleItems';
import ReturnedSales from './pages/Main/pages/Finance/ReturnedSales';
import ReturnedSale from './pages/Main/pages/Finance/ReturnedSales/ReturnedSale';
import Reports from './pages/Main/pages/Finance/Reports';

import CpMain from './pages/CashierPanel/pages/Main';
import CpSales from './pages/CashierPanel/pages/Main/Sales/All';
import CpSale from './pages/CashierPanel/pages/Main/Sales/Sale';
import CpReturnedSales from './pages/CashierPanel/pages/Main/ReturnedSales/All';
import CpReturnedSale from './pages/CashierPanel/pages/Main/ReturnedSales/ReturnedSale';
import CpOpenSale from './pages/CashierPanel/pages/Main/NewSale';
import CpNewSaleItemReturn from './pages/CashierPanel/pages/Main/NewSaleItemReturn';

function App() {
  const { auth } = useAuth();
  const refresh = useRefreshToken();

  useEffect(() => {
    refresh();
  }, [auth.token]);
  
  return (
    <BrowserRouter>
      <Routes>
        {/* public routes */}
        <Route path='/something-went-wrong' element={<SomethinWentWrong />} />
        <Route path='/auth/signin' element={<Login />} />

        <Route path='/' element={<Dashboard/>} />

        {/* protected routes */}
        
        <Route path='/settings/profile' element={<Profile />} />
        <Route path='/settings/privacy-and-security' element={<PrivacyAndSecurity />} />
        <Route path='/settings/appearance' element={<Appearance />} />

        <Route path='/settings/data/administration' element={<SettingsDataAdministration />} />
        <Route path='/settings/data/administration/users' element={<Users />} />
        <Route path='/settings/data/administration/users/:id' element={<User />} />
        <Route path='/settings/data/administration/users/create' element={<UserCreate />} />
        <Route path='/settings/data/administration/logs' element={<Logs />} />
        <Route path='/settings/data/administration/roles' element={<Roles />} />
        <Route path='/settings/data/administration/backup' element={<Backup />} />

        <Route path='/settings/data/general' element={<SettingsDataGeneral />} />
        <Route path='/settings/data/general/brands' element={<Brands />} />
        <Route path='/settings/data/general/categories' element={<Categories />} />
        <Route path='/settings/data/general/countries' element={<Countries />} />
        <Route path='/settings/data/general/districts' element={<Districts />} />
        <Route path='/settings/data/general/manufacturers' element={<Manufacturers />} />
        <Route path='/settings/data/general/provinces' element={<Provinces />} />
        <Route path='/settings/data/general/units' element={<Units />} />

        <Route path='/product/products' element={<Products />} />
        <Route path='/product/products/:id' element={<Product />} />
        <Route path='/product/products/create' element={<ProductCreate/>} />
        <Route path='/product/products/update/:id' element={<ProductUpdate/>} />

        <Route path='/invoice/invoices' element={<Invoices/>} />
        <Route path='/invoice/invoices/:id' element={<Invoice/>} />
        <Route path='/invoice/invoices/create' element={<InvoiceCreate/>} />
        <Route path='/invoice/invoice-items' element={<InvoiceItems/>} />
        <Route path='/invoice/invoice-returns' element={<InvoiceReturns/>} />
        <Route path='/invoice/invoice-returns/create' element={<InvoiceReturnCreate/>} />

        <Route path='/warehouse/warehouses' element={<Warehouses />} />
        <Route path='/warehouse/written-off-products' element={<WrittenOffProducts/>} />
        <Route path='/warehouse/written-off-products/create' element={<WrittenOffProductCreate/>} />
        <Route path='/warehouse/audits' element={<Audits/>} />
        <Route path='/warehouse/audits/:id' element={<Audit/>} />

        <Route path='/company/branches' element={<Branches />} />
        <Route path='/company/suppliers' element={<Suppliers />} />
        <Route path='/company/contacts' element={<Contacts />} />

        <Route path='/finance/sales' element={<Sales />} />
        <Route path='/finance/sales/:id' element={<Sale />} />
        <Route path='/finance/sale-items' element={<SaleItems />} />
        <Route path='/finance/returned-sales' element={<ReturnedSales />} />
        <Route path='/finance/returned-sales/:id' element={<ReturnedSale />} />
        <Route path='/finance/reports' element={<Reports />} />

        <Route path='/cp' element={<CpMain />} />
        <Route path='/cp/sales' element={<CpSales />} />
        <Route path='/cp/sales/:id' element={<CpSale />} />
        <Route path='/cp/returned-sales' element={<CpReturnedSales />} />
        <Route path='/cp/returned-sales/:id' element={<CpReturnedSale />} />
        <Route path='/cp/sales/open/:id' element={<CpOpenSale />} />
        <Route path='/cp/new-sale-item-return' element={<CpNewSaleItemReturn />} />

        {/* catch all */}
        <Route path='/page-not-found' element={<PageNotFound />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;