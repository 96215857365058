import { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { faSearch, faArrowRotateRight, faAdd } from "@fortawesome/free-solid-svg-icons";
import Utils from "../../../../../utils";
import WarehouseWrapper from "../WarehouseWrapper";
import InputField from "../../../../../components/form/InputField";
import TableStd from '../../../../../components/ui/TableStd';
import PaginationU from '../../../../../components/ui/PaginationU';
import LimitU from '../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../components/ui/DataNotFound';
import ButtonU from "../../../../../components/ui/ButtonU";
import SortU from '../../../../../components/ui/SortU';
import Filter from "./Filter";
import ToastU from "../../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";
import Keys from "../../../../../utils/constants";

const WrittenOffProducts = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [writtenOffProducts, setWrittenOffProducts] = useState([]);
    const [writtenOffProductsCount, setWrittenOffProductsCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['createdAt', 'DESC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('WAREHOUSE'), link: '/warehouse/warehouses' } ,
        { title: t('WRITTEN_OFF_PRODUCTS'), active: true},
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getWrittenOffProducts();
        }
    }, [ready, query]);
    
    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }
    
    const getWrittenOffProducts = () => {
        setLoading(true);
        axiosPrivate.get('/written-off-products', { params: query }).then((res) => {
            if (res.status == 200) {
                setWrittenOffProducts(res.data.data);
                setWrittenOffProductsCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery({...query, filter: { productName: find }});
    }, [find]);

    const createNew = () => { navigate('/warehouse/written-off-products/create'); }
    
    const refresh = () => { getWrittenOffProducts() }

    const modelProps = [ 
        {
            name: t('BAR_CODE'),
            value: (prop) => { return(<>{prop?.product?.barcode}</>) }
        },
        {
            name: t('PRODUCT'),
            value: (prop) => { return(<Link to={`/product/products/${prop?.product?.id}`} className="no-decor">{prop?.product?.name}</Link>) }
        },
        {
            name: <SortU title={t('QUANTITY')} attr={'quantity'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.quantity?.toFixed(2)} {prop?.product?.unit?.name}</>) }
        },
        {
            name: <SortU title={t('UNIT_PRICE')} attr={'unitPrice'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.unitPrice?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: <SortU title={`${t('TOTAL')} ${t('UNIT_PRICE')}`} attr={'total'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.total?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: <SortU title={t('DEFINITION')} attr={'description'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.description ? prop.description : '-'}</>) }
        },
        {
            name: <SortU title={t('REGISTRATION_DATE')} attr={'createdAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.createdAt)}</>) }
        }
    ];

    return (
        <WarehouseWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('WRITTEN_OFF_PRODUCTS')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('PRODUCT')} ${t('NAME')}, ${t('BARCODE')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    <ButtonU onSubmit={createNew} className={'border-success fg-green-500'} icon={faAdd} text={t('ADD')}/>
                </div>
            </div>
            { writtenOffProductsCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={writtenOffProducts} count={writtenOffProductsCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={writtenOffProductsCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            <ToastU toast={toast} setToast={setToast}/>
        </WarehouseWrapper>
    );
}

export default WrittenOffProducts;