import Keys from "../../../../../../utils/constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Footer = ({...props}) => {
    const { t } = useTranslation();
    
    const [total, setTotal] = useState(props.total);

    useEffect(() => {
        setTotal(props.total)
    }, [props.total]);
    
    return (
        <div className="cp-sale-footer d-flex justify-content-between">
            <div className="d-flex flex-column ms-2">
                <span className="text-secondary medium fs-4">{t('TOTAL')}</span>
                <span className="fg-orange-300 bold fs-1">{total ? total.toFixed(2) : 0} {Keys.TMT}</span>
            </div>
        </div>
    );
}

export default Footer;