import Keys from "../../../../../../utils/constants";
import Utils from "../../../../../../utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PaymentTypes from "../../../../../../components/ui/PaymentTypes";

const Footer = ({...props}) => {
    const { t } = useTranslation();
    const PAYMENT_TYPES = PaymentTypes();
    
    const [sale, setSale] = useState({});
    const [total, setTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [paid, setPaid] = useState();
    const [change, setChange] = useState(0);
    const [paymentType, setPaymentType] = useState();

    useEffect(() => {
        if (props.sale.id) {
            setSale(props.sale)
            setTotal(calculateTotal());
            setDiscount(calculateDiscount());
            setPaid(parseFloat(props.paid));
            setChange(props.change);
            setPaymentType(props.paymentType);
        }
    }, [props.sale, props.paid, props.change, props.paymentType]);

    const calculateDiscount = () => {
        const discountExtra = (props.sale.discountExtra / 100) * props.sale.total;
        return (props.sale.discount + discountExtra);
    }

    const calculateTotal = () => {
        const discountExtra = (props.sale.discountExtra / 100) * props.sale.total;
        return (props.sale.total - discountExtra);
    }
    
    return (
        <div className="cp-sale-footer d-flex justify-content-between">
            <div className="d-flex flex-row">
                <div className="d-flex flex-column ms-2  me-5">
                    <span className="text-secondary medium fs-4">{t('TOTAL')} {t('PAYMENT')}</span>
                    <span className="fg-green-500 bold fs-1">{total.toFixed(2)} <span className="fs-5"> {Keys.TMT}</span></span>
                </div>
                <div className="d-flex flex-column ms-2">
                    <span className="text-secondary medium fs-4">{t('DISCOUNT')}</span>
                    <span className="fg-orange-300 bold fs-1">{discount.toFixed(2)} <span className="fs-5"> {Keys.TMT}</span></span>
                </div>
            </div>
            <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                    <code className="font-18 me-2 fg-dark">{t('SALE_NO')}:</code>
                    <code className="medium font-18 fg-dark">{sale.number && sale.number}</code>
                </div>
                <div className="d-flex align-items-center">
                    <code className="font-18 me-2 fg-dark">{t('CREATED_DATE')}:</code>
                    <code className="medium font-18 fg-dark">{sale.createdAt && Utils.getTimestampDateHm(sale.createdAt)}</code>
                </div>
            </div>
            <div className="d-flex me-2">
                {(paymentType === 'CASH' || paymentType === 'CARD') && <div className="d-flex flex-column me-5">
                    <span className="text-secondary medium fs-4">{PAYMENT_TYPES.get(paymentType)} {t('PAID')}</span>
                    <span className="text-dark bold fs-1">
                        {paid ? paid.toFixed(2) : 0}
                        <span className="fs-5"> {Keys.TMT}</span>
                    </span>
                </div>}
                {(paymentType === 'CASH+CARD') && 
                    <div className="d-flex">
                        <div className="d-flex flex-column me-5">
                            <span className="text-secondary medium fs-4">{t('CASH')}</span>
                            <span className="text-dark bold fs-1">
                                {paid ? paid.toFixed(2) : 0}
                                <span className="fs-5"> {Keys.TMT}</span>
                            </span>
                        </div>
                        <div className="d-flex flex-column me-5">
                            <span className="text-secondary medium fs-4">{t('CARD')}</span>
                            <span className="text-dark bold fs-1">
                                {paid ? (total - paid).toFixed(2) : 0}
                                <span className="fs-5"> {Keys.TMT}</span>
                            </span>
                        </div>
                    </div>
                }
                <div className="d-flex flex-column">
                    <span className="text-secondary medium fs-4">{t('YOUR_CHANGE')}</span>
                    <span className="fg-red-300 bold fs-1">{change.toFixed(2)} <span className="fs-5"> {Keys.TMT}</span></span>
                </div>
            </div>
        </div>
    );
}

export default Footer;