import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import useAuth from "../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { Link } from "react-router-dom";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../../utils";
import Keys from "../../../../../../utils/constants";
import FinanceWrapper from "../../FinanceWrapper";
import TableStd from '../../../../../../components/ui/TableStd';
import DataNotFound from '../../../../../../components/ui/DataNotFound';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShowReturnedSaleItem from "../../components/ShowReturnedSaleItem";

const ReturnedSale = () => {
    const { t } = useTranslation();
    const params = useParams();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);

    const [returnedSale, setReturnedSale] = useState({});

    const [modalShowItem, setModalShowItem] = useState(false);
    
    const [itemToBe, setItemToBe] = useState({});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('FINANCE'), link: '/finance/sales' },
        { title: `${t('RETURNED')} ${t('SALES')}`, link: '/finance/returned-sales' },
        { title: returnedSale.number, active: true}
    ]

    useEffect(() => {
        if (ready) {
            getReturnedSale();
        }
    }, [ready]);

    const getReturnedSale = () => {
        setLoading(true);
        axiosPrivate.get(`/returned-sales/${params.id}`).then((res) => {
            if (res.status == 200) {
                setReturnedSale(res.data.data);
            }
        }).finally(() => {setLoading(false)});
    }

    const modelProps = [
        { 
            name: t('BARCODE'),
            value: (prop) => { return(<>{prop?.saleItem?.product?.barcode}</>) } 
        },
        { 
            name: t('PRODUCT'),
            value: (prop) => { return(<Link to={`/product/products/${prop?.saleItem?.product?.id}`} className='no-decor'>{prop?.saleItem?.product?.name}</Link>) } 
        },
        {
            name: t('QUANTITY'),
            value: (prop) => { return(<>{prop?.quantity?.toFixed(2)} {prop?.saleItem?.product?.unit?.shortName}</>) }
        },
        {
            name: t('UNIT_PRICE'),
            value: (prop) => { return(<>{prop?.saleItem?.unitPrice.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: t('SELLING_PRICE'),
            value: (prop) => { return(<>{prop?.saleItem?.sellingPrice?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: t('TOTAL'),
            value: (prop) => { return(<>{prop?.total?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: t('DISCOUNT'),
            value: (prop) => { return(<span className="fg-orange-300">{prop?.saleItem?.discount.toFixed(2)} {Keys.TMT}</span>) }
        },
        {
            name: t('REVENUE'),
            value: (prop) => { return(<span className="fg-purple-300">{prop?.revenue?.toFixed(2)} {Keys.TMT}</span>) }
        },
        {
            name: t('UPDATED_AT'),
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.updatedAt)}</>) }
        },
        {
            name: "",
            value: (prop) => {
                return (
                    <div className="d-flex flex-row">
                        <FontAwesomeIcon icon={faEye} className="rounded-2 p-1 bg-gray-50 hover-cursor"
                            onClick={() => {
                                setItemToBe(prop);
                                setModalShowItem(true);
                            }}
                        />
                    </div>
                )
            }
        }
    ];

    return (
        <FinanceWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{`${t('RETURNED')} ${t('SALE_NO')}: ${returnedSale?.sale?.number}`}</span>
                <div className="d-flex align-items-center">
                </div>
            </div>
            <div className="mt-5"></div>
            {returnedSale.id && <div className="d-flex flex-column border border-gray m-3 p-3">
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex flex-column">
                        <span className="font-14 text-secondary mb-2">{t('CASHIER')}: <span className="fg-dark medium">{returnedSale?.cashier?.fullName}</span></span>
                        <div className="d-flex flex-row">
                            <div className="d-flex flex-column font-12">
                                <span className="text-secondary">{t('REVENUE')}</span>
                                <span className="fg-purple-300 medium">{returnedSale?.revenue?.toFixed(2)} {Keys.TMT}</span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                        <span className="font-12 text-secondary">{t('NUMBER')}: <span className="fg-dark medium">#{returnedSale?.sale?.number}</span></span>
                        <span className="font-12 text-secondary">{t('DATE')}: <span className="fg-dark medium">{Utils.getTimestampDate(returnedSale.createdAt)}</span></span>
                    </div>
                </div>
                <hr className="my-2"/>
                { returnedSale?.returnedSaleItems?.length > 0 ?
                    <div style={{overflowY: "scroll", height: "250px"}}>
                        <TableStd modelProps={modelProps} data={returnedSale?.returnedSaleItems}/>
                    </div>
                    : <DataNotFound/>
                }
                <span className="font-14 medium fg-dark">{t('TOTAL')} {t('ITEM')}: {returnedSale?.returnedSaleItems?.length}</span>
                <hr  className="my-2"/>
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex flex-column">
                    </div>
                    <div className="d-flex flex-column">
                        <span className="font-14 medium fg-dark">{t('TOTAL')}</span>
                        <span className="font-18 fg-dark bold">{returnedSale.total.toFixed(2)} {Keys.TMT}</span>
                    </div>
                </div>
            </div>}
            <ShowReturnedSaleItem
                modalShow={modalShowItem}
                setModalShow={setModalShowItem}
                item={itemToBe}
            />
        </FinanceWrapper>
    );
}

export default ReturnedSale;