import { useState, useEffect } from "react";
import useAuth from '../../../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../../utils";
import Keys from "../../../../../../utils/constants";
import Config from "../../../../../../config";
import ProductsWrapper from "./../../ProductWrapper";
import ButtonU from "../../../../../../components/ui/ButtonU";
import ToastU from "../../../../../../components/ui/ToastU";
import InputLabeled from "../../../../../../components/form/InputLabeled";
import SelectLabeled from "../../../../../../components/form/SelectLabeled";
import SwitchLabeled from "../../../../../../components/form/SwitchLabeled";
import InputAreaLabeled from "../../../../../../components/form/InputAreaLabeled";
import Cancel from "../../../../../../components/ui/Cancel";
import { useTranslation } from "react-i18next";

const ProductCreate = () => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [barcode, setBarcode] = useState('');
    const [image, setImage] = useState('');
    const [file, setFile] = useState();
    const [categoryId, setCategoryId] = useState('');
    const [brandId, setBrandId] = useState('');
    const [manufacturerId, setManufacturerId] = useState('');
    const [countryId, setCountryId] = useState('');
    const [unitId, setUnitId] = useState('');
    const [discount, setDiscount] = useState();
    const [active, setActive] = useState(true);
    const [mostSold, setMostSold] = useState(false);
    const [description, setDescription] = useState();

    const [modalCancel, setModalCancel] = useState(false);

    const [categories, setCategories] = useState([]);
    const [countries, setCountries] = useState([]);
    const [manufacturers, setManufacturers] = useState([]);
    const [brands, setBrands] = useState([]);
    const [units, setUnits] = useState([]);

    const [query, setQuery] = useState({ order: [['name', 'ASC']] });

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('PRODUCT'), link: '/product/products' },
        { title: t('PRODUCTS'), link: '/product/products' },
        { title: t('NEW'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getCategories();
            getCountries();
            getManufacturers();
            getBrands();
            getUnits();
        }
    }, [ready]);

    const getCategories = () => {
        axiosPrivate.get('/categories', { params: query }).then((res) => {
            if (res.status == 200) setCategories(res.data.data);
        });
    }

    const getCountries = () => {
        axiosPrivate.get('/countries', { params: query }).then((res) => {
            if (res.status == 200) setCountries(res.data.data);
        });
    }

    const getManufacturers = () => {
        axiosPrivate.get('/manufacturers', { params: query }).then((res) => {
            if (res.status == 200) setManufacturers(res.data.data);
        });
    }

    const getBrands = () => {
        axiosPrivate.get('/brands', { params: query }).then((res) => {
            if (res.status == 200) setBrands(res.data.data);
        });
    }

    const getUnits = () => {
        const query = { order: [['order', 'ASC']] };
        axiosPrivate.get('/units', { params: query }).then((res) => {
            if (res.status == 200) {
                setUnits(res.data.data);
                setUnitId(res.data.data[0].id);
            }
        });
    }

    const handleFile = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const requiredInputs = [
            { key: name, value: t('NAME_REQUIRED') },
            { key: barcode, value: t('BARCODE_REQUIRED') },
            { key: unitId, value: t('UNIT_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        setLoading(true);

        const data = { 
            name, barcode, description, categoryId, brandId, manufacturerId, countryId, unitId, discount, active, mostSold,
            path: Config.PATH_PRODUCT_IMAGES, 
            file };

        const headers = { 'Content-Type': 'multipart/form-data'};
        axiosPrivate.post('/products/create', data, {headers}).then((res) => {
            if (res.status === 200) {
                setToast({ content: res.data.msg, variant: 'success', title: t('SUCCESS'), show: true });
                navigate(-1);
            }
        }).catch((err) => {
            console.log(err)
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {setLoading(false)});
    }

    return (
        <ProductsWrapper loading={loading} breadcrumbs={breadcrumbs}>
             <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('NEW')} {t('PRODUCT')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={() => {setModalCancel(true)}} className={'bg-red-400 bg-gradient text-white'} icon={faCancel} text={t('CANCEL')}/>
                    <ButtonU onSubmit={handleSubmit} className={'bg-green-700 bg-gradient text-white'} icon={faSave} text={t('SAVE')}/>
                </div>
            </div>
            <div className="row mt-5 p-3">
                <InputLabeled label={t('NAME')} type={'text'} value={name} setValue={setName} hint={t('NAME')} className={'col-md-4'} required={true}/>
                <InputLabeled label={t('BAR_CODE')} type={'text'} value={barcode} setValue={setBarcode} hint={'123456789'} className={'col-md-3'} required={true}/>
                <div className="col-md-5"></div>
                <SelectLabeled label={t('CATEGORY')} items={categories} value={categoryId} setValue={setCategoryId} className={'col-md-4'}/>
                <SelectLabeled label={t('BRAND')} items={brands} value={brandId} setValue={setBrandId} className={'col-md-3'}/>
                <div className="col-md-4"></div>
                <SelectLabeled label={t('MANUFACTURER')} items={manufacturers} value={manufacturerId} setValue={setManufacturerId} className={'col-md-3'}/>
                <div className="col-md-1"></div>
                <SelectLabeled label={t('COUNTRY')} items={countries} value={countryId} setValue={setCountryId} className={'col-md-3'}/>
                <div className="col-md-5"></div>
                <SelectLabeled label={t('UNIT')} items={units} value={unitId} setValue={setUnitId} className={'col-md-2'} required={true}/>
                <InputLabeled label={`${t('DISCOUNT')} (%)`} type={'number'} value={discount} setValue={setDiscount} hint={'5'} className={'col-md-2'}/>
                <SwitchLabeled label={t('ACTIVE')} value={active} setValue={setActive} size="small" className={'col-md-2'} required={false}/>
                <SwitchLabeled label={t('MOST_SOLD')} value={mostSold} setValue={setMostSold} size="small" className={'col-md-2'} required={false}/>
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <div className="d-flex flex-column my-2">
                        {image ? <img src={image} className="image-product mt-2"/>
                        :
                        <div className="image-upload">
                            <FontAwesomeIcon icon={faCloudArrowUp} className="fs-1 fg-blue-300"/>
                            <span className="medium fg-blue-300 mt-2">{t('IMAGE')} {t('UPLOAD')}</span>
                            <span className="fg-blue-700 font-12 mt-4 mx-3 text-center">{t('IMAGE_TO_BE_UPLOADED_SIZE')}</span>
                        </div>
                        }
                    </div>
                    <label htmlFor="uploadBtn" onChange={handleFile} className="upload-btn bg-blue-100 mt-1">
                        {t('IMAGE')} {t('UPLOAD')}
                        <input id="uploadBtn" type="file" hidden/>
                    </label>
                </div>
                <div className="col-md-8"></div>
                <InputAreaLabeled label={t('DEFINITION')} type={'number'} value={description} setValue={setDescription} rows={5} hint={Keys.DEFINITION} className={'col-md-4'}/>
            </div>
            <Cancel modalShow={modalCancel} setModalShow={setModalCancel}/>
            <ToastU toast={toast} setToast={setToast}/>
        </ProductsWrapper>
    );
}

export default ProductCreate;