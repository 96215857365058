import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormCheck } from "react-bootstrap";

const PaymentTypeRadioBtn = ({...props}) => {
    const items = props.items;

    const handleSelectedOption = (event) => {
        props.onChangeAttr(event.target.value);
    }

    return (
        <div className="d-flex flex-row justify-content-between rounded">
            { items.map((item) => {
                return(
                    <div key={item.id} className="col-md-4 card-default">
                        <div className={`rounded-1 pb-1 mb-1 mx-1 ${props.attr === item.id ?' bg-blue-700' : 'bg-gray-300'}`}>
                            <div className={`rounded-1 ${props.attr === item.id ?' bg-blue-500' : 'bg-neutral'}`}>
                                <label className="d-flex align-items-center justify-content-between px-2 py-1 my-1">
                                    <div className="d-flex flex-column">
                                        <FormCheck 
                                            id={item.id}
                                            label={item.name}
                                            value={item.id}
                                            name={props.group}
                                            type={props.type}
                                            className={`bold ${props.attr === item.id ? 'text-white': 'fg-gray'} font-14 me-4 my-0`}
                                            checked={props.attr === item.id}
                                            onChange={handleSelectedOption}/>
                                        <span className={`small medium ${props.attr === item.id ? 'text-white': 'fg-gray'}`}>{item.shortCut}</span>
                                    </div>
                                    <FontAwesomeIcon icon={item.icon} className={`${props.attr === item.id ? 'text-white': 'fg-gray'}`}/>
                                </label>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default PaymentTypeRadioBtn;