import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const PopUp = ({...props}) => {
    return (
      <Popup contentStyle={{width: '350px'}} className="shadow"
        trigger={
          <div className={`btn button-u mx-1 ${props.fg} border ${props.border} ${props.className}`}>
            {props.icon && <FontAwesomeIcon className="mx-1" icon={props.icon}/>}
            {props.text && <span className="mx-1 medium">{props.text}</span>}
          </div>
        }
        position="bottom right">
        {props.children}
      </Popup>
    );
}

export default PopUp;