import { useEffect, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import Utils from "../../../../utils";
import MainWrapper from "../../layouts/MainWrapper";
import SideBar from "../../../../layouts/SideBar";
import { useTranslation } from "react-i18next";
import Keys from "../../../../utils/constants";
import icReceipt from './../../../../assets/svg/ic_receipt_outline.svg';
import icClipboardList from './../../../../assets/svg/ic_clipboard_list_outline.svg';
import icFlipBackward from './../../../../assets/svg/ic_flip_backward.svg';

const InvoiceWrapper = (props) => {
    const { t } = useTranslation();
    const { ready, auth } = useAuth();
    const [allowed, setAllowed] = useState(false);

    useEffect(() => {
        if (ready) setAllowed(Utils.allowedRole(auth, '1002', 'read'));
    }, [ready, allowed]);

    const items = [
        { name: t('INVOICES'), id: "invoices", icon: icReceipt, links: Keys.INVOICE_INVOICES_LINKS},
        { name: `${t('INVOICE')} ${t('ITEMS')}`, id: "invoice-items", icon: icClipboardList, links: Keys.INVOICE_INVOICE_ITEMS_LINKS},
        { name: t('INVOICE_RETURNS'), id: "invoice-returns", icon: icFlipBackward, links: Keys.INVOICE_INVOICE_RETURNS_LINKS},
    ];

    return (
        <MainWrapper loading={props.loading} breadcrumbs={props.breadcrumbs}>
            {allowed && <div className="main-page">
                <SideBar items={items}/>
                <div className="body-page">
                    { props.children }
                </div>
            </div>}
        </MainWrapper>
    );
};

export default InvoiceWrapper;