import { useEffect, useState } from "react";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../../components/ui/ModalU';
import InputAreaLabeled from '../../../../../../components/form/InputAreaLabeled';
import { useTranslation } from "react-i18next";

const ModalNote = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const sale = props.sale;
    
    const [note, setNote] = useState('');

    useEffect(() => {
        if (sale.note)
            setNote(sale.note);
    }, [sale]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = { note };

        axiosPrivate.put('/sales/update/'+sale.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.refresh()});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('NOTE')}
            actionbtntext={t('SAVE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <InputAreaLabeled label={t('NOTE')} rows={10} type={'text'} value={note} setValue={setNote}/>
            </form>
        </ModalU>
    );
}

export default ModalNote