import { useState } from "react";
import icDiscount from "../../../../../../assets/svg/ic_discount_tag.svg";
import icNote from "../../../../../../assets/svg/ic_note.svg";
import ModalDiscount from "../components/ModalDiscount";
import ModalNote from "../components/ModalNote";

const RightBar = ({...props}) => {
    const [modalDiscount, setModalDiscount] = useState(false);
    const [modalNote, setModalNote] = useState(false);

    return (
        <div>
            <div className="d-flex flex-column ms-2 mt-2">
                <div className="card-default rounded-1 pb-3 bg-gray-300 mb-1">
                    <span className="rounded-1 bg-neutral px-2 pt-2 pb-3"
                        onClick={() => setModalDiscount(true)}>
                        <img src={icDiscount} alt="discount" className="wh-30"/>
                    </span>
                </div>
                <div className="card-default rounded-1 pb-3 bg-gray-300 mt-2">
                    <span className="rounded-1 bg-neutral px-2 pt-2 pb-3"
                        onClick={() => setModalNote(true)}>
                        <img src={icNote} alt="note" className="wh-30"/>
                    </span>
                </div>
            </div>
            <ModalDiscount
                modalShow={modalDiscount}
                setModalShow={setModalDiscount}
                sale={props.sale}
                toast={props.toast}
                setToast={props.setToast}
                refresh={props.refresh}
            />
            <ModalNote
                modalShow={modalNote}
                setModalShow={setModalNote}
                sale={props.sale}
                toast={props.toast}
                setToast={props.setToast}
                refresh={props.refresh}
            />
        </div>
    );
}

export default RightBar;