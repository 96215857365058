import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from "react-bootstrap";

const DropdownU = ({...props}) => {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
          className={props.toggleClass}
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
          >
          <FontAwesomeIcon icon={props.icon}/>
        </div>
    ));

    return (
        <Dropdown className="small hover-cursor">
            <Dropdown.Toggle as={CustomToggle}>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                { props.items.map((item) => {
                    return (
                        <Dropdown.Item className="px-3 py-1" onClick={item.onClick} key={item.name}>
                            <div className='d-flex align-items-center'>
                                {item.icon && <FontAwesomeIcon className={item.color} icon={item.icon}/>}
                                <div className="me-2"></div>
                                <span className={item.color}>{item.name}</span>
                            </div>
                        </Dropdown.Item>
                    )
                }) }
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default DropdownU;