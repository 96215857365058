import { useEffect, useState } from "react";
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';
import InputLabeled from "../../../../../../../components/form/InputLabeled";
import InputAreaLabeled from "../../../../../../../components/form/InputAreaLabeled";
import ModalU from '../../../../../../../components/ui/ModalU';
import { useTranslation } from "react-i18next";
import Chekbox from "@mui/material/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const Edit = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
  
    const [audit, setAudit] = useState(props.audit);
    const [auditedProduct, setAuditedProduct] = useState(props.item);

    const [quantity, setQuantity] = useState();
    const [description, setDescription] = useState('');
    const [closeAuditedProduct, setCloseAuditedProduct] = useState(false);

    useEffect(() => {
        setAuditedProduct(props.item);
        setQuantity(props.item.quantity);
        setDescription(props.item.description);
        setCloseAuditedProduct(props.item.closed);
    }, [props.item]);

    const editTempInvoiceItem = (event) => {
        event.preventDefault();

        if (!quantity || quantity > auditedProduct.product.quantity) return;
        auditedProduct.quantity = quantity;
        auditedProduct.description = description;
        auditedProduct.closed = closeAuditedProduct;
        update();

        props.setModalShow(false);
    }

    const handleCloseAuditedProduct = (e) => {
        const flag = e.target.checked;
        setCloseAuditedProduct(flag);
    }

    const update = () => {
        const data = {auditedProduct};
        axiosPrivate.put('/audits/update-with-items-quantity/'+audit.id, data).then((res) => {
            if (res.status == 200)
                props.refresh();
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        });
    }

    return (
        <ModalU show={props.modalShow} title={t('EDIT')}
            actionbtntext={t('SAVE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={editTempInvoiceItem} onHide={props.setModalShow}>
            <form onSubmit={editTempInvoiceItem} className='mx-2'>
                <div className="d-flex flex-column align-items-center mt-3">
                    <span className="fg-dark">{t('PRODUCT')}</span>
                    <span className="fg-dark medium">{auditedProduct.product.name}</span>
                </div>
                <InputLabeled label={`${t('QUANTITY')} (${t('INSUFFICIENT')})`} type={'number'} value={quantity} setValue={setQuantity} hint={"3"} className={'mt-3'} required={true}/>
                <InputAreaLabeled label={t('DEFINITION')} rows={3} value={description} setValue={setDescription} hint={t('DEFINITION')} className={'mt-3'}/>
                <div className='d-flex flex-column mt-3 mb-2'>
                    <div className="d-flex flex-row align-items-center">
                        <Chekbox checked={closeAuditedProduct} onChange={handleCloseAuditedProduct} size="small" className='py-1'/>
                        <span className='font-12 medium'>{t('CLOSED')}</span>
                    </div>
                    <div className="d-flex flex-row mt-2 font-12 bg-yellow-50 border border-warning fg-yellow-700 regular rounded-2 p-2">
                        <FontAwesomeIcon icon={faInfoCircle} className="mt-1 fg-yellow-700"/>
                        <span className="ms-2">{t('CLOSED_AUDITED_PRODUCT_DESC')}</span>
                    </div>
                </div>
            </form>
        </ModalU>
    );
}

export default Edit