import { useEffect, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import Utils from "../../../../utils";
import MainWrapper from "../../layouts/MainWrapper";
import SideBar from "../../../../layouts/SideBar";
import { useTranslation } from "react-i18next";
import Keys from "../../../../utils/constants";
import icWarehouse from './../../../../assets/svg/ic_warehouse_outline.svg';
import icMemoCheck from './../../../../assets/svg/ic_memo_check.svg';
import icCubeAlt from './../../../../assets/svg/ic_cube_alt.svg';

const WarehouseWrapper = (props) => {
    const { t } = useTranslation();
    const { ready, auth } = useAuth();
    const [allowed, setAllowed] = useState(false);

    useEffect(() => {
        if (ready) setAllowed(Utils.allowedRole(auth, '1003', 'read'));
    }, [ready, allowed]);

    const items = [
        { name: t('WAREHOUSES'), id: "warehouse", icon: icWarehouse, links: Keys.WAREHOUSE_WAREHOUSES_LINKS},
        { name: t('WRITTEN_OFF_PRODUCTS'), id: "written_off_products", icon: icCubeAlt, links: Keys.WAREHOUSE_WRITTEN_OFF_PRODUCTS_LINKS},
        { name: t('AUDIT'), id: "audit", icon: icMemoCheck, links: Keys.WAREHOUSE_AUDITS_LINKS},
    ];

    return (
        <MainWrapper loading={props.loading} breadcrumbs={props.breadcrumbs}>
            {allowed && <div className="main-page">
                <SideBar items={items}/>
                <div className="body-page">
                    { props.children }
                </div>
            </div>}
        </MainWrapper>
    );
};

export default WarehouseWrapper;