import { useState, useEffect, useRef } from "react";
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { faBarcode, faMagnifyingGlass, faReceipt, faXmark } from "@fortawesome/free-solid-svg-icons";
import Wrapper from "../../../layout/Wrapper";
import InputField from "../../../../../components/form/InputField";
import ButtonU from "../../../../../components/ui/ButtonU";
import ButtonUPush from "../../../../../components/ui/ButtonUPush";
import ReturnedSaleItems from "./components/ReturnedSaleItems";
import ToastU from "../../../../../components/ui/ToastU";
import ActiveItem from "./components/ActiveItem";
import SaleIReceipt from "./components/SaleReceipt";
import Footer from "./components/Footer";
import SuccessModal from "./components/SuccessModal";
import ClearItems from "./components/ClearItems";
import Discard from "./components/Discard";
import { useTranslation } from "react-i18next";
import Utils from "../../../../../utils";

const NewReturnSale = () => {
    const { t } = useTranslation();
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const barcodeRef = useRef();
    const saleNumberRef = useRef();

    const [find, setFind] = useState('');
    const [findSaleNumber, setfindSaleNumber] = useState('');
    const [returnedSaleItems, setReturnedSaleItems] = useState([]);

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [activeItem, setActiveItem] = useState({});
    
    const [foundSale, setFoundSale] = useState({});

    const [total, setTotal] = useState();
    
    const [modalSuccess, setModalSuccess] = useState(false);
    const [modalDiscardReturnSale, setModalDiscardReturnSale] = useState(false);
    const [modalClearReturnSale, setModalClearReturnSale] = useState(false);
    
    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        let sum = 0;
        returnedSaleItems.map(item => sum += item.total);
        setTotal(calculateTotal(sum));
        setActiveItem(returnedSaleItems[activeItemIndex]);
    }, [returnedSaleItems]);

    useEffect(() => {
        setActiveItemIndex(returnedSaleItems.length-1);
        setActiveItem(returnedSaleItems[returnedSaleItems.length-1]);
    }, [returnedSaleItems.length]);

    const calculateTotal = (total) => {
        const discountExtra = (foundSale.discountExtra / 100) * total;
        return (total - discountExtra);
    }

    useEffect(() => {
        if (!activeItem || returnedSaleItems.length < 1) return;
        else if (activeItem.quantity != returnedSaleItems[activeItemIndex].quantity) {
            updateQuantity();
        }
    }, [activeItem]);

    const updateQuantity = () => {
        // check existence of product in Sale Items
        let foundSaleItem = {};
        foundSale.saleItems.map(saleItem => { if(saleItem.productId == activeItem.productId) foundSaleItem = saleItem; });
        
        const updatedReturnedSaleItems = returnedSaleItems.map((item) => {
            if (item.productId == activeItem.productId) {
                const quantity = activeItem.quantity;
                const discount = (activeItem.product.discount / 100) * activeItem.product.sellingPrice * quantity;
                const total = (quantity * activeItem.product.sellingPrice) - discount;
                return {...item, quantity, discount, total};
            }
            return item;
        });
        setReturnedSaleItems(updatedReturnedSaleItems);
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            switch(event.key) {
                case 'Home': makeReturnSale(); break;
                case 'Escape': setModalDiscardReturnSale(true); break;
                case 'End': setModalClearReturnSale(true); break;
                case 'F8': barcodeRef.current.focus(); break;
                case 'F9': saleNumberRef.current.focus(); break;
                default: break;
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => { window.removeEventListener('keydown', handleKeyDown) };
    }, []);

    const handleFind = (event) => {
        event.preventDefault();
        addReturnedSaleItem();
    }

    const addReturnedSaleItem = () => {
        const id = undefined;
        const barcode = find;

        // check existence of product in Sale Items
        let foundSaleItem = null;
        foundSale.saleItems.map(saleItem => { if(saleItem.product.barcode == barcode) foundSaleItem = saleItem; });
        
        if (!foundSaleItem) {
            setToast({ content: t('NOT_FOUND'), variant: 'warning', title: t('WARNING'), show: true });
            return;
        }

        axiosPrivate.get('/products/'+id +'/'+barcode).then((res) => {
            if (res.status == 200) {
                const product = res.data.data;

                // check existence of product in Sale item returns
                let existReturnedSaleItem = false;
                returnedSaleItems.map(item => { if(item.productId == product.id) existReturnedSaleItem = true; });

                if (existReturnedSaleItem) {
                    const updatedReturnedSaleItems = returnedSaleItems.map((item) => {
                        if (item.productId == product.id && item.quantity < foundSaleItem.quantity) {
                            
                            const quantity = item.quantity + 1;
                            const discount = (product.discount / 100) * product.sellingPrice * quantity;
                            const total = (quantity * product.sellingPrice) - discount;
                            return { ...item, quantity, discount, total};
                        }
                        return item;
                    });
                    setReturnedSaleItems(updatedReturnedSaleItems);
                }
                else {
                    const newReturnedSaleItem = {
                        saleItemId: foundSaleItem.id,
                        product,
                        productId: foundSaleItem.productId,
                        originalQuantity: foundSaleItem.quantity,
                        quantity: 0,
                        unitPrice: product.unitPrice,
                        unitId: product.unitId,
                        sellingPrice: product.sellingPrice,
                        discount: (product.discount / 100) * product.sellingPrice,
                        total: 0,
                    }
                    setReturnedSaleItems([...returnedSaleItems, newReturnedSaleItem]);
                }
            }
            else if (res.status == 210) setToast({ content: res.data.msg, variant: 'warning', title: t('WARNING'), show: true });
        }).finally(() => {setFind('')})
    }

    const checkSaleNumber = (event) => {
        event.preventDefault();
        const id = undefined;
        const number = findSaleNumber;

        axiosPrivate.get('/sales/'+id +'/'+number).then((res) => {
            if (res.status == 200) {
                const sale = res.data.data;
                if (sale.number === findSaleNumber) {
                    setFoundSale(sale);
                    setReturnedSaleItems([]);
                }
                else setToast({ content: t('NOT_FOUND'), variant: 'warning', title: t('WARNING'), show: true });
            }
        }).catch((err) => {
            switch(err.response.status) {
                case 400: setToast({ content: t('SOMETHING_WENT_WRONG'), variant: 'danger', title: t('ERROR'), show: true }); break;
                case 404: setToast({ content: t('SALE_NOT_FOUND'), variant: 'warning', title: t('WARNING'), show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        });
    }

    const makeReturnSale = () => {
        const requiredInputs = [
            { key: (total > 0) , value: t('PRODUCT_QUANTITIES_NOT_ENTERED') }
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        const data = { returnedSaleItems, saleId: foundSale.id, total };
        axiosPrivate.post('/returned-sales/create', data).then((res) => {
            if (res.status == 200) {
                setModalSuccess(true);
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        });
    }

    return (
        <Wrapper>
            <div className="cp-bg">
                <div className='d-flex align-items-center justify-content-between mt-1 mx-3'>
                    <div className="d-flex">
                        <ButtonUPush onSubmit={() => {setModalDiscardReturnSale(true)}} text={<span>{t('CANCEL')}<sup className="ms-1 small ">[Esc]</sup></span>} className={"bg-red-400 font-14 medium text-white card-default"} shadow={"bg-red-700"}/>
                        <ButtonUPush onSubmit={() => {setModalClearReturnSale(true)}} icon={faXmark} text={<span>{t('CLEAR')}<sup className="ms-1 small">[Del]</sup></span>} className={'bg-white font-14 fg-gray card-default'} shadow={"bg-gray-300"} disabled={returnedSaleItems.length <= 0}/>
                    </div>
                    <div className="d-flex">
                        <ButtonUPush onSubmit={makeReturnSale} text={<span>{t('RETURN')}<sup className="ms-1 small">[Home]</sup></span>} className={"bg-blue-500 font-14 medium text-white card-default"} disabled={!foundSale.id || returnedSaleItems.length <= 0} shadow={"bg-blue-700"}/>
                    </div>
                </div>
                <div className="row mx-2">
                    <div className="col-md-4">
                        <form onSubmit={handleFind} className="d-flex flex-row my-2">
                            <InputField type={"number"} value={find} setValue={setFind} hint={`${t('BAR_CODE')} [F8]`} icon={faBarcode} innerRef={barcodeRef} formClass="input-field-container font-18 flex-fill" inputClass="input-field-outline w-100" disabled={!foundSale.saleItems}/> 
                            <ButtonU onSubmit={handleFind} icon={faMagnifyingGlass} className={"bg-blue-700 bg-gradient font-18 medium text-white"} disabled={!foundSale.saleItems}/>
                        </form>
                        <ReturnedSaleItems data={returnedSaleItems} setActiveItem={setActiveItem} setActiveItemIndex={setActiveItemIndex}/>
                    </div>
                    <ActiveItem activeItem={activeItem} setActiveItem={setActiveItem} returnedSaleItems={returnedSaleItems} setReturnedSaleItems={setReturnedSaleItems}/>
                    <div className="col-md-5">
                        <form onSubmit={checkSaleNumber} className="row d-flex align-items-center my-2">
                            <div className="col-md-10">
                                <InputField type={"text"} value={findSaleNumber} setValue={setfindSaleNumber} hint={`${t('SALE_NO')} [F9]`} icon={faReceipt} innerRef={saleNumberRef} formClass="input-field-container font-18 col-md-12" inputClass="input-field-outline w-100"/> 
                            </div>
                            <div className="col-md-2">
                                <ButtonU onSubmit={checkSaleNumber} icon={faMagnifyingGlass} className={"col-md-12 bg-blue-700 bg-gradient font-18 medium text-white"}/>
                            </div>
                        </form>
                        {foundSale.saleItems && <SaleIReceipt sale={foundSale} saleItemReturns={returnedSaleItems}/>}
                    </div>
                </div>
                <Footer total={total}/>
            </div>
            <SuccessModal show={modalSuccess} onHide={setModalSuccess}/>
            <ToastU toast={toast} setToast={setToast}/>
            <ClearItems
                modalShow={modalClearReturnSale}
                setModalShow={setModalClearReturnSale}
                items={returnedSaleItems}
                setItems={setReturnedSaleItems}
            />
            <Discard
                modalShow={modalDiscardReturnSale}
                setModalShow={setModalDiscardReturnSale}
            />
        </Wrapper>
    );
}

export default NewReturnSale;