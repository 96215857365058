import { useEffect, useState } from "react";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../../components/ui/ModalU';
import Utils from "../../../../../../utils";
import InputLabeled from '../../../../../../components/form/InputLabeled';
import SelectLabeled from '../../../../../../components/form/SelectLabeled';
import { useTranslation } from "react-i18next";

const Update = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [name, setName] = useState('');
    const [provinceId, setProvinceId] = useState('');

    useEffect(() => {
        setName(item.name);
        setProvinceId(item.provinceId);
    }, [item])

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: name, value: t('NAME_REQUIRED') },
            { key: provinceId, value: t('PROVINCE_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { name, provinceId };
        axiosPrivate.put('/districts/update/'+item.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('DISTRICT')}
            actionbtntext={t('UPDATE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <InputLabeled label={t('NAME')} type={'text'} value={name} setValue={setName} hint={t('NAME')} required={true}/>
                <SelectLabeled label={t('PROVINCE')} items={props.provinces} value={provinceId} setValue={setProvinceId} required={true}/>
            </form>
        </ModalU>
    );
}

export default Update