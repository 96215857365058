const ImageModal = ({...props}) => {
    const closeModal = () => {
        props.setShow({...props.show, show: false});
    }

    return (
        <div className={"image-modal " + (props.show.show ? 'show': 'hide')}>
            <span onClick={closeModal} className="close">&times;</span>
            <img src={props.show.src} className="image-modal-content"/>
        </div>
    );
}

export default ImageModal;