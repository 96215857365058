import { useState, useEffect } from "react";
import useAuth from '../../../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { useParams } from "react-router-dom";
import HomeWrapper from "../../HomeWrapper";
import TableStd from '../../../../../../components/ui/TableStd';
import { ListGroup } from "react-bootstrap";
import Utils from "../../../../../../utils";
import DataNotFound from '../../../../../../components/ui/DataNotFound';
import { useTranslation } from "react-i18next";

const ReturnedSale = () => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();

    const [returnedSale, setReturnedSale] = useState({});

    useEffect(() => {
        if (ready) {
            getReturnedSale();
        }
    }, [ready]);

    const getReturnedSale = () => {
        axiosPrivate.get('/returned-sales/'+params.id).then((res) => {
            if (res.status == 200) {
                setReturnedSale(res.data.data);
            }
        });
    }

    const modelProps = [
        { label: t('NUMBER'), value: (prop) => { return(<>{prop?.sale?.number}</>) } },
        { label: t('CASHIER'), value: (prop) => { return(<>{prop?.cashier?.fullName}</>) } },
        { label: t('REGISTRATION_DATE'), value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.createdAt)}</>)} },
        { label: t('UPDATED_AT'), value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.updatedAt)}</>)} },
    ];

    const modelProps2 = [ 
        { 
            name: t('PRODUCT'),
            value: (prop) => { return(<>{prop?.saleItem?.product?.name}</>) } 
        },
        { 
            name: t('BAR_CODE'),
            value: (prop) => { return(<>{prop?.saleItem?.product?.barcode}</>) } 
        },
        {
            name: t('QUANTITY'),
            value: (prop) => { return(<>{prop.quantity} {prop?.saleItem?.product?.unit?.name}</>) }
        }
    ];

    return (
        <HomeWrapper>
            <div className="d-flex flex-row align-items-center justify-content-between bg-gray-50 px-2">
                <span className="fs-3 bold fg-dark">{`${t('RETURNED')} ${t('SALE')}`}: {returnedSale?.sale?.number}</span>
            </div>
            <div className="d-flex flex-row">
                <ListGroup className="my-3 small rounded-0">
                    { modelProps.map((prop) => {
                        return(
                            <ListGroup.Item key={prop.label} className="d-flex align-items-center justify-content-between py-1">
                                <span className="medium fg-dark me-5">{prop.label}</span>
                                <span className="text-end">{returnedSale.id && prop.value(returnedSale)}</span>
                            </ListGroup.Item>
                        ) })
                    }
                </ListGroup>
                <div className="d-flex flex-column flex-fill ms-3 my-2">
                    <span className="fs-5 medium fg-dark">{t('PRODUCTS')}</span>
                    { returnedSale?.returnedSaleItems?.length ?
                        <TableStd modelProps={modelProps2} data={returnedSale?.returnedSaleItems}/>
                        : <DataNotFound/>
                    }
                </div>
            </div>
        </HomeWrapper>
    );
}

export default ReturnedSale;