import { Link, useLocation } from "react-router-dom"

const SideBar = ({...props}) => {
    const location = useLocation();
    const items = props.items;

    const isActive = (links) => {
        let flag = links.some((link) => {
            const baseLink = link.split('/')[2];
            const path = location.pathname.split('/')[2];
            return (baseLink === path);
        });

        return flag;
    }

    return (
        <ul className="cp-side-bar" id="side-bar">
            { items.map((item)=>{
                return (
                    <Link to={item.links[0]} className={"side-item"} key={item.id}>
                        <div className={"side-link " + (!isActive(item.links) && 'collapsed')} data-bs-target={`#${item.id}`} data-bs-toggle="collapse">
                            <span>{item.name}</span>
                        </div>
                    </Link>
                );
            }) }
        </ul>
    );
}

export default SideBar;