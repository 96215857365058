import { useTranslation } from "react-i18next";
import WeekDays from "../../../../../../components/ui/WeekDays";
import Months from "../../../../../../components/ui/Months";
import Utils from "../../../../../../utils";

const SelectDate = ({...props}) => {
    const { t } = useTranslation();

    const WEEK_DAYS = WeekDays();
    const MONTHS = Months();

    const thisDay = WEEK_DAYS.get(new Date().getDay());
    const thisMonth = MONTHS.get(String(new Date().getMonth() + 1).padStart(2, '0'));
    const thisYear = new Date().getFullYear();

    const today = new Date(Utils.getCurrentDateISO()).toISOString().split('T')[0];

    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).toISOString().split('T')[0];
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).toISOString().split('T')[0];

    const currentYear = new Date().getFullYear();
    const firstDayOfYear = new Date(currentYear, 0, 1).toISOString().split('T')[0];
    const lastDayOfYear = new Date(currentYear, 11, 31).toISOString().split('T')[0];

    const items = [
        { id: 0, name: thisDay, filter: {createdAtLb: today, createdAtUb: ''} },
        { id: 1, name: thisMonth, filter: { createdAtLb: firstDayOfMonth, createdAtUb: lastDayOfMonth} },
        { id: 2, name: thisYear, filter: { createdAtLb: firstDayOfYear, createdAtUb: lastDayOfYear} },
    ]

    const handleChange = (event) => {
        const value = event.target.value;
        const f = items[value].filter;
        props.setQuery(prev => {
            return {...prev, filter: {
                ...prev.filter, id: value, createdAtLb: f.createdAtLb, createdAtUb: f.createdAtUb}
            }
        });
    }

    return (
        <div className="d-flex flex-row align-items-center bg-purple-400 rounded-2 p-2 me-2">
            <span className="font-16 medium ms-1 me-2 text-white">{t('DATE')}</span>
            <div className={`d-flex flex-column`}>
                <select
                    value={props.query.filter.id}
                    onChange={handleChange}
                    className={`rounded-2 border px-2 font-16 bg-light`}>
                    { items.map((item) => {
                        return (<option value={item.id} key={item.id}>{item.name}</option>)
                    }) }
                </select>
            </div>
        </div>
    );
}

export default SelectDate;