import React, { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import Keys from '../../utils/constants';
import Utils from '../../utils';
import { useTranslation } from 'react-i18next';

const PrintSaleSummary = ({...props}) => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    
    const [report, setReport] = useState(props.report);
    const [query, setQuery] = useState(props.query);

    useEffect(() => {
        if (ready) {
            setReport(props.report);
            setQuery(props.query);
        }
    }, [ready, props.report]);

    return (
        (report && query.filter) && <div id='receipt' className='receipt visible-on-print'>
            <div className='d-flex flex-column align-items-center'>
                <span className='font-14 bold'>{t('REPORT')}</span>
                <span className='font-12'>
                    {Utils.getTimestampDate(query.filter.createdAtLb)}
                    {query.filter.createdAtUb && ` - ${Utils.getTimestampDate(query.filter.createdAtUb)}`}
                </span>
            </div>
            <div className='d-flex flex-column'>
                <div className='d-flex flex-row justify-content-between'>
                    <span className='font-12'>{`${t('TOTAL')} ${t('SALE')}`}:</span>
                    <span className='font-12'>{report?.sale?.total?.toFixed(2)}</span>
                </div>
                <div className='d-flex flex-row justify-content-between'>
                    <span className='font-12'>{t('CASH')}:</span>
                    <span className='font-12'>{report?.sale?.cash?.toFixed(2)}</span>
                </div>
                <div className='d-flex flex-row justify-content-between'>
                    <span className='font-12'>{t('CARD')}:</span>
                    <span className='font-12'>{report?.sale?.card?.toFixed(2)}</span>
                </div>
                <div className='d-flex flex-row justify-content-between'>
                    <span className='font-12'>{t('DISCOUNT')}:</span>
                    <span className='font-12'>{report?.sale?.discount?.toFixed(2)}</span>
                </div>
                <div className='d-flex flex-row justify-content-between'>
                    <span className='font-12'>{`${t('RETURNED')} ${t('SALE')}`}:</span>
                    <span className='font-12'>{report?.returnedSale?.total?.toFixed(2)}</span>
                </div>
                <div className='d-flex flex-row justify-content-between'>
                    <span className='font-12'>{t('WRITTEN_OFF_PRODUCTS')}:</span>
                    <span className='font-12'>{report?.writtenOff?.total?.toFixed(2)}</span>
                </div>
                <div className='d-flex flex-row justify-content-between'>
                    <span className='font-12'>{t('AUDIT')}:</span>
                    <span className='font-12'>{report?.audit?.total?.toFixed(2)}</span>
                </div>
                <hr className='my-1'/>
                <div className='d-flex flex-row justify-content-between'>
                    <span className='font-12 medium'>{t('TOTAL')} {t('REVENUE')}: </span>
                    <span className='font-14 bold'>{(report?.sale?.revenue - report?.returnedSale?.revenue - report?.writtenOff?.total - report?.audit?.total)?.toFixed(2)} {Keys.TMT}</span>
                </div>
            </div>
        </div>
    );
}

export default PrintSaleSummary;