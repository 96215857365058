import { useState, useEffect } from "react";
import useAuth from '../../../../hooks/useAuth';
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import SettingsWrapper from "./../../layouts/SettingsWrapper";
import Utils from "../../../../utils";
import InputLabeled from '../../../../components/form/InputLabeled';
import ButtonU from "../../../../components/ui/ButtonU";
import ToastU from "../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";

const Appearance = ({...props}) => {
    const { ready, auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const { t } = useTranslation();
    
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('SETTINGS')}, { title: t('APPEARANCE'), active: true }
    ]



    return (
        <SettingsWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="d-flex flex-column">
                <span className="fs-4 medium fg-dark">{t('APPEARANCE')}</span>
                <hr />
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </SettingsWrapper>
    )
}

export default Appearance;