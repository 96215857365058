import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Modal } from 'react-bootstrap';
import ButtonU from "../../../../../../components/ui/ButtonU";
import { useTranslation } from "react-i18next";

const SuccessModal = ({...props}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const dissmiss = () => {
        props.onHide(false);
        navigate('/cp');
    }

    return (
        <Modal {...props} size={props.size} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>
            <div className="p-3">
                <div className="border rounded-2 my-2 p-2">
                    <div className="d-flex flex-column align-items-center font-dark">
                        <span className="fs-3 bold fg-green-500 my-3">
                            <FontAwesomeIcon icon={faCheckCircle} className="me-2"/>
                            {t('SUCCESS')}
                        </span>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <ButtonU onSubmit={dissmiss} icon={faHome} text={t('MAIN_PAGE')} className={'font-18 bg-blue-700 bg-gradient text-white'}/>
                </div>
            </div>
        </Modal>
    );
}

export default SuccessModal;