import { useState } from 'react';
import useAuth from "../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import AdminVerification from "../../../../../../components/ui/AdminVerification";
import ModalU from '../../../../../../components/ui/ModalU';
import Keys from "../../../../../../utils/constants";
import ToastU from "../../../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";

const DeleteSaleItem = ({...props}) => {
    const { t } = useTranslation();
    const { setTempUnlocked } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [adminVerificationModal, setAdminVerificationModal] = useState(false);
    const item = props.item;

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const handleSubmit = (event) => {
        event.preventDefault();
        setAdminVerificationModal(true);
    }

    const setOnSuccess = () => {
        setTempUnlocked(false);

        axiosPrivate.delete('/sale-items/delete/'+item.id).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
                props.refresh();
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        });
    }
 
    return (
        <ModalU show={props.modalShow} size={''} title={t('WARNING')}
            actionbtntext={t('DELETE')}
            actionbtnclassname={"bg-red-400 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            {item && <div className="d-flex flex-column align-items-center">
                <span className="medium fg-red-300">{t('DATA_DELETION_CONFIRMATION')}</span>
                <div className="d-flex flex-row my-2">
                    <span className="medium fg-dark me-2">{t('SALE')} {t('PRODUCT')}:</span>
                    <span className="fg-dark me-1">{item.product && item.product.name}</span>
                </div>
            </div>}
            <ToastU toast={toast} setToast={setToast}/>
            <AdminVerification
                show={adminVerificationModal}
                onHide={setAdminVerificationModal}
                appName={Keys.CP}
                setOnSuccess={setOnSuccess}
                toast={toast}
                setToast={setToast}
            />
        </ModalU>
    );
}

export default DeleteSaleItem