import { useState, useEffect } from "react";
import useAuth from '../../../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faSave, faSquareCheck, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../../utils";
import WarehouseWrapper from "../../WarehouseWrapper";
import ButtonU from "../../../../../../components/ui/ButtonU";
import InputField from "../../../../../../components/form/InputField";
import AuditedProducts from "./components/AuditedProducts";
import ToastU from "../../../../../../components/ui/ToastU";
import Chekbox from "@mui/material/Checkbox";
import DeleteAudit from "./components/DeleteAudit";
import { useTranslation } from "react-i18next";
import Keys from "../../../../../../utils/constants";
import DeleteAuditedProducts from './components/DeleteAuditedProducts';
import Status from "../../../../../../components/ui/Status";
import InputAreaLabeled from "../../../../../../components/form/InputAreaLabeled";

const Audit = () => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [barcode, setBarcode] = useState('');
    const [audit, setAudit] = useState({});
    const [auditedProducts, setAuditedProducts] = useState([]);
    const [note, setNote] = useState('');

    const [modalDelete, setModalDelete] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [modalDeleteItems, setModalDeleteItems] = useState(false);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('WAREHOUSE'), link: '/warehouse/warehouses' } ,
        { title: t('AUDITS'), link: '/warehouse/audits'},
        { title: audit.number, active: true}
    ]

    useEffect(() => {
        if (ready) {
            getAudit();
        }
    }, [ready, toast]);

    const getAudit = () => {
        setLoading(true);
        axiosPrivate.get('/audits/'+params.id).then((res) => {
            if (res.status == 200) {
                const audit = res.data.data;
                setAudit(audit);
                setNote(audit?.note || '');
                audit.auditedProducts.forEach((item) => {
                    item.checked = false;
                });
                setAuditedProducts(audit.auditedProducts);
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {setLoading(false)});
    }

    const handleFindProduct = (event) => {
        event.preventDefault();
        addAuditedProduct(barcode);
    }

    const addAuditedProduct = (barcode) => {
        const data = {audit, barcode};
        axiosPrivate.put('/audits/update-with-items/'+audit.id, data).then((res) => {
            if (res.status == 200)
                refresh();
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => setBarcode(''));
    }

    const refresh = () => { getAudit(); }

    const updateAudit = () => {
        const data = { note };
        axiosPrivate.put('/audits/update/'+audit.id, data).then((res) => {
            if (res.status == 200) {
                setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        });
    }

    const terminateAudit = () => {
        const data = { audit };
        axiosPrivate.put('/audits/terminate/'+audit.id, data).then((res) => {
            if (res.status == 200) {
                navigate(-1);
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        });
    }

    useEffect(() => {
        setSelectAll(!checkItemsUnChecked(auditedProducts));
    }, [auditedProducts]);

    const handleSelectAll = (e) => {
        const flag = e.target.checked;
        setSelectAll(flag);

        const updatedItems = auditedProducts.map((item) => {
            return {...item, checked: (flag && !item.closed)};
        });
        setAuditedProducts(updatedItems);
    }

    const closeSelectedItems = async() => {
        if (!checkItemsChecked(auditedProducts)) return;

        const closedItems = [];
        auditedProducts.map((item) => {
            if (item.closed || !item.quantity) return;
            item.closed = item.checked;
            closedItems.push(item);
        });

        axiosPrivate.put('/audits/close-items/' + audit.id, {closedItems}).then((res) => {
            if (res.status == 200) {
                setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => { refresh() });
    }

    const checkItemsChecked = (items) => {
        return items.some((item) => item.checked);
    }

    const checkItemsUnChecked = (items) => {
        return items.some((item) => !(item.checked || item.closed));
    }

    return (
        <WarehouseWrapper loading={loading} breadcrumbs={breadcrumbs}>
             <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('AUDIT')}: {audit.number}</span>
                <div className="d-flex align-items-center">
                    {!audit.closed && <ButtonU onSubmit={() => {setModalDelete(true)}} icon={faTrashCan} text={t('DELETE')} className={"bg-red-400 bg-gradient text-white"}/>}
                    <ButtonU onSubmit={updateAudit} icon={faSave} text={t('SAVE')} className={"bg-green-700 bg-gradient text-white"}/>
                    {!audit.closed && <ButtonU onSubmit={terminateAudit} icon={faSquareCheck} text={t('TERMINATE_AUDIT')} className={"bg-blue-700 bg-gradient text-white"}/>}
                </div>
            </div>

            <div className="d-flex flex-column my-2 py-2 mt-5">
                <div className="d-flex flex-row align-items-center font-14">
                    <div className="d-flex flex-column m-2 flex-fill border px-2 py-1">
                        <div className="d-flex flex-row justify-content-between mb-1">
                            <span className="text-secondary">{t('AUDIT')} {t('NUMBER')}:</span>
                            <span className="medium ms-3 fg-dark">{audit.number}</span>
                        </div>
                        <div className="d-flex flex-row justify-content-between mb-1">
                            <span className="text-secondary">{t('RESPONSIBLE')}:</span>
                            <span className="medium ms-3 fg-dark">{audit?.user?.fullName}</span>
                        </div>
                        <div className="d-flex flex-row justify-content-between mb-1">
                            <span className="text-secondary">{t('TOTAL')} {t('AUDIT')}:</span>
                            <span className="medium ms-3 fg-dark">{audit?.total ? audit?.total?.toFixed(2) : 0}<span className="ms-1 font-12">{Keys.TMT}</span></span>
                        </div>
                    </div>
                    <div className="d-flex flex-column m-2 flex-fill border px-2 py-1">
                        <div className="d-flex flex-row justify-content-between mb-1">
                            <span className="text-secondary">{t('REGISTRATION_DATE')}:</span>
                            <span className="medium ms-3 fg-dark">{audit.createdAt && Utils.getTimestampDateHm(audit.createdAt)}</span>
                        </div>
                        <div className="d-flex flex-row justify-content-between mb-1">
                            <span className="text-secondary">{t('UPDATED_AT')}:</span>
                            <span className="medium ms-3 fg-dark">{audit.updatedAt && Utils.getTimestampDateHm(audit.updatedAt)}</span>
                        </div>
                        <div className="d-flex flex-row justify-content-between mb-1">
                            <span className="text-secondary">{t('STATUS')}:</span>
                            <span className="medium ms-3 fg-dark">{<Status status={audit.closed} textSize={'font-12'}/>}</span>
                        </div>
                    </div>
                    <InputAreaLabeled label={t('NOTE')} type={'text'} value={note} setValue={setNote} rows={3} className={'d-flex flex-column flex-fill px-2'}/>
                </div>
                <div className='d-flex flex-column bg-light mb-2'>
                    <form onSubmit={handleFindProduct} className="d-flex flex-row mx-2">
                        <InputField type={"number"} value={barcode} setValue={setBarcode} hint={t('BARCODE')} icon={faBarcode} formClass="input-field-container me-1 font-14" inputClass="input-field-outline" disabled={audit.closed}/> 
                        <ButtonU onSubmit={handleFindProduct} icon={faSearch} className={"bg-blue-700 bg-gradient text-white"} disabled={audit.closed}/>
                    </form>
                    <dir className='d-flex flex-row align-items-center px-1 my-1'>
                        <div className='d-flex flex-row align-items-center me-3 py-1'>
                            <Chekbox checked={selectAll} onChange={handleSelectAll} size="small" className='py-0' disabled={audit.closed}/>
                            <span className='font-12 medium'>{t('ALL')}</span>
                        </div>
                        {checkItemsChecked(auditedProducts) && <span onClick={closeSelectedItems} className="font-12 medium border px-1 card-default rounded-1 bg-light fg-dark">{t('CLOSED')}</span>}
                        {checkItemsChecked(auditedProducts) &&
                            <span onClick={() => setModalDeleteItems(true)}
                                className="font-12 medium border px-1 card-default rounded-1 ms-2 bg-light fg-dark"><FontAwesomeIcon icon={faTrashCan}/>
                            </span>
                        }
                    </dir>

                </div>
                {auditedProducts.length > 0 && 
                    <AuditedProducts
                        audit={audit}
                        closed={audit.closed}
                        items={auditedProducts}
                        setItems={setAuditedProducts}
                        toast={toast}
                        setToast={setToast}
                        refresh={refresh}
                    />
                }
            </div>
            <DeleteAudit
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                toast={toast}
                setToast={setToast}
                audit={audit}
            />
            <DeleteAuditedProducts
                modalShow={modalDeleteItems}
                setModalShow={setModalDeleteItems}
                toast={toast}
                setToast={setToast}
                audit={audit}
                items={auditedProducts}
                refresh={refresh}
            />
            <ToastU toast={toast} setToast={setToast}/>
        </WarehouseWrapper>
    );
}

export default Audit;