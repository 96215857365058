import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import useAuth from "../../../hooks/useAuth";
import Utils from "../../../utils";
import Keys from "../../../utils/constants";
import TopStatus from "./TopStatus";

const Wrapper = (props) => {
    const navigate = useNavigate();
    const { ready, auth } = useAuth();
    const [allowed, setAllowed] = useState(false);

    useEffect(() => {
        if (ready) setAllowed(Utils.allowedRole(auth, '1008', 'read'));
    }, [ready, allowed]);

    useEffect(() => {
        if (auth.token == undefined && !auth.token) return;
        else if (auth.token == undefined || auth.token == Keys.UNAUTHORIZED) {
            navigate('/auth/signin');
        }
    }, [auth]);

    return (
        (ready && allowed) && <div className="cp-body">
            <TopStatus/>
            { props.children }
        </div>
    );
}

export default Wrapper;