import { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate } from "react-router-dom";
import Keys from "../../../utils/constants";
import Utils from "../../../utils";
import { useTranslation } from "react-i18next";

const OpenSales = () => {
    const { ready, auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [openSales, setOpenSales] = useState({});
    const [openSalesCount, setOpenSalesCount] = useState({});

    const [query, setQuery] = useState({ order: [['createdAt', 'ASC']], filter: {closed: false, cashierId: auth.user.id} });

    useEffect(() => {
        if (ready) getOpenSales();
    }, [ready, query]);

    const getOpenSales = () => {
        axiosPrivate.get('/sales', { params: query }).then((res) => {
            if (res.status == 200) {
                setOpenSales(res.data.data);
                setOpenSalesCount(res.data.count);
            }
        });
    }

    return (
        <div className="cp-open-sales">
            <div className=" position-fixed bg-light pt-2 w-100">
                <span className="bold fg-dark font-18 px-2">{t('OPEN')} {t('SALES')}</span>
                <hr className="my-2"/>
            </div>
            <div className="mt-5"></div>
            {openSalesCount > 0 ? 
                openSales.map((sale) => {
                    return (
                        <div key={sale.id}
                            onClick={() => navigate(`/cp/sales/open/${sale.id}`)}
                            className="d-flex flex-column border border-secondary hover-cursor mx-2 my-1">
                            <div className="d-flex flex-row justify-content-between bg-gray-50 small fg-dark px-2 py-1">
                                <span><span className="fg-orange-300">⬤</span> {t('SALE_NO')}:</span>
                                <span className="ms-1 medium">#{sale.number}</span>
                            </div>
                            <div className="px-2 pb-1">
                                <div className="d-flex flex-row justify-content-between font-12 fg-dark">
                                    <span className="medium text-secondary">{t('DATE')}:</span>
                                    <span>{Utils.getTimestampDateHm(sale.createdAt)}</span>
                                </div>
                                <div className="d-flex flex-row justify-content-between font-12 fg-dark">
                                    <span className="medium text-secondary">{t('PRODUCTS')}:</span>
                                    <span>{sale.saleItems.length}</span>
                                </div>
                                <div className="d-flex flex-row justify-content-between font-12 fg-dark">
                                    <span className="medium">{t('TOTAL')}:</span>
                                    <span className="fg-green-500 medium">{sale.total ? sale.total.toFixed(2) : 0} {Keys.TMT}</span>
                                </div>
                            </div>
                        </div>
                    )
                })
            : <span className="mx-2 font-14 fg-dark">{t('DATA_NOT_FOUND')}</span>
            }
        </div>
    );
}

export default OpenSales;