import { useState } from 'react';
import useAuth from "../../../../../../hooks/useAuth";
import AdminVerification from "../../../../../../components/ui/AdminVerification";
import ModalU from '../../../../../../components/ui/ModalU';
import Keys from "../../../../../../utils/constants";
import ToastU from "../../../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";

const DeleteItem = ({...props}) => {
    const { t } = useTranslation();
   
    const { setTempUnlocked } = useAuth();
    const [adminVerificationModal, setAdminVerificationModal] = useState(false);
    const item = props.item;

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const handleSubmit = (event) => {
        event.preventDefault();
        setAdminVerificationModal(true);
    }

    const setOnSuccess = () => {
        setTempUnlocked(false);

        const updatedItems = props.items.filter((e) => e.productId != item.productId);
        props.setItems(updatedItems);
        props.setModalShow(false);
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('WARNING')}
            actionbtntext={t('DELETE')}
            actionbtnclassname={"bg-red-400 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            {item && <div className="d-flex flex-column align-items-center">
                <span className="medium fg-red-300 font-18">{t('DATA_DELETION_CONFIRMATION')}</span>
                <div className="d-flex flex-row my-2">
                    <span className="medium fg-dark me-2">{t('SALE')} {t('PRODUCT')}:</span>
                    <span className="fg-dark me-1">{item.product && item.product.name}</span>
                </div>
            </div>}
            <ToastU toast={toast} setToast={setToast}/>
            <AdminVerification
                show={adminVerificationModal}
                onHide={setAdminVerificationModal}
                appName={Keys.CP}
                setOnSuccess={setOnSuccess}
                toast={toast}
                setToast={setToast}
            />
        </ModalU>
    );
}

export default DeleteItem