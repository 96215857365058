import InputNumPad from "./InputNumPad";
import Keys from "../../../../../../utils/constants";

const Numpad = ({...props}) => {

    function onClickKeyPad(pad) {
        const input = props.value;
        switch(pad) {
            case '0':
                if (!input) props.setValue(pad);
                else if (input.at(0) != '0' || (input.at(1) && input.at(1) != '0'))
                    props.setValue(input + pad);
                break;
            case '.': if (input && !input.includes('.')) props.setValue(input + pad); break;
            case '<': props.setValue(input && input.slice(0, -1)); break;
            default:
                if (!input) props.setValue(pad);
                else if (input.at(0) != '0' || input.at(1) == '.')
                    props.setValue(input + pad);
                break;
        }
    }
    
    return (
        <div className="rounded-1 bg-brown-50 px-2 py-2">
            <InputNumPad type={'text'} value={props.value} setValue={props.setValue} innerRef={props.innerRef} lockNumPad={props.lockNumPad} bg={'bg-orange-100 border-secondary'}/>
            <table>
                <tbody>
                    { Keys.NUM_PADS.map((row, index) => {
                        return(
                            <tr key={index}>
                                { row.map((pad) => {
                                    return (
                                        <td key={pad}>
                                            <div className="m-1 bg-black rounded-2">
                                                <button 
                                                    className="btn bg-dark-500 card-default px-4 py-1 mb-2"
                                                    onClick={() => onClickKeyPad(pad)}
                                                    disabled={props.lockNumPad}
                                                    >
                                                    <code className="bold fs-1 text-white">{pad}</code>
                                                </button>
                                            </div>
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default Numpad;