import { useState } from 'react';
import useAuth from "../../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';
import AdminVerification from "../../../../../../../components/ui/AdminVerification";
import ModalU from '../../../../../../../components/ui/ModalU';
import Keys from "../../../../../../../utils/constants";
import ToastU from "../../../../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";

const DeleteAuditedProducts = ({...props}) => {
    const { t } = useTranslation();
    const { setTempUnlocked } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [adminVerificationModal, setAdminVerificationModal] = useState(false);
    const items = props.items;

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const handleSubmit = (event) => {
        event.preventDefault();
        setAdminVerificationModal(true);
    }

    const setOnSuccess = () => {
        setTempUnlocked(false);
        deleteSelectedItems();
    }

    const deleteSelectedItems = async() => {
        if (!checkItemsChecked(items)) return;

        const promises = items.map((item) => {
            if (item.closed || !item.checked) return;
            axiosPrivate.delete('/audited-products/delete/'+item.id);
        });

        await Promise.all(promises);
        props.setModalShow(false);
        props.refresh();
    }

    const checkItemsChecked = (items) => {
        return items.some((item) => item.checked);
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('WARNING')}
            actionbtntext={t('DELETE')}
            actionbtnclassname={"bg-red-400 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <div className="d-flex flex-column align-items-center">
                <span className="medium fg-red-300 font-18">{t('DATA_DELETION_CONFIRMATION')}</span>
                <div className="d-flex flex-column my-2">
                    <span className="medium fg-dark">{t('AUDITED_PRODUCTS')}</span>
                    <hr className='my-2'/>
                    {items.map((item, index) => {
                        if (!item.checked) return;
                        return (<span key={item.id} className="font-14 fg-dark me-1">{index+1}. {item.product && item.product.name}</span>)
                    })}
                </div>
            </div>
            <ToastU toast={toast} setToast={setToast}/>
            <AdminVerification
                show={adminVerificationModal}
                onHide={setAdminVerificationModal}
                appName={Keys.WH}
                setOnSuccess={setOnSuccess}
                toast={toast}
                setToast={setToast}
            />
        </ModalU>
    );
}

export default DeleteAuditedProducts