import { useEffect, useState } from "react";
import { faMinus, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Config from "../../../../../../config";
import ButtonUPush from "../../../../../../components/ui/ButtonUPush";
import DeleteSaleItem from "./DeleteSaleItem";
import icProduct from "../../../../../../assets/svg/ic_cube_scellet.svg";
import { useTranslation } from "react-i18next";
import Keys from "../../../../../../utils/constants";

const ActiveItem = ({...props}) => {
    const { t } = useTranslation();
   
    const saleItem = props.activeItem;
    const [quantity, setQuantity] = useState();

    useEffect(() => {
        if (saleItem) setQuantity(saleItem.quantity);
    }, [props.activeItem]);

    const [modalDelete, setModalDelete] = useState(false);

    const handleQuantity = (event) => {
        event.preventDefault();
        props.setActiveItem({...props.activeItem, quantity})
    }

    return (
        <div className="col-md-3">
            {(saleItem && saleItem.product) && <div className="row bg-white rounded-2 py-2 px-2 my-2">
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex flex-column">
                        <span className="font-16 medium fg-dark">{saleItem.product.name}</span>
                        <code className="font-12 fg-dark">{t('BAR_CODE')}: {saleItem.product.barcode}</code>
                    </div>
                    <div className="d-flex flex-column">
                        <span className="font-12 fg-dark">
                            {t('PRICE')}:
                            <span className="medium ms-1">
                                {saleItem.product.sellingPrice.toFixed(2)} {Keys.TMT}
                            </span>
                        </span>
                        {saleItem.product.discount ? 
                        <span className="font-12 text-white bg-orange-400 rounded-1 text-center">
                            {t('DISCOUNT')}:
                            <span className="medium ms-1">{saleItem.product.discount}%</span>
                        </span>
                        : <span></span>}
                    </div>
                </div>
                {saleItem.product.image ?
                <img src={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_PRODUCT_IMAGES}${saleItem.product.image}`} className="bg-white image-product"/>
                : <img src={icProduct} className="image-product p-5 bg-gray-50"/>
                }
                <div className="d-flex flex-row justify-content-between bg-light rounded-1 mt-1">
                    <div className="d-flex flex-column">
                        <code className="font-14 fg-dark">{t('TOTAL')}</code>
                        <code className="font-14 medium fg-dark">
                            {(saleItem.total + saleItem.discount).toFixed(2)} {Keys.TMT}
                        </code>
                    </div>
                    <div className="d-flex flex-column">
                        <code className="font-14 fg-dark">{t('DISCOUNT')}</code>
                        <code className="font-14 medium fg-red-300">{saleItem.discount.toFixed(2)} {Keys.TMT}</code>
                    </div>
                    <div className="d-flex flex-column">
                        <code className="font-14 fg-dark">{t('COMMON')} {t('TOTAL')}</code>
                        <code className="font-16 medium fg-green-500">{saleItem.total.toFixed(2)} {Keys.TMT}</code>
                    </div>
                </div>
                <span className="font-14 medium fg-dark mt-2">{t('QUANTITY')} [`]</span>
                <div className="d-flex flex-row align-items-center justify-content-between my-1">
                    <div className="d-flex flex-row align-items-center">
                        <ButtonUPush onSubmit={() => {
                            if (saleItem.quantity <= 1) return;
                                props.setActiveItem({...saleItem, quantity: saleItem.quantity-1})
                            }} shadow={"bg-gray-300"} icon={faMinus} className={"bg-gray font-18 card-default"}/>
                        <form onSubmit={handleQuantity}>
                            <input type="number" step={'any'} value={quantity} onChange={(e) => setQuantity(e.target.value)} ref={props.inputQuantityRef} className="p-2 font-18 rounded-1 w-100 input-inner-shadow"/>
                        </form>
                        <ButtonUPush onSubmit={() => {
                            if (saleItem.quantity >= saleItem.product.quantity) return;
                                props.setActiveItem({...saleItem, quantity: saleItem.quantity+1})
                            }} shadow={"bg-gray-300"} icon={faPlus} className={"bg-gray font-18 card-default"}/>
                    </div>
                    <ButtonUPush onSubmit={() => setModalDelete(true)} 
                        shadow={"bg-red-700"} icon={faTrashCan} className={"bg-red-400 font-18 text-white card-default"}/>
                </div>
            </div>}
            <DeleteSaleItem
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                toast={props.toast}
                setToast={props.setToast}
                item={saleItem}
                refresh={props.refresh}
            />
        </div>
    )
}

export default ActiveItem;