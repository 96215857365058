import { useState, useEffect } from "react";
import useAuth from '../../../../hooks/useAuth';
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import SettingsWrapper from "./../../layouts/SettingsWrapper";
import Utils from "../../../../utils";
import InputLabeled from '../../../../components/form/InputLabeled';
import ButtonU from "../../../../components/ui/ButtonU";
import ToastU from "../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";

const Profile = ({...props}) => {
    const { ready, auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const { t } = useTranslation();
    
    const [user, setUser] = useState({});
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setMiddleName] = useState('');
    
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('SETTINGS')}, { title: t('PROFILE'), active: true }
    ]

    useEffect(() => {
        if (ready) {
            getUser();
        }
    }, [ready, toast]);

    useEffect(() => {
        if (user) {
            setUsername(user.username);
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setMiddleName(user.middleName);
        }
    }, [user]);

    const getUser = () => {
        setLoading(true);
        axiosPrivate.get('/users/'+auth.user.id).then((res) => {
            if (res.status === 200) {
                setUser(res.data.data);
            }
        }).finally(() => {setLoading(false)});
    }

    const handleSubmit = () => {
        const requiredInputs = [
            { key: firstName, value: t('NAME_REQUIRED') },
            { key: lastName, value: t('LASTNAME_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        const data = { firstName, lastName, middleName };
        
        axiosPrivate.put('/users/update/'+user.id, data).then((res) => {
            if (res.status == 200) {
                setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) {
                setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 400: setToast({ content: t('SOMETHING_WENT_WRONG'), variant: 'danger', title: t('ERROR'), show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        }).finally(() => {setLoading(false)});
    }

    return (
        <SettingsWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="d-flex flex-column">
                <span className="fs-4 medium fg-dark">{t('MY_PROFILE')}</span>
                <hr />
                <div className="d-flex flex-row me-5 mt-3">
                    <div className="d-flex flex-row me-4 badge bg-orange-50 fg-dark py-2 px-3">
                        <span className="me-3">{t('ROLE')}:</span>
                        <span className="medium">{user.userAuth && user.userAuth.role.name}</span>
                    </div>
                    <div className="d-flex flex-row me-4 badge bg-orange-50 fg-dark py-2 px-3">
                        <span className="me-3">{t('VERIFIED')}:</span>
                        <span className="medium">{user.userAuth && user.userAuth.verified ? t('YES') : t('NO')}</span>
                    </div>
                    <div className="d-flex flex-row me-4 badge bg-orange-50 fg-dark py-2 px-3">
                        <span className="me-3">{t('ACTIVE')}:</span>
                        <span className="medium">{user.userAuth && user.userAuth.active ? t('YES') : t('NO')}</span>
                    </div>
                    <div className="d-flex flex-row badge bg-orange-50 fg-dark py-2 px-3">
                        <span className="me-3">{t('LAST_LOGIN')}:</span>
                        <span className="medium">{user.userAuth && Utils.getTimestampDateHm(user.userAuth.lastLogin)}</span>
                    </div>
                </div>
                <form className="d-flex flex-column mt-4">
                    <InputLabeled label={t('USERNAME')} type={'text'} value={username} setValue={setUsername} hint={t('USERNAME')} className={'col-md-4'} disabled={true}/>
                    <div className="d-flex flex-row row mt-2">
                        <InputLabeled label={t('FIRSTNAME')} type={'text'} value={firstName} setValue={setFirstName} hint={t('FIRSTNAME')} className={'col-md-3'} required={true}/>
                        <InputLabeled label={t('LASTNAME')} type={'text'} value={lastName} setValue={setLastName} hint={t('LASTNAME')} className={'col-md-3'} required={true}/>
                        <InputLabeled label={t('MIDDLENAME')} type={'text'} value={middleName} setValue={setMiddleName} hint={t('MIDDLENAME')} className={'col-md-3'}/>
                    </div>
                    <div className="mt-2">
                        <ButtonU onSubmit={handleSubmit} className={`bg-green-700 rounded-3 text-white px-3`} text={t('UPDATE')}/>
                    </div>
                </form>
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </SettingsWrapper>
    )
}

export default Profile;