import icPageNotFound from "../assets/svg/ic_page_not_found.svg"
import { useTranslation } from "react-i18next";

const PageNotFound = () => {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column align-items-center justify-content-center m-5 p-5">
            <span className="bold fs-2 mb-2">{t('PAGE_NOT_FOUND')}</span>
            <img src={icPageNotFound} className="image-product" alt="page not found"/>
        </div>
    );
}

export default PageNotFound;