import { useState, useEffect } from "react";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { useNavigate } from "react-router-dom";
import { faCancel, faReceipt, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../../utils";
import Keys from "../../../../../../utils/constants";
import InvoiceWrapper from "../../InvoiceWrapper";
import ButtonU from "../../../../../../components/ui/ButtonU";
import InputField from "../../../../../../components/form/InputField";
import InvoiceItems from "./InvoiceItem/InvoiceItems";
import ToastU from "../../../../../../components/ui/ToastU";
import Cancel from "../../../../../../components/ui/Cancel";
import Status from "../../../../../../components/ui/Status";
import { useTranslation } from "react-i18next";

const Create = () => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [invoiceNumber, setInvoiceNumber] = useState('');

    const [invoice, setInvoice] = useState({});
    const [invoiceItems, setInvoiceItems] = useState([]);
    
    const [modalCancel, setModalCancel] = useState(false);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('INVOICE'), link: '/invoice/invoices' } ,
        { title: t('INVOICE_RETURNS'), link: '/invoice/invoice-returns' },
        { title: t('NEW'), active: true}
    ]

    const handleFindInvoiceNumber = (event) => {
        event.preventDefault();

        setLoading(true);
        const id = undefined;
        axiosPrivate.get('/invoices/'+id+'/'+invoiceNumber).then((res) => {
            if (res.status == 200) {
                setInvoice(res.data.data);
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        })
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (invoice.id) {
            invoice.invoiceItems.forEach((item) => {
                item.stock = item.quantity;
                item.quantity = 0;
                item.checked = false;
                item.description = '';
            });
            setInvoiceItems(invoice.invoiceItems);
        }
    }, [invoice.id]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const checkedItems = [];
        invoiceItems.map((item) => { if (item.checked) checkedItems.push(item) })

        const requiredInputs = [
            { key: invoiceNumber, value: t('INVOICE_NUMBER_REQUIRED') },
            { key: !(checkedItems.length < 1), value: `${t('DATA_NOT_ENTERED_YET')} (${t('INVOICE')} ${t('ITEMS')})` },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        setLoading(true);
        const data = { invoiceItems: checkedItems };

        axiosPrivate.post('/invoice-item-returns/create-bulk', data).then((res) => {
            if (res.status == 200) {
                setToast({ content: res.data.msg, variant: 'success', title: t('SUCCESS'), show: true });
                navigate(-1);
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => setLoading(false));
    }

    return (
        <InvoiceWrapper loading={loading} breadcrumbs={breadcrumbs}>
             <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('INVOICE')} {t('RETURN')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={() => {setModalCancel(true)}} icon={faCancel} text={t('CANCEL')} className={"bg-red-400 bg-gradient text-white"}/>
                    <ButtonU onSubmit={handleSubmit} icon={faSave} text={t('SAVE')} className={"bg-green-700 bg-gradient text-white"}/>
                </div>
            </div>

            <div className="d-flex flex-column my-2 py-2 mt-5">
                <form onSubmit={handleFindInvoiceNumber} className="d-flex flex-row mt-2 mx-3">
                    <InputField type={"text"} value={invoiceNumber} setValue={setInvoiceNumber} hint={`${t('INVOICE')} ${t('NUMBER')}`} icon={faReceipt} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <ButtonU onSubmit={handleFindInvoiceNumber} icon={faSearch} className={"bg-blue-700 bg-gradient text-white"}/>
                </form>
                <div className="row d-flex flex-row mx-0 p-3">
                    {invoice.id && <div className="d-flex flex-column col-md-8 border border-gray p-3">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="d-flex flex-column">
                                <span className="font-20 medium fg-dark">{invoice.supplier.companyName}</span>
                                <span className="font-16 medium fg-dark">{invoice.supplier.contactName}</span>
                                <span className="font-12 fg-dark">{invoice.supplier.addressLine}</span>
                                <span className="font-12 fg-dark">{invoice.supplier.province.name} {invoice.supplier.district.name}  {invoice.supplier.postalCode}</span>
                            </div>
                            <div className="d-flex flex-column">
                                <span className="fs-1 medium fg-blue-300">{t('INVOICE').toUpperCase()}</span>
                                <span className="font-12 text-secondary">{t('INVOICE_TYPE')}: <span className="fg-dark medium">{invoice.invoiceType.name}</span></span>
                                <span className="font-12 text-secondary">{t('NUMBER')}: <span className="fg-dark medium">#{invoice.number}</span></span>
                                <span className="font-12 text-secondary">{t('DATE')}: <span className="fg-dark medium">{Utils.getTimestampDate(invoice.createdAt)}</span></span>
                            </div>
                        </div>
                        <hr  className="my-2"/>
                        <div className="d-flex flex-row justify-content-between">
                            <div className="d-flex flex-column">
                                <span className="font-12 fg-dark">{t('WAREHOUSE')}: <span className="medium">{invoice.warehouse.name}</span></span>
                                <span className="font-12 fg-dark">{t('BRANCH')}: <span className="medium">{invoice.warehouse.branch.name} {invoice.warehouse.branch.district.name} {invoice.warehouse.branch.province.name}</span></span>
                            </div>
                            <div className="d-flex flex-column">
                                <span className="font-12 fg-dark">{t('START_DATE')}: <span className="medium">{invoice.issueDate ? invoice.issueDate : '-'}</span></span>
                                <span className="font-12 fg-dark">{t('EXPIRE_DATE')}: <span className="medium">{invoice.expireDate ? invoice.expireDate : '-'}</span></span>
                            </div>
                        </div>
                        <hr  className="my-2"/>
                        {invoiceItems.length > 0 &&
                            <InvoiceItems items={invoiceItems} setItems={setInvoiceItems}/>
                        }
                        <span className="font-14 medium fg-dark">{t('TOTAL')} {t('ITEM')}: {invoiceItems.length}</span>
                        <hr  className="my-2"/>
                        <div className="d-flex flex-row justify-content-between">
                            <div className="d-flex flex-column">
                                <span className="font-12 medium fg-dark">{t('PAYMENT_TERMS')}</span>
                                <span className="font-12 fg-dark">{invoice.paymentTerms ? invoice.paymentTerms : '-'}</span>
                                <span className="font-12 medium fg-dark">{t('PAYMENT_DETAILS')}</span>
                                <span className="font-12 fg-dark">{invoice.paymentDetail ? invoice.paymentDetail : '-'}</span>
                                <span className="font-12 medium fg-dark">{t('NOTE')}</span>
                                <span className="font-12 fg-dark">{invoice.note ? invoice.note : '-'}</span>
                            </div>
                            <div className="d-flex flex-column">
                                <span className="font-14 medium fg-dark">{t('TOTAL')}</span>
                                <span className="font-18 fg-dark bold">{invoice.total.toFixed(2)} {Keys.TMT}</span>
                                <span className="font-12 text-secondary mt-3">{t('PAID')}: <span className="fg-dark medium">{invoice.paid ? invoice.paid.toFixed(2) : 0} {Keys.TMT}</span></span>
                                <span className="font-12 text-secondary mt-1">{t('STATUS')}: <Status  status={invoice.closed}/></span>
                            </div>
                        </div>
                    </div>}
                    {invoice.user && <div className="col-md-4">
                        <div className="ms-2 border p-2">
                            <div className="d-flex flex-row justify-content-between">
                                <div className="d-flex flex-column">
                                    <span className="font-12 text-secondary medium">{t('REGISTRATION_DATE')}</span>
                                    <span className="fg-dark font-14">{Utils.getTimestampDateHm(invoice.createdAt)}</span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className="font-12 text-secondary medium">{t('UPDATED_AT')}</span>
                                    <span className="fg-dark font-14">{Utils.getTimestampDateHm(invoice.updatedAt)}</span>
                                </div>
                            </div>
                            <div className="d-flex flex-column mt-3">
                                <span className="font-12 text-secondary medium">{t('ACCEPTING')} ({t('USER')})</span>
                                <span className="fg-dark font-14">{invoice.user.fullName}</span>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <Cancel modalShow={modalCancel} setModalShow={setModalCancel}/>
            <ToastU toast={toast} setToast={setToast}/>
        </InvoiceWrapper>
    );
}

export default Create;