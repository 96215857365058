import { useState, useEffect, useRef } from "react";
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { useNavigate, useParams } from "react-router-dom";
import { faBarcode, faMagnifyingGlass, faCreditCard, faMoneyBill, faMoneyCheckDollar, faBinoculars, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import BarcodeReader  from "react-barcode-reader";
import Wrapper from "../../../layout/Wrapper";
import InputField from "../../../../../components/form/InputField";
import ButtonU from "../../../../../components/ui/ButtonU";
import ButtonUPush from "../../../../../components/ui/ButtonUPush";
import SaleItems from "./components/SaleItems";
import ToastU from "../../../../../components/ui/ToastU";
import PaymentTypeRadioBtn from "./components/PaymentTypeRadioBtn";
import Numpad from "./components/Numpad";
import SearchProduct from "./components/SearchProduct";
import ActiveItem from "./components/ActiveItem";
import Footer from "./components/Footer";
import MostSold from "./components/MostSold";
import ClearSaleItems from "./components/ClearSaleItems";
import DiscardSale from "./components/DiscardSale";
import PrintReceipt from "../../../../../components/ui/PrintReceipt";
import RightBar from "./components/RightBar";
import { useTranslation } from "react-i18next";
import PrintReceiptCheckBox from "./components/PrintReceiptCheckBox";

const NewSale = () => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const params = useParams();
    const barcodeRef = useRef();
    const inputPaidRef = useRef();
    const inputQuantityRef = useRef();

    const [lockNumPad, setLockNumPad] = useState(true);
    const [lockMakePayment, setLockMakePayment] = useState(true);
    const [printReceipt, setPrintReceipt] = useState(false);

    const [barcode, setBarcode] = useState('');
    const [terminatedSale, setTerminatedSale] = useState({});
    const [sale, setSale] = useState({});
    const [saleItems, setSaleItems] = useState([]);

    const [paymentType, setPaymentType] = useState('CASH');
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [activeItem, setActiveItem] = useState({});
   
    const [paid, setPaid] = useState();
    const [change, setChange] = useState(0);
    
    const [mostSold, setMostSold] = useState([]);

    const [modalSearchProduct, setModalSearchProduct] = useState(false);
    const [modalDiscardSale, setModalDiscardSale] = useState(false);
    const [modalClearItems, setModalClearItems] = useState(false);
    
    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        if (ready) {
            getSale();
            getMostSold();
        }
    }, [ready]);

    useEffect(() => {
        setTimeout(() => {
            barcodeRef.current && barcodeRef.current.focus();
        }, 500);
    }, [])

    useEffect(() => {
        const handleKeyDown = (event) => {
            switch(event.key) {
                case 'F4': terminateSale(); break;
                case 'Escape': setModalDiscardSale(true); break;
                case 'End': setModalClearItems(true); break;
                case 'F8': barcodeRef.current.focus(); break;
                case 'F2': inputPaidRef.current.focus(); break;
                case '`': inputQuantityRef.current.focus(); break;
                case 'Insert': setPrintReceipt(!printReceipt); break;
                case 'Home': setPaymentType('CASH'); break;
                case 'F9': setPaymentType('CARD'); break;
                case 'Pause': setPaymentType('CASH+CARD'); break;
                default: break;
            }
        }
        
        window.addEventListener('keydown', handleKeyDown);
        return () => { window.removeEventListener('keydown', handleKeyDown) };
    }, [sale, printReceipt]);

    const getSale = () => {
        axiosPrivate.get('/sales/'+params.id).then((res) => {
            if (res.status === 200) {
                const sale = res.data.data;
                if (sale.closed) navigate(-1);
                
                setSale(sale);
                setSale(prev => {
                    return {...prev, paymentType, paid, change}
                });
                setSaleItems(sale.saleItems);
                setActiveItemIndex(0);
                setActiveItem(sale.saleItems[0]);
            }
        });
    }

    const getMostSold = () => {
        const query = { filter: { mostSold: true } }
        axiosPrivate.get('/products', { params: query }).then((res) => {
            if (res.status === 200) {             
                setMostSold(res.data.data);
            }
        });
    }

    // reset numpad input
    useEffect(() => {
        switch (paymentType) {
            case 'CARD': setPaid(sale.total - (sale.discountExtra / 100) * sale.total); break;
            default: setPaid(''); break;
        }
    }, [paymentType]);

    // lock make payment if doesn't exist any sale items
    useEffect(() => {
        setLockMakePayment(saleItems.length <= 0);
    }, [saleItems.length]);

    useEffect(() => {
        setLockMakePayment(sale.total <= 0);
    }, [sale]);
 
    // toggle make payment lock
    useEffect(() => {
        switch (paymentType) {
            case 'CASH': setLockMakePayment(paid < sale.total); break;
            case 'CARD': setLockMakePayment(false); break;
            case 'CASH+CARD': setLockMakePayment(paid <=  0); break;
            default: break;
        }
    }, [paymentType, paid]);

    // toggle numpad lock
    useEffect(() => {
        switch (paymentType) {
            case 'CARD': setLockNumPad(true); break;
            default: setLockNumPad(saleItems.length <= 0); break;
        }
    }, [paymentType, saleItems.length]);

    // calculate change
    useEffect(() => {
        if (paid) {
            const result = (paid - calculateTotal(sale));
            setChange(result <= 0 ? 0 : result);
        } else setChange(0);
    }, [paid, sale.total]);

    const calculateTotal = (sale) => {
        const discountExtra = (sale.discountExtra / 100) * sale.total;
        return (sale.total - discountExtra);
    }

    const paymentTypes = [
        { id: 'CASH', name: t('CASH'), icon: faMoneyBill, shortCut: "[Home]" },
        { id: 'CARD', name: t('CARD'), icon: faCreditCard, shortCut: "[F9]" },
        { id: 'CASH+CARD', name: t('CASH_CARD'), icon: faMoneyCheckDollar, shortCut: "[Pause]" },
    ];

    const handleScan = (data) => {
        addSaleItem(data);
    }

    const handleError = (err) => {
        console.error(err);
    }

    const handleBarcode = (event) => {
        event.preventDefault();
        addSaleItem(barcode);
    }

    const addSaleItem = (barcode) => {
        const data = {sale, barcode};
        axiosPrivate.put('/sales/update-with-items/'+sale.id, data).then((res) => {
            if (res.status == 200)
                refresh();
        }).catch((err) => {
            switch(err.response.status) {
                case 400: setToast({ content: t('SOMETHING_WENT_WRONG'), variant: 'danger', title: t('ERROR'), show: true }); break;
                case 404: setToast({ content: t('PRODUCT_NOT_FOUND'), variant: 'warning', title: t('WARNING'), show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        }).finally(() => setBarcode(''));
    }

    useEffect(() => {
        if (!activeItem || saleItems.length < 0 || !activeItem.quantity) return;
        else if (activeItem.quantity != saleItems[activeItemIndex].quantity) {
            updateQuantity();
        }
    }, [activeItem]);

    const updateQuantity = () => {
        const data = {saleItem: activeItem};
        axiosPrivate.put('/sales/update-with-items-quantity/'+sale.id, data).then((res) => {
            if (res.status == 200)
                refresh();
        }).catch((err) => {
            switch(err.response.status) {
                case 400: setToast({ content: t('SOMETHING_WENT_WRONG'), variant: 'danger', title: t('ERROR'), show: true }); break;
                case 405: setToast({ content: t('QUANTITY_NOT_ENOUGH'), variant: 'warning', title: t('WARNING'), show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        }).finally(() => setBarcode(''));
    }

    const refresh = () => { getSale(); }

    useEffect(() => {
        if (sale) {
            setSale(prev => {
                return {...prev, paymentType, paid, change}
            });
        }
    }, [sale.id, paymentType, paid, change]);

    const terminateSale = () => {
        const data = { sale, paymentType, paid: parseFloat(paid), change };
        axiosPrivate.put('/sales/terminate/'+sale.id, data).then((res) => {
            if (res.status == 200) {
                if (printReceipt) getTerminatedSale();
                else navigate('/cp');
            }
        }).catch((err) => {
            if (!err?.response) {
                setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 400: setToast({ content: t('SOMETHING_WENT_WRONG'), variant: 'danger', title: t('ERROR'), show: true }); break;
                case 420: setToast({ content: t('WITHOUT_PRODUCTS_YOU_CANNOT_MAKE_PAYMENT'), variant: 'warning', title: t('WARNING'), show: true }); break;
                case 421: setToast({ content: t('PAYMENT_NOT_ENOUGH'), variant: 'warning', title: t('WARNING'), show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        });
    }

    const getTerminatedSale = () => {
        axiosPrivate.get('/sales/'+params.id).then((res) => {
            if (res.status == 200) {
                const sale = res.data.data;
                setTerminatedSale(sale);
                handlePrintReceipt();
            }
        });
    }

    const handlePrintReceipt = () => {
        setTimeout(() => { window.print(); navigate('/cp'); }, 500);
    }

    return (
        <div>
            <BarcodeReader onScan={handleScan} onError={handleError}/>
            <div className="hide-on-print">
                <Wrapper>
                    <div className="cp-bg">
                        <div className='d-flex align-items-center justify-content-between mt-1 mx-3'>
                            <div className="d-flex">
                                <ButtonUPush onSubmit={() => {navigate(-1)}} text={<span>{t('BACK')}</span>} className={"bg-white font-14 medium fg-blue-500 card-default"} shadow={"bg-gray-300"} icon={faChevronLeft}/>
                                <ButtonUPush onSubmit={() => {setModalDiscardSale(true)}} text={<span>{t('DISCARD_SALE')}<sup className="ms-1 small">[Esc]</sup></span>} className={"bg-red-400 font-14 medium text-white card-default"} shadow={"bg-red-700"}/>
                                <ButtonUPush onSubmit={() => {setModalClearItems(true)}} text={<span>{t('CLEAR')}<sup className="ms-1 small">[End]</sup></span>} className={"bg-white font-14 medium fg-gray card-default"} shadow={"bg-gray-300"} disabled={saleItems.length <= 0}/>
                            </div>
                            <div className="d-flex">
                                <PrintReceiptCheckBox value={printReceipt} setValue={setPrintReceipt}/>
                                <ButtonUPush onSubmit={terminateSale} text={<span>{t('MAKE_PAYMENT')}<sup className="ms-1 small">[F4]</sup></span>} className={"bg-green-600 font-14 medium text-white card-default"} shadow={"bg-green-700"} disabled={lockMakePayment}/>
                            </div>
                        </div>
                        <div className="row mx-2">
                            <div className="col-md-4">
                                <div className="d-flex flex-row align-items-center my-2">
                                    <ButtonU onSubmit={() => setModalSearchProduct(true)} icon={faBinoculars} className={"bg-white bg-gradient font-18 medium fg-dark"}/>
                                    <form onSubmit={handleBarcode} className="d-flex flex-fill">
                                        <InputField type={"number"} value={barcode} setValue={setBarcode} hint={`${t('BAR_CODE')} [F8]`} icon={faBarcode} innerRef={barcodeRef} formClass="input-field-container font-18 flex-fill" inputClass="input-field-outline w-100 input-inner-shadow"/> 
                                        <ButtonU onSubmit={handleBarcode} icon={faMagnifyingGlass} className={"bg-blue-700 bg-gradient font-18 medium text-white"}/>
                                    </form>
                                </div>
                                <SaleItems data={saleItems} setActiveItem={setActiveItem} setActiveItemIndex={setActiveItemIndex}/>
                            </div>

                            <ActiveItem
                                activeItem={activeItem}
                                setActiveItem={setActiveItem}
                                refresh={refresh}
                                toast={toast}
                                inputQuantityRef={inputQuantityRef}
                                setToast={setToast}
                            />

                            <div className="col-md-5">
                                <div className="rounded-1 bg-gray-100 p-2 d-flex flex-column mt-2 mb-3">
                                    <PaymentTypeRadioBtn items={paymentTypes} inline={false} group={t('PAYMENT_TYPE')} attr={paymentType} onChangeAttr={setPaymentType} type={'radio'}/>
                                    <div className="d-flex flex-row mt-1">
                                        <Numpad value={paid} setValue={setPaid} lockNumPad={lockNumPad} innerRef={inputPaidRef} paid={paid}/>
                                        <MostSold mostSold={mostSold} addSaleItem={addSaleItem} setFind={setBarcode}/>
                                        <RightBar sale={sale} setSale={setSale} toast={toast} setToast={setToast} refresh={refresh}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer sale={sale} paid={paid} change={change} paymentType={paymentType}/>
                    </div>
                    <SearchProduct
                        modalShow={modalSearchProduct}
                        setModalShow={setModalSearchProduct}
                        setBarcode={setBarcode}
                        add={addSaleItem}/>
                    <ToastU toast={toast} setToast={setToast}/>
                    <ClearSaleItems
                        modalShow={modalClearItems}
                        setModalShow={setModalClearItems}
                        toast={toast}
                        setToast={setToast}
                        sale={sale}
                        items={saleItems}
                        refresh={refresh}
                    />
                    <DiscardSale
                        modalShow={modalDiscardSale}
                        setModalShow={setModalDiscardSale}
                        toast={toast}
                        setToast={setToast}
                        sale={sale}
                    />
                </Wrapper>
            </div>
            <PrintReceipt sale={terminatedSale} saleItems={terminatedSale.saleItems} paymentType={paymentType} paid={paid} change={change}/>
        </div>
    );
}

export default NewSale;