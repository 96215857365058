import { Link } from "react-router-dom";
import Keys from "../../../../../../utils/constants";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

const CardTotal = ({...props}) => {
    const { t } = useTranslation();
    
    return (
        <div className="col-md-3">
            <div className="d-flex flex-column rounded-1 shadow px-4 pb-4 pt-2">
                <div className="d-flex flex-row justify-content-between">
                    <span className="medium text-secondary">{t('TOTAL')}</span>
                    <Link to={props.link}><FontAwesomeIcon icon={faArrowUpRightFromSquare} className="font-12"/></Link>
                </div>
                <span className="bold fs-3 fg-dark">
                    {(props.value ? props.value : 0).toFixed(2)} <span className="fs-5">{Keys.TMT}</span>
                </span>
                <span className="fg-dark">{props.title}</span>
            </div>
        </div>
    );
}

export default CardTotal;