import { useTranslation } from "react-i18next";

const SelectCashier = ({...props}) => {
    const { t } = useTranslation();

    const handleChange = (event) => {
        const value = event.target.value;
        props.setQuery(prev => {
            return {...prev, filter: {
                ...prev.filter, cashierId: (value == t('ALL') ? '' : value)}
            }
        });
    }

    return (
        <div className="d-flex flex-row align-items-center bg-blue-600 rounded-2 p-2 me-2">
            <span className="font-16 medium ms-1 me-2 text-white">{t('CASHIER')}</span>
            <div className={`d-flex flex-column`}>
                <select
                    size={props.size}
                    value={props.query.filter.cashierId}
                    onChange={handleChange}
                    className={`rounded-2 border px-2 font-16 bg-light`}>
                    <option value={""}>{t('ALL')}</option>
                    { props.items.map((item) => {
                        return (<option value={item.id} key={item.id}>{item.username}</option>)
                    }) }
                </select>
            </div>
        </div>
    );
}

export default SelectCashier;