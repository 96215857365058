import ModalU from '../../../../../../components/ui/ModalU';
import Utils from "../../../../../../utils";
import { useTranslation } from "react-i18next";

const Show = ({...props}) => {
    const { t } = useTranslation();
    const item = props.item;

    return (
        <ModalU show={props.modalShow} size={''} title={t('LOG')} onHide={props.setModalShow}>
            {item.id && <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-column font-12 flex-fill">
                    <div className="d-flex flex-row justify-content-between">
                        <span className="medium">{t('USER')}:</span>
                        <span>{item.user.username}</span>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                        <span className="medium">{t('EVENT')}:</span>
                        <span>{item.eventType}</span>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                        <span className="medium">{t('IP')}:</span>
                        <span>{item.ipv4}</span>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                        <span className="medium">{t('DATE')}:</span>
                        <span>{Utils.getTimestampDate(item.createdAt)}</span>
                    </div>
                </div>
                <div className="d-flex flex-column font-12 flex-fill ms-4">
                    <div className="d-flex flex-row justify-content-between">
                        <span className="medium">{t('FULLNAME')}:</span>
                        <span>{item.user.fullName}</span>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                        <span className="medium">{t('ACTION')}:</span>
                        <span>{item.actionType}</span>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                        <span className="medium">{t('MAC')}:</span>
                        <span>{item.mac}</span>
                    </div>
                </div>
            </div>}
            <hr className='my-1' style={{"border-top": "dashed 2px"}}/>
            <span className='medium font-14'>{t('DATA')}</span>
            <hr className='my-1'/>
            <div style={{"overflow-y": "scroll", "height": "250px"}}>
                <code className='font-12 fg-dark'>{JSON.stringify(item.dataEntry, null, '\t')}</code>
            </div>
        </ModalU>
    );
}

export default Show