import { useEffect, useState } from "react";
import useAuth from "./../hooks/useAuth";
import UserAvatar from "../components/ui/UserAvatar";
import DropdownLang from "./DropdownLang";
import { Drawer } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BreadCrumbsU from "./../components/ui/BreadCrumbsU";
import icLogo from "../assets/svg/ic_logo.png";
import icHome from "../assets/svg/ic_home.svg";
import icSettingsOutline from "../assets/svg/ic_settings_outline.svg";
import icRouter from "../assets/svg/ic_router.svg";
import LinearIndeterminant from "../components/ui/PbLinearIndeterminant";
import ProgressBar from "../components/ui/ProgressBar";
import RightSideModal from "../components/ui/RightSideModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Utils from "../utils";

const Header = ({...props}) => {
    const { auth } = useAuth();
    const navigate = useNavigate();

    const [localIp, setLocalIp] = useState("");
    const [open, setOpen] = useState(false);

    const toggleDrawer = (flag) => () => {
        setOpen(flag)
    };

    return (
        <div className="header shadow-sm">
            <div className="d-flex flex-row align-items-center justify-content-between px-3">
                <div className="d-flex flex-row px-1 align-items-center">
                    <div className="d-flex flex-row align-items-center me-3">
                        <img src={icLogo} alt="logo" onClick={() => navigate('/')} className="wh-35 me-3 hover-cursor"/>
                        {props.rootPageLink !== '/' && <img src={icHome} alt="logo" onClick={() => navigate('/')} className="wh-35 px-1 hover-cursor rounded-2 btn-hover"/>}
                        <BreadCrumbsU items={props.breadcrumbs} className={'pt-1 ms-2'}/>
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center">
                    {/* <div className="d-flex flex-row bg-light border py-1 px-2 rounded-2 me-3">
                        <img src={icRouter} className="wh-20 me-1"/>
                        <span className="medium font-13 fg-dark">{localIp}</span>
                    </div> */}
                    <DropdownLang/>
                    <div className="d-flex flex-row align-items-center">
                        <div className="d-flex flex-row align-items-center hover-cursor me-1"
                            onClick={toggleDrawer(true)}>
                            <UserAvatar user={auth?.user} style={'bg-blue-600 text-white wh-35'}/>
                            <FontAwesomeIcon icon={faAngleDown} className="small-x ms-2 fg-gray"/>
                        </div>
                        <div className="d-flex flex-column ms-2">
                            <span className="fg-dark font-14 medium">{Utils.capitalizeFirstLetter(auth?.user?.firstName)} {Utils.capitalizeFirstLetter(auth?.user?.lastName)}</span>
                            <span className="fg-gray font-12">{auth?.role?.name}</span>
                        </div>
                        <span className="fg-gray-50 font-20 mx-3">|</span>
                        <div onClick={() => {navigate('/settings/data/general')}} className="hover-cursor">
                            <img src={icSettingsOutline} className="wh-35 p-2 bg-gray-50 rounded-2" />
                        </div>
                    </div>
                </div>
            </div>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <RightSideModal onClose={toggleDrawer(false)}/>
            </Drawer>
            {props.loading && <LinearIndeterminant/>}
            {props.loading && <ProgressBar loading={props.loading}/>}
        </div>
    );
}

export default Header;