import { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import Utils from "../../../utils";
import Wrapper from "../../../layouts/Wrapper";
import NavBar from "../../../layouts/NavBar";
import Keys from "../../../utils/constants";
import icDashboard from '../../../assets/svg/ic_dashboard.svg';
import icProduct from '../../../assets/svg/ic_cube.svg';
import icDocument from '../../../assets/svg/ic_receipt.svg';
import icWarehouse from '../../../assets/svg/ic_warehouse.svg';
import icBuilding from '../../../assets/svg/ic_building.svg';
import icFinance from '../../../assets/svg/ic_chart.svg';
import { useTranslation } from "react-i18next";

const MainWrapper = ({...props}) => {
    const { t } = useTranslation();
    const { ready, auth } = useAuth();
    const [allowedItems, setAllowedItems] = useState([]);

    let items = [   
        { name: t('DASHBOARD'), id: "1000", links: Keys.DASHBOARD_LINKS, icon: icDashboard },
        { name: t('PRODUCT'), id: "1001", links: Keys.PRODUCT_LINKS, icon: icProduct },
        { name: t('INVOICE'), id: "1002", links: Keys.INVOICE_LINKS, icon: icDocument },
        { name: t('WAREHOUSE'), id: "1003", links: Keys.WAREHOUSE_LINKS, icon: icWarehouse },
        { name: t('COMPANY'), id: "1004", links: Keys.COMPANY_LINKS, icon: icBuilding },
        { name: t('FINANCE'), id: "1005", links: Keys.FINANCE_LINKS, icon: icFinance },
    ];

    useEffect(() => {
        if (!ready) return;
        setAllowedItems(items.filter(item => Utils.allowedRole(auth, item.id, 'read')));
    }, [auth, t]);

    return (
        <Wrapper loading={props.loading} breadcrumbs={props.breadcrumbs}>
            <NavBar items={allowedItems} wrapper={'MAIN'}/>
            {props.children}
        </Wrapper>
    );
};

export default MainWrapper;