import { useEffect, useRef, useState } from "react";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Modal } from 'react-bootstrap';
import icEmail from './../../assets/svg/ic_email.svg';
import icLock from './../../assets/svg/ic_lock.svg';
import icEyeOpen from './../../assets/svg/ic_eye_open.svg';
import icXmarkSquare from "../../assets/svg/ic_x_mark_square.svg";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ButtonU from "./ButtonU";
import ToastU from "./ToastU";
import { useTranslation } from 'react-i18next';
import InputLabelIcon from "../form/InputLabelIcon";

const AdminVerification = ({...props}) => {
    const { t } = useTranslation();
    const { setTempUnlocked } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const usernameRef = useRef();
    
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [hiddenText, setHiddenText] = useState('');

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const dissmiss = () => { props.onHide(false); }
    
    useEffect(() => {
        setHiddenText(hiddenText + password);
    }, [password]);
    
    const handleSubmitUnlock = (event) => {
        event.preventDefault();
        if (!hiddenText) {
            setToast({ content: t('INVALID_ENTRY'), variant: 'danger', title: t('WARNING'), show: true });
            return;
        }

        const data = { appName: props.appName, username, password: hiddenText };
        axiosPrivate.post('/auth/app-passwords/check', data).then((res) => {
            if (res.status == 200) {
                setTempUnlocked(true);
                if (props.setOnSuccess) props.setOnSuccess();
                dissmiss();
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        });
        
        setHiddenText('');
    }

    return (
        <Modal {...props} size={'sm'} aria-labelledby="contained-modal-title-vcenter" centered>
            <div className="p-3 bg-dark">
                <div className="d-flex align-items-center justify-content-between">
                    <span className="fs-5 medium text-white">{t('CONFIRMATION')}</span>
                    <img src={icXmarkSquare} className='wh-25 hover-cursor bg-red-200' onClick={dissmiss}/>
                </div>
                <form className="d-flex flex-column align-items-center my-1" onSubmit={handleSubmitUnlock}>
                    <span className="medium text-white my-2">{t('ADMIN')}</span>
                    <InputLabelIcon 
                        formClass="" inputClass={'col-md-12'}
                        id={'username'} type={'text'} ref={usernameRef}
                        value={username} setValue={setUsername}
                        hint={t('USERNAME')} autoFocus={true} required={true}
                        label={t('USERNAME')} labelClass={'text-white'} icon={icEmail}
                    />
                    <InputLabelIcon 
                        formClass={'mt-3 mb-2'} inputClass={'col-md-12'}
                        id={'password'} type={'text'} ref={null}
                        value={''} setValue={setPassword}
                        hint={t('YOUR_PASSWORD')} autoFocus={false} required={true}
                        label={t('PASSWORD')} labelClass={'text-white'} icon={icLock}
                    />
                    <ButtonU onSubmit={handleSubmitUnlock} icon={faCheck} text={t('CONFIRM')} className={"bg-blue-200 border-info fg-blue-500 bg-gradient mt-2"}/>
                </form>
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </Modal>
    )
}

export default AdminVerification;