import { useState, useEffect } from "react";
import useAuth from '../../../../../../hooks/useAuth';
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { useParams } from "react-router-dom";
import Keys from "../../../../../../utils/constants";
import HomeWrapper from "../../HomeWrapper";
import TableStd from '../../../../../../components/ui/TableStd';
import { ListGroup } from "react-bootstrap";
import Utils from "../../../../../../utils";
import DataNotFound from '../../../../../../components/ui/DataNotFound';
import Status from "../../../../../../components/ui/Status";
import PrintReceipt from "../../../../../../components/ui/PrintReceipt";
import icPrinter from "../../../../../../assets/svg/ic_printer.svg";
import { useTranslation } from "react-i18next";
import PaymentTypes from "../../../../../../components/ui/PaymentTypes";

const Sale = () => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();
    const PAYMENT_TYPES = PaymentTypes();

    const [sale, setSale] = useState({});
    const [saleItems, setSaleItems] = useState([]);
    const [saleItemsCount, setSaleItemsCount] = useState();

    useEffect(() => {
        if (ready) {
            getSale();
        }
    }, [ready]);

    const getSale = () => {
        axiosPrivate.get('/sales/'+params.id).then((res) => {
            if (res.status == 200) {
                const sale = res.data.data;
                setSale(sale);
                setSaleItems(sale.saleItems);
                setSaleItemsCount(sale.saleItems.length);
            }
        });
    }

    const handlePrintReceipt = () => { window.print(); }

    const modelProps = [
        { label: t('NUMBER'), value: (prop) => { return(<>{prop.number}</>) } },
        { label: t('CASHIER'), value: (prop) => { return(<>{prop.cashier && prop.cashier.fullName}</>) } },
        { label: t('REGISTRATION_DATE'), value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.createdAt)}</>)} },
        { label: t('UPDATED_AT'), value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.updatedAt)}</>)} },
        { label: t('PAYMENT_TYPE'), value: (prop) => { return(<>{PAYMENT_TYPES.get(prop.paymentType)}</>) } },
        { label: t('STATUS'), value: (prop) => { return(<Status  status={prop.closed}/>)} },
        { label: t('NOTE'), value: (prop) => { return(<>{prop.note ? prop.note : '-'}</>)} },
    ];

    const modelProps2 = [ 
        { 
            name: t('PRODUCT'),
            value: (prop) => { return(<>{prop.product.name}</>) } 
        },
        { 
            name: t('BAR_CODE'),
            value: (prop) => { return(<>{prop.product.barcode}</>) } 
        },
        {
            name: t('QUANTITY'),
            value: (prop) => { return(<>{prop.quantity} {prop?.product?.unit?.name}</>) }
        }
    ];

    return (
        <div>
            <div className="hide-on-print">
                <HomeWrapper>
                    <div className="d-flex flex-row align-items-center justify-content-between bg-gray-50 px-2">
                        <span className="fs-3 bold fg-dark">{t('SALE')}: {sale.number}</span>
                        <span onClick={handlePrintReceipt}>
                            <img src={icPrinter} alt="printer" className="wh-35 hover-cursor ms-2"/>
                        </span>
                    </div>
                    
                    <div className="d-flex flex-row">
                        <ListGroup className="my-3 small rounded-0">
                            { modelProps.map((prop) => {
                                return(
                                    <ListGroup.Item key={prop.label} className="d-flex align-items-center justify-content-between py-1">
                                        <span className="medium fg-dark me-5">{prop.label}</span>
                                        <span className="text-end">{sale.id && prop.value(sale)}</span>
                                    </ListGroup.Item>
                                ) })
                            }
                        </ListGroup>
                        <div className="d-flex flex-column flex-fill ms-3 my-2">
                            <span className="fs-5 medium fg-dark">{t('PRODUCTS')}</span>
                            { saleItemsCount ?
                                <TableStd modelProps={modelProps2} data={saleItems}/>
                                : <DataNotFound/>
                            }
                        </div>
                    </div>
                </HomeWrapper>
            </div>
            <PrintReceipt sale={sale} saleItems={saleItems}/>
        </div>
    );
}

export default Sale;