import { useTranslation } from "react-i18next";

const SelectCashier = ({...props}) => {
    const { t } = useTranslation();

    const handleChange = (event) => {
        props.setValue(event.target.value);
    }

    return (
        <div className="d-flex flex-column mt-2">
            <span className="font-14 medium ms-1 me-2 fg-dark">{t('CASHIER')}</span>
            <div className={`d-flex flex-column`}>
                <select
                    value={props.cashierId}
                    onChange={handleChange}
                    className={`rounded-2 border font-14 bg-light mt-1 px-2 py-1`}>
                    <option value={""}>{t('ALL')}</option>
                    { props.items.map((item) => {
                        return (<option value={item.id} key={item.id}>{item.username}</option>)
                    }) }
                </select>
            </div>
        </div>
    );
}

export default SelectCashier;