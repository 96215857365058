import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Actions = ({...props}) => {
    return (
        <div className="d-flex flex-row">
            { props.items.map((item, index) => {
                return (
                    <span className={`mx-1 hover-cursor px-2 py-1 rounded-1 ${item.style}`}
                        onClick={item.onClick} key={index}>
                        {item.icon && <FontAwesomeIcon icon={item.icon}/>}
                        {item.text && <span>{item.name}</span>}
                    </span>
                )
            })}
        </div>
    )
}

export default Actions;