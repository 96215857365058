import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormCheck } from "react-bootstrap";

const RadioBtnU = ({...props}) => {
    const items = props.items;

    const handleSelectedOption = (event) => {
        props.onChangeAttr(event.target.value);
    }

    return (
        <div className="d-flex flex-row">
            { items.map((item) => {
                return(
                    <div key={item.id} className={`rounded-1 mx-1 ${props.attr === item.id ?' bg-blue-500' : 'bg-gray-50'} bg-gradient`}>
                        <label className="d-flex align-items-center justify-content-between px-2 py-1">
                            <FormCheck 
                                id={item.id}
                                label={item.name}
                                value={item.id}
                                name={props.group}
                                type={props.type}
                                className={`bold ${props.attr === item.id ? 'text-white': 'fg-dark'} font-14 me-4`}
                                checked={props.attr === item.id}
                                onChange={handleSelectedOption}/>
                            {item.icon && <FontAwesomeIcon icon={item.icon} className={`${props.attr === item.id ? 'text-white': 'fg-dark'}`}/>}
                        </label>
                    </div>
                )
            })}
        </div>
    );
}

export default RadioBtnU;