import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import TableStd from '../../../../../../../components/ui/TableStd';
import ModalU from "../../../../../../../components/ui/ModalU";
import Edit from './Edit';
import { useTranslation } from "react-i18next";
import Keys from '../../../../../../../utils/constants';

const InvoiceItemsTemp = ({...props}) => {
    const { t } = useTranslation();
    const [modalEdit, setModalEdit] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);

    const [itemToBe, setItemToBe] = useState({});

    const deleteItem = () => {
        const updated = props.items.filter(item => item.productId !== itemToBe.productId)
        props.setItems(updated);
        setModalDelete(false);
    }

    const modelProps = [ 
        {
            name: t('BAR_CODE'),
            value: (prop) => { return(<>{prop.product.barcode}</>) }
        },
        { 
            name: t('PRODUCT'),
            value: (prop) => { return(<>{prop.product.name}</>) } 
        },
        {
            name: t('QUANTITY'),
            value: (prop) => { return(<>{parseFloat(prop.quantity)}</>) }
        },
        {
            name: t('UNIT'),
            value: (prop) => { return(<>{prop.product.unit.shortName}</>) }
        },
        {
            name: t('UNIT_PRICE'),
            value: (prop) => { return(<>{parseFloat(prop.unitPrice).toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: t('SELLING_PRICE'),
            value: (prop) => { return(<>{parseFloat(prop.sellingPrice).toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: t('TOTAL'),
            value: (prop) => { return(<>{(parseFloat(prop.quantity) * parseFloat(prop.unitPrice)).toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: "",
            value: (prop) => {
                return (
                    <div className='d-flex flex-row'>
                        <span className='me-3'
                            onClick={() => {
                                setItemToBe(prop);
                                setModalEdit(true);
                            }}>
                            <FontAwesomeIcon className='fg-blue-500 font-12' icon={faPenToSquare}/>
                        </span>
                        <span className=''
                            onClick={() => {
                                setItemToBe(prop);
                                setModalDelete(true);
                            }}>
                            <FontAwesomeIcon className='fg-red-300 font-12' icon={faTrashCan}/>
                        </span>
                    </div>
                )
            }
        }
    ];

    return (
        <div style={{overflowY: "scroll", minHeight: "100px", maxHeight: "250px"}}>
            <TableStd modelProps={modelProps} data={props.items}/>
            { itemToBe.product &&
            <ModalU show={modalDelete} title={t('WARNING')}
                actionbtntext={t('DELETE')}
                actionbtnclassname={"bg-red-400 font-14 medium text-white"}
                onSubmit={deleteItem} onHide={setModalDelete}>
                <div className='d-flex flex-column align-items-center justify-content-center'>
                    <span className='medium text-danger mb-2'>{t('DATA_DELETION_CONFIRMATION')}</span>
                    <span>{itemToBe.product.name} - {itemToBe.quantity}x {itemToBe.product.unit.name}</span>
                </div>
            </ModalU>
            }
            { itemToBe.product && 
                <Edit
                    modalShow={modalEdit}
                    setModalShow={setModalEdit}
                    item={itemToBe}
                    items={props.items}
                    setItems={props.setItems}
                />
            }
        </div>
    )
}

export default InvoiceItemsTemp;