import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { useNavigate } from "react-router-dom";
import { faSearch, faEllipsisV, faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import { faEye, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../../utils";
import DataWrapper from "../../DataWrapper";
import InputField from "../../../../../../components/form/InputField";
import TableStd from '../../../../../../components/ui/TableStd';
import DropdownU from '../../../../../../components/ui/DropdownU';
import Filter from "./Filter";
import PaginationU from '../../../../../../components/ui/PaginationU';
import LimitU from '../../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../../components/ui/DataNotFound';
import ButtonU from "../../../../../../components/ui/ButtonU";
import ToastU from "../../../../../../components/ui/ToastU";
import SortU from "../../../../../../components/ui/SortU";
import Show from "./Show";
import Empty from "./Empty";
import { useTranslation } from "react-i18next";

const Logs = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready, tempUnlocked } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [logs, setLogs] = useState([]);
    const [logsCount, setLogsCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['createdAt', 'DESC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');
    
    const [modalShow, setModalShow] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);

    const [itemToBe, setItemToBe] = useState({});

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('SETTINGS'), link: '/settings/profile'}, { title: t('DATABASE'), link: '/settings/data/general'}, { title: t('ADMINISTRATION'), link: '/settings/data/administration' }, { title: t('LOGS'), active: true }
    ]

    useEffect(() => {
        if (!tempUnlocked) {
            navigate(-1);
            return;
        }
        else if (ready) {
            getUpdatedUrl();
            getLogs();
        }
    }, [ready, query, toast]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }

    const getLogs = () => {
        setLoading(true);
        axiosPrivate.get('/logs', { params: query }).then((res) => {
            if (res.status == 200) {
                setLogs(res.data.data);
                setLogsCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery({...query, filter: { user: find }});
    }, [find]);

    const refresh = () => { getLogs() }
    
    const modelProps = [
        { 
            name: <code className="fg-dark font-12">{t('USER')}</code>,
            value: (prop) => { return(<code className="fg-dark">{prop.user.username}</code>)} 
        },
        { 
            name: <code className="fg-dark font-12">{t('FULLNAME')}</code>,
            value: (prop) => { return(<code className="fg-dark">{prop.user.fullName}</code>)} 
        },
        {
            name: <code className="fg-dark font-12"><SortU title={t('EVENT')} attr={'eventType'} query={query} setQuery={setQuery}/></code>,
            value: (prop) => { return(<code className="fg-dark">{prop.eventType}</code>) } 
        },
        {
            name: <code className="fg-dark font-12"><SortU title={t('ACTION')} attr={'actionType'} query={query} setQuery={setQuery}/></code>,
            value: (prop) => { return(<code className="fg-dark">{prop.actionType}</code>) } 
        },
        {
            name: <code className="fg-dark font-12"><SortU title={t('IP')} attr={'ipv4'} query={query} setQuery={setQuery}/></code>,
            value: (prop) => { return(<code className="fg-dark">{prop.ipv4}</code>) } 
        },
        {
            name: <code className="fg-dark font-12"><SortU title={t('MAC')} attr={'mac'} query={query} setQuery={setQuery}/></code>,
            value: (prop) => { return(<code className="fg-dark">{prop.mac && prop.mac.toUpperCase()}</code>) } 
        },
        {
            name: <code className="fg-dark font-12"><SortU title={t('CREATED_DATE')} attr={'createdAt'} query={query} setQuery={setQuery}/></code>,
            value: (prop) => { return(<code className="fg-dark">{Utils.getTimestampDateHm(prop.createdAt)}</code>) }
        },
        {
            name: "",
            value: (prop) => {
                const options = [
                    {
                        name: t('SHOW'),
                        icon: faEye,
                        color: "text-dark",
                        onClick: () => {
                            setItemToBe(prop);
                            setModalShow(true);
                        }
                    },
                ]
                return (
                    <DropdownU className='d-flex align-items-center justify-content-center' toggleClass={"card-8-outline py-1 px-1"} icon={faEllipsisV} items={options}/>
                )
            }
        }
    ];

    return (
        <DataWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="bold fs-5 fg-dark">{t('LOGS')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('NAME')}, ${t('LASTNAME')}, ${t('USERNAME')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    <ButtonU onSubmit={() => setModalDelete(true)} className={'border-danger fg-red-300'} icon={faTrashCan} text={t('EMPTY_LOG')}/>
                </div>
            </div>
            { logsCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={logs} count={logsCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={logsCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            <ToastU toast={toast} setToast={setToast}/>
            <Show
                modalShow={modalShow}
                setModalShow={setModalShow}
                item={itemToBe}
            />
            <Empty
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                toast={toast}
                setToast={setToast}
                setLoading={setLoading}
            />
            <div className="mb-5"></div>
        </DataWrapper>
    );
}

export default Logs;