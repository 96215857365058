import { useTranslation } from 'react-i18next';

const Modules = () => {
    const { t } = useTranslation();

    const MODULES = new Map([
        ['1000', t('MAIN')],
        ['1001', t('PRODUCT')],
        ['1002', t('INVOICE')],
        ['1003', t('WAREHOUSE')],
        ['1004', t('COMPANY')],
        ['1005', t('FINANCE')],
        ['1006', t('DATA_GENERAL')],
        ['1007', t('DATA_ADMINISTRATION')],
        ['1008', t('CASHIER_DESK')],
    ]);

    return MODULES;
};

export default Modules;