import { useEffect, useState } from "react";
import InputLabeled from "../../../../../../../components/form/InputLabeled";
import InputAreaLabeled from "../../../../../../../components/form/InputAreaLabeled";
import ModalU from '../../../../../../../components/ui/ModalU';
import { useTranslation } from "react-i18next";

const Edit = ({...props}) => {
    const { t } = useTranslation();
    const productToBeWrittenOff = props.item;

    const [quantity, setQuantity] = useState();
    const [description, setDescription] = useState('');

    useEffect(() => {
        setQuantity(productToBeWrittenOff.quantity);
        setDescription(productToBeWrittenOff.description);
    }, [productToBeWrittenOff]);


    const editTempInvoiceItem = (event) => {
        event.preventDefault();

        if (!quantity || quantity > productToBeWrittenOff.product.quantity) return;

        const updatedItems = props.items.map((item) => {
            if (item.productId == productToBeWrittenOff.productId)
                return {...item, quantity: parseFloat(quantity), description};
            return item;
        });

        props.setItems(updatedItems);

        props.setModalShow(false);
    }

    return (
        <ModalU show={props.modalShow} size={'sm'} title={t('EDIT')}
            actionbtntext={t('SAVE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={editTempInvoiceItem} onHide={props.setModalShow}>
            <form onSubmit={editTempInvoiceItem} className='mx-2'>
                <span>{productToBeWrittenOff.product.name}</span>
                <InputLabeled label={t('QUANTITY')} type={'number'} value={quantity} setValue={setQuantity} hint={"3"} className={'mt-3'} required={true}/>
                <InputAreaLabeled label={t('DEFINITION')} rows={3} value={description} setValue={setDescription} hint={t('DEFINITION')} className={'mt-3'}/>
            </form>
        </ModalU>
    );
}

export default Edit