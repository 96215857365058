import { useEffect } from "react";
import axios from "axios";
import useRefreshToken from './../hooks/useRefreshToken';
import useAuth from "./useAuth";
import Config from "../config";

const baseURL = `${Config.URL_SERVER}/api`;

const instance = axios.create({
    baseURL,
    headers: { Accept: 'application/json'},
    withCredentials: true,
});

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {
        const reqIntercept = instance.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.token}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        )

        const resIntercept = instance.interceptors.response.use(
            (response) => response, (error) => {
                const prevReq = error?.config;
                if (error?.response?.status === 401 && !prevReq?.sent) {
                    prevReq.sent = true;
                    const newAccessToken = refresh();
                    prevReq.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return instance(prevReq);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            instance.interceptors.request.eject(reqIntercept);
            instance.interceptors.response.eject(resIntercept);
        }
    }, [auth]);

    return instance;
}

export default useAxiosPrivate;