import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ButtonU = ({...props}) => {

    return(
        <div className={`m-1 rounded-2 ${props.shadow}`}>
            <button onClick={props.onSubmit} className={`btn ${props.className} mb-2`} disabled={props.disabled}>
                {props.icon && <FontAwesomeIcon className="font-16" icon={props.icon}/>}
                {props.text && <span className="mx-1 medium">{props.text}</span>}
            </button>
        </div>
    );
}

export default ButtonU;