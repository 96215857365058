import AxiosHelper from '../api/AxiosHelper';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth, setReady } = useAuth();

    const refresh = () => {
        AxiosHelper.refresh().then((res) => {
            if (res.status == 200) {
                setAuth(prev => {
                    return {...prev, ...res.data }
                });
                setReady(true);
            }
        }).catch((error) => {
            setAuth(prev => {
                return {...prev, token: 'Unauthorized' }
            });
            setReady(false);
        });
    }

    return refresh;
}

export default useRefreshToken;