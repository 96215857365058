import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/style/appDropdown.css';
import './assets/style/index.css';
import './assets/style/menu-bar.css';
import './assets/style/nav-bar.css';
import './assets/style/side-bar.css';
import './assets/style/wh.css';
import './assets/style/colors.css';
import './assets/style/font.css';
import './assets/style/card.css';
import './assets/style/input.css';
import './assets/style/dropdown.css';
import './assets/style/cp-index.css';
import './assets/style/cp-top-status.css';
import './assets/style/cp-nav-bar.css';
import './assets/style/cp-side-bar.css';
import './assets/style/receipt.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { AuthProvider } from './context/AuthProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </AuthProvider>
  </React.StrictMode>
);  