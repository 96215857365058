import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { Link, useNavigate } from "react-router-dom";
import { faAdd, faSearch, faEllipsisV, faArrowRotateRight, faFileImport, faFileExport, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare, faEye, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../utils";
import ProductsWrapper from "../ProductWrapper";
import InputField from "../../../../../components/form/InputField";
import TableStd from '../../../../../components/ui/TableStd';
import Status from "../components/Status";
import DropdownU from '../../../../../components/ui/DropdownU'
import PaginationU from '../../../../../components/ui/PaginationU';
import LimitU from '../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../components/ui/DataNotFound';
import ButtonU from "../../../../../components/ui/ButtonU";
import ToastU from "../../../../../components/ui/ToastU";
import SortU from "../../../../../components/ui/SortU";
import Filter from "./components/Filter";
import Delete from "./components/Delete";
import Synchronization from "./components/Synchronization";
import ImportFile from "./components/ImportFile";
import ExportFile from "./components/ExportFile";
import { useTranslation } from "react-i18next";
import Keys from "../../../../../utils/constants";

const Products = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [products, setProducts] = useState([]);
    const [productsCount, setProductsCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['name', 'ASC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);
    
    const [find, setfind] = useState('');
    const [modalDelete, setModalDelete] = useState(false);
    const [synchronizationModal, setSynchronizationModal] = useState(false);
    const [importModal, setImportModal] = useState(false);
    const [exportModal, setExportModal] = useState(false);

    const [itemToBe, setItemToBe] = useState({});

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('PRODUCT'), link: '/product/products' } ,
        { title: t('PRODUCTS'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getProducts();
        }
    }, [ready, query, toast]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }
    
    const getProducts = () => {
        setLoading(true);
        axiosPrivate.get('/products', { params: query }).then((res) => {
            if (res.status == 200) {
                setProducts(res.data.data);
                setProductsCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery({...query, filter: { name: find }});
    }, [find]);

    const createNew = () => { navigate('/product/products/create'); }

    const refresh = () => { getProducts() }
    
    const modelProps = [ 
        {
            name: <SortU title={t('BAR_CODE')} attr={'barcode'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.barcode}</>) }
        },
        { 
            name: <SortU title={t('NAME')} attr={'name'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Link to={`/product/products/${prop.id}`} className='no-decor'>{prop.name}</Link>) } 
        },
        {
            name: <SortU title={t('UNIT_PRICE')} attr={'unitPrice'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.unitPrice?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: <SortU title={t('SELLING_PRICE')} attr={'sellingPrice'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.sellingPrice?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: <SortU title={t('IN_STOCK')} attr={'quantity'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.quantity?.toFixed(2)} {prop?.unit?.name}</>) }
        },
        {
            name: t('CATEGORY'),
            value: (prop) => { return(<>{prop?.category ? prop?.category?.name : '-'}</>) }
        },
        {
            name: <SortU title={t('STATUS')} attr={'active'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Status status={prop.active} size={'font-10'}/>) }
        },
        {
            name: <SortU title={t('UPDATED_AT')} attr={'updatedAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.updatedAt)}</>) }
        },
        {
            name: "",
            value: (prop) => {
                const options = [
                    {
                        name: t('SHOW'),
                        icon: faEye,
                        color: "text-dark",
                        onClick: () => { navigate(`/product/products/${prop.id}`); }
                    },
                    {
                        name: t('EDIT'),
                        icon: faPenToSquare,
                        color: "text-dark",
                        onClick: () => { navigate(`/product/products/update/${prop.id}`); }
                    },
                    // {
                    //     name: t('DELETE'),
                    //     icon: faTrashCan,
                    //     color: "text-danger",
                    //     onClick: () => { 
                    //         setItemToBe(prop);
                    //         setModalDelete(true);
                    //     }
                    // }
                ]
                return (
                    <DropdownU className='d-flex align-items-center justify-content-center' toggleClass={"card-8-outline py-1 px-1"} icon={faEllipsisV} items={options}/>
                )
            }
        }
    ];

    return (
        <ProductsWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('PRODUCTS')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={refresh} fg={''} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('PRODUCT')} ${t('NAME')}, ${t('BAR_CODE')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    <ButtonU onSubmit={() => setSynchronizationModal(true)} icon={faArrowsRotate} text={t('SYNCHRONIZATION')} className={"bg-blue-500 bg-gradient text-white"}/>
                    <ButtonU onSubmit={() => setImportModal(true)} icon={faFileImport} text={t('IMPORT')} className={"bg-pink-700 bg-gradient text-white"}/>
                    <ButtonU onSubmit={() => setExportModal(true)} icon={faFileExport} text={t('EXPORT')} className={"bg-orange-400 bg-gradient text-white"}/>
                    <ButtonU onSubmit={createNew} className={'bg-green-700 bg-gradient text-white'} icon={faAdd} text={t('ADD')}/>
                </div>
            </div>
            { productsCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={products} count={productsCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={productsCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            <Delete
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
                setLoading={setLoading}
            />
            <Synchronization
                modalShow={synchronizationModal}
                setModalShow={setSynchronizationModal}
                productsCount={productsCount}
                toast={toast}
                setToast={setToast}
            />
            <ImportFile
                modalShow={importModal}
                setModalShow={setImportModal}
                toast={toast}
                setToast={setToast}
            />
            <ExportFile
                modalShow={exportModal}
                setModalShow={setExportModal}
                productsCount={productsCount}
                toast={toast}
                setToast={setToast}
            />
            <ToastU toast={toast} setToast={setToast}/>
        </ProductsWrapper>
    );
}

export default Products;