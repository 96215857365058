export default class Config {
    static CO_NAME = "Älem Tilsimat";
    static APP_NAME = "Alem Kassa";
    static COMPANY_CONTACT_ID = "116a6b2f-7268-49ec-ad93-abd9ca05e3e6";
    static VERSION = "2.0.4";
    
    static IS_DEV = false;
    static URL_SERVER = this.IS_DEV ? 'http://127.0.0.1:4000' : 'https://alemkassa.alemtilsimat.com';
    static PATH_PREFIX = this.IS_DEV ? "/static" : "/api/static";
    static PATH_PRODUCT_IMAGES = "/images/products/";
    static PATH_BACKUP_SQL = "/backup/";

    static SYNCH_TARGET_SERVER_URL = "https://ecommerce.alemtilsimat.com"

}