import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import useAuth from "../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { Link, useNavigate } from "react-router-dom";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../../utils";
import Keys from "../../../../../../utils/constants";
import FinanceWrapper from "../../FinanceWrapper";
import TableStd from '../../../../../../components/ui/TableStd';
import DataNotFound from '../../../../../../components/ui/DataNotFound';
import ButtonU from "../../../../../../components/ui/ButtonU";
import Status from "../../../../../../components/ui/Status";
import PaymentTypes from "../../../../../../components/ui/PaymentTypes";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShowSaleItem from "./../../components/ShowSaleItem";

const Sale = () => {
    const { t } = useTranslation();
    const params = useParams();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const PAYMENT_TYPES = PaymentTypes();

    const [sale, setSale] = useState({});

    const [modalShowItem, setModalShowItem] = useState(false);
    
    const [itemToBe, setItemToBe] = useState({});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('FINANCE'), link: '/finance/sales' },
        { title: t('SALES'), link: '/finance/sales' },
        { title: sale.number, active: true}
    ]

    useEffect(() => {
        if (ready) {
            getSale();
        }
    }, [ready]);

    const getSale = () => {
        setLoading(true);
        axiosPrivate.get(`/sales/${params.id}`).then((res) => {
            if (res.status == 200) {
                setSale(res.data.data);
            }
        }).finally(() => {setLoading(false)});
    }

    const modelProps = [ 
        { 
            name: t('BARCODE'),
            value: (prop) => { return(<>{prop?.product?.barcode}</>) } 
        },
        { 
            name: t('PRODUCT'),
            value: (prop) => { return(<Link to={`/product/products/${prop?.product?.id}`} className='no-decor'>{prop?.product?.name}</Link>) } 
        },
        {
            name: t('QUANTITY'),
            value: (prop) => { return(<>{prop?.quantity?.toFixed(2)} {prop?.product?.unit?.shortName}</>) }
        },
        {
            name: t('UNIT_PRICE'),
            value: (prop) => { return(<>{prop?.unitPrice?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: t('SELLING_PRICE'),
            value: (prop) => { return(<>{prop?.sellingPrice?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: t('TOTAL'),
            value: (prop) => { return(<>{prop?.total?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: t('DISCOUNT'),
            value: (prop) => { return(<span className="fg-orange-300">{prop?.discount?.toFixed(2)} {Keys.TMT}</span>) }
        },
        {
            name: t('REVENUE'),
            value: (prop) => { return(<span className="fg-green-500">{prop?.revenue?.toFixed(2)} {Keys.TMT}</span>) }
        },
        {
            name: t('RETURNED'),
            value: (prop) => { return(<>{prop.returned.toFixed(2)} {prop?.product?.unit?.shortName}</>) }
        },
        {
            name: t('UPDATED_AT'),
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop?.updatedAt)}</>) }
        },
        {
            name: "",
            value: (prop) => {
                return (
                    <div className="d-flex flex-row">
                        <FontAwesomeIcon icon={faEye} className="rounded-2 p-1 bg-gray-50 hover-cursor"
                            onClick={() => {
                                setItemToBe(prop);
                                setModalShowItem(true);
                            }}
                        />
                    </div>
                )
            }
        }
    ];

    return (
        <FinanceWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{`${t('SALE_NO')}: ${sale?.number}`}</span>
                <div className="d-flex align-items-center">
                </div>
            </div>
            <div className="mt-5"></div>
            {sale.id && <div className="d-flex flex-column border border-gray m-3 p-3">
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex flex-column">
                        <span className="font-14 text-secondary mb-2">{t('CASHIER')}: <span className="fg-dark medium">{sale?.cashier?.fullName}</span></span>
                        <div className="d-flex flex-row">
                            <div className="d-flex flex-column font-12">
                                <span className="text-secondary">{t('PAID')}</span>
                                <span className="fg-dark medium">{sale?.paid?.toFixed(2)} {Keys.TMT}</span>
                            </div>
                            <div className="d-flex flex-column font-12 ms-4">
                                <span className="text-secondary">{t('YOUR_CHANGE')}</span>
                                <span className="fg-dark medium">{sale?.change?.toFixed(2)} {Keys.TMT}</span>
                            </div>
                            <div className="d-flex flex-column font-12 ms-4">
                                <span className="text-secondary">{t('DISCOUNT')}</span>
                                <span className="fg-orange-300 medium">{sale?.discount?.toFixed(2)} {Keys.TMT}</span>
                            </div>
                            <div className="d-flex flex-column font-12 ms-4">
                                <span className="text-secondary">{t('REVENUE')}</span>
                                <span className="fg-green-500 medium">{sale?.revenue?.toFixed(2)} {Keys.TMT}</span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                        <span className="fs-1 medium fg-red-300">{t('RECEIPT').toUpperCase()}</span>
                        <span className="font-12 text-secondary">{t('PAYMENT_TYPE')}: <span className="fg-dark medium">{PAYMENT_TYPES.get(sale?.paymentType)}</span></span>
                        <span className="font-12 text-secondary">{t('NUMBER')}: <span className="fg-dark medium">#{sale?.number}</span></span>
                        <span className="font-12 text-secondary">{t('DATE')}: <span className="fg-dark medium">{Utils.getTimestampDate(sale?.createdAt)}</span></span>
                    </div>
                </div>
                <hr className="my-2"/>
                { sale?.saleItems?.length > 0 ?
                    <div style={{overflowY: "scroll", height: "250px"}}>
                        <TableStd modelProps={modelProps} data={sale?.saleItems}/>
                    </div>
                    : <DataNotFound/>
                }
                <span className="font-14 medium fg-dark">{t('TOTAL')} {t('ITEM')}: {sale?.saleItems?.length}</span>
                <hr  className="my-2"/>
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex flex-column">
                        <span className="font-12 medium fg-gray">{t('NOTE')}</span>
                        <span className="font-12 fg-dark">{sale.note ? sale.note : '-'}</span>
                    </div>
                    <div className="d-flex flex-column">
                        <span className="font-14 medium fg-dark">{t('TOTAL')}</span>
                        <span className="font-18 fg-dark bold">{sale?.total?.toFixed(2)} {Keys.TMT}</span>
                        <span className="font-12 text-secondary mt-1">{t('STATUS')}: <Status  status={sale.closed}/></span>
                    </div>
                </div>
            </div>}
            <ShowSaleItem
                modalShow={modalShowItem}
                setModalShow={setModalShowItem}
                item={itemToBe}
            />
        </FinanceWrapper>
    );
}

export default Sale;