import { ListGroup } from "react-bootstrap";
import ModalU from '../../../../../components/ui/ModalU';
import Utils from "../../../../../utils";
import { useTranslation } from "react-i18next";
import Keys from "../../../../../utils/constants";

const ShowReturnedSaleItem = ({...props}) => {
    const { t } = useTranslation();
    const item = props.item;
    
    const modelProps = [
        { label: t('REGISTRATION_DATE'), value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.createdAt)}</>)} },
        { label: t('UPDATED_AT'), value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.updatedAt)}</>)} },
        { label: t('PRODUCT'), value: (prop) => { return(<>{prop?.saleItem?.product.name}</>)} },
        { label: t('CATEGORY'), value: (prop) => { return(<>{prop?.saleItem?.product?.category?.name}</>)} },
        { label: t('QUANTITY'), value: (prop) => { return(<>{ prop.quantity.toFixed(2)} {prop?.product?.unit?.shortName}</>)} },
        { label: t('UNIT'), value: (prop) => { return(<>{prop?.saleItem?.product?.unit?.name}</>)} },
        { label: t('UNIT_PRICE'), value: (prop) => { return(<>{prop?.saleItem?.unitPrice.toFixed(2)} {Keys.TMT}</>)} },
        { label: t('SELLING_PRICE'), value: (prop) => { return(<>{prop?.saleItem?.sellingPrice.toFixed(2)} {Keys.TMT}</>)} },
        { label: t('DISCOUNT'), value: (prop) => { return(<span className="fg-orange-300">{prop?.saleItem?.discount.toFixed(2)} {Keys.TMT}</span>)} },
        { label: t('TOTAL'), value: (prop) => { return(<>{prop.total.toFixed(2)} {Keys.TMT}</>)} },
        { label: t('REVENUE'), value: (prop) => { return(<span className="fg-purple-300">{prop.revenue.toFixed(2)} {Keys.TMT}</span>)} },
    ];

    return (
        <ModalU show={props.modalShow} size={''} title={t('PRODUCT')} onHide={props.setModalShow}>
            <ListGroup className="my-3 small rounded-0">
                { modelProps.map((prop) => {
                    return(
                        <ListGroup.Item key={prop.label} className="d-flex align-items-center justify-content-between py-1">
                            <span className="medium fg-dark">{prop.label}</span>
                            {item.id && prop.value(item)}
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </ModalU>
    );
}

export default ShowReturnedSaleItem