import { useState } from 'react';
import useAuth from "../../../../../../hooks/useAuth";
import AdminVerification from "../../../../../../components/ui/AdminVerification";
import ModalU from '../../../../../../components/ui/ModalU';
import Keys from "../../../../../../utils/constants";
import ToastU from "../../../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";

const ClearItems = ({...props}) => {
    const { t } = useTranslation();
   
    const { setTempUnlocked } = useAuth();
    const [adminVerificationModal, setAdminVerificationModal] = useState(false);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const handleSubmit = (event) => {
        event.preventDefault();
        setAdminVerificationModal(true);
    }

    const setOnSuccess = () => {
        setTempUnlocked(false);
        props.setItems([]);
        props.setModalShow(false);
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('WARNING')}
            actionbtntext={t('DELETE')}
            actionbtnclassname={"bg-red-400 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <div className="d-flex flex-column align-items-center">
                <span className="medium fg-red-300 font-18">{t('DATA_DELETION_CONFIRMATION')}</span>
                <div className="d-flex flex-column align-items-center my-2">
                    <span className="medium fg-dark mb-2">{t('SALE')} {t('PRODUCTS')}</span>
                    {props.items.map((item, index) => {
                        return (<span key={index} className="font-14 fg-dark me-1">{index+1}. {item.product && item.product.name}</span>)
                    })}
                </div>
            </div>
            <ToastU toast={toast} setToast={setToast}/>
            <AdminVerification
                show={adminVerificationModal}
                onHide={setAdminVerificationModal}
                appName={Keys.CP}
                setOnSuccess={setOnSuccess}
                toast={toast}
                setToast={setToast}
            />
        </ModalU>
    );
}

export default ClearItems