import { useState, useEffect } from "react";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { useNavigate } from "react-router-dom";
import { faCancel, faCube, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../../utils";
import WarehouseWrapper from "../../WarehouseWrapper";
import ButtonU from "../../../../../../components/ui/ButtonU";
import InputField from "../../../../../../components/form/InputField";
import ProductsToBeWrittenOff from "./components/ProductsToBeWrittenOff";
import ToastU from "../../../../../../components/ui/ToastU";
import Cancel from "../../../../../../components/ui/Cancel";
import { useTranslation } from "react-i18next";

const Create = () => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [barcode, setBarcode] = useState('');

    const [productsToBeWrittenOff, setProductsToBeWrittenOff] = useState([]);
    
    const [modalCancel, setModalCancel] = useState(false);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('WAREHOUSE'), link: '/warehouse/warehouses' } ,
        { title: t('WRITTEN_OFF_PRODUCTS'), link: '/warehouse/written-off-products'},
        { title: t('NEW'), active: true}
    ]

    const handleFindProduct = (event) => {
        event.preventDefault();

        if (checkExistence(barcode)) return;

        setLoading(true);
        const id = undefined;
        axiosPrivate.get('/products/'+id+'/'+barcode).then((res) => {
            if (res.status == 200) {
                addProduct(res.data.data);
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {
            setBarcode('');
            setLoading(false);
        });
    }

    const checkExistence = (barcode) => {
        return productsToBeWrittenOff.some((item) => {
            return item.product.barcode == barcode;
        });
    }

    const addProduct = (product) => {
        const productToBeWrittenOff = { productId: product.id, product, quantity: 0, description: '' }
        setProductsToBeWrittenOff([...productsToBeWrittenOff, productToBeWrittenOff]);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const requiredInputs = [
            { key: !(productsToBeWrittenOff.length < 1), value: `${t('DATA_NOT_ENTERED_YET')} (${t('PRODUCTS')})` },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        setLoading(true);
        const data = { productsToBeWrittenOff };

        axiosPrivate.post('/written-off-products/create-bulk', data).then((res) => {
            if (res.status == 200) {
                setToast({ content: res.data.msg, variant: 'success', title: t('SUCCESS'), show: true });
                navigate(-1);
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => setLoading(false));
    }

    return (
        <WarehouseWrapper loading={loading} breadcrumbs={breadcrumbs}>
             <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('WRITE_OFF_PRODUCT')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={() => {setModalCancel(true)}} icon={faCancel} text={t('CANCEL')} className={"bg-red-400 bg-gradient text-white"}/>
                    <ButtonU onSubmit={handleSubmit} icon={faSave} text={t('SAVE')} className={"bg-green-700 bg-gradient text-white"}/>
                </div>
            </div>

            <div className="d-flex flex-column my-2 py-2 mt-5">
                <form onSubmit={handleFindProduct} className="d-flex flex-row mt-2 mb-3 mx-3">
                    <InputField type={"number"} value={barcode} setValue={setBarcode} hint={t('BARCODE')} icon={faCube} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <ButtonU onSubmit={handleFindProduct} icon={faSearch} className={"bg-blue-700 bg-gradient text-white"}/>
                </form>
                {productsToBeWrittenOff.length > 0 && <ProductsToBeWrittenOff items={productsToBeWrittenOff} setItems={setProductsToBeWrittenOff}/>}
            </div>
            <Cancel modalShow={modalCancel} setModalShow={setModalCancel}/>
            <ToastU toast={toast} setToast={setToast}/>
        </WarehouseWrapper>
    );
}

export default Create;