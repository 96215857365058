import { useEffect, useState } from "react";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../components/ui/ModalU';
import Utils from "../../../../../utils";
import InputLabeled from '../../../../../components/form/InputLabeled';
import { useTranslation } from "react-i18next";

const Update = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberWork, setPhoneNumberWork] = useState('');
    const [fax, setFax] = useState('');
    const [addressLine, setAddressLine] = useState('');
    const [website, setWebsite] = useState('');

    useEffect(() => {
        setFirstName(item.firstName);
        setLastName(item.lastName);
        setCompany(item.company);
        setEmail(item.email);
        setPhoneNumber(item.phoneNumber);
        setPhoneNumberWork(item.phoneNumberWork);
        setFax(item.fax);
        setAddressLine(item.addressLine);
        setWebsite(item.website);
    }, [item])

    const handleSubmit = (event) => {
        event.preventDefault();

        const requiredInputs = [
            { key: firstName, value: t('NAME_REQUIRED') },
            { key: phoneNumber, value: t('PHONE_NUMBER_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { firstName, lastName, company, email, phoneNumber, phoneNumberWork, fax, website, addressLine };
        axiosPrivate.put('/contacts/update/'+item.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={'lg'} title={t('CONTACT')}
            actionbtntext={t('UPDATE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <div className="d-flex row">
                    <div className="col-md-4">
                        <InputLabeled label={t('FIRSTNAME')} type={'text'} value={firstName} setValue={setFirstName} hint={t('NAME')} required={true}/>
                    </div>
                    <div className="col-md-4">
                        <InputLabeled label={t('LASTNAME')} type={'text'} value={lastName} setValue={setLastName} hint={t('LASTNAME')}/>
                    </div>
                    <div className="col-md-4">
                        <InputLabeled label={t('COMPANY_NAME')} type={'text'} value={company} setValue={setCompany} hint={t('HINT_COMPANY')}/>
                    </div>
                </div>
                <div className="d-flex row">
                    <div className="col-md-4">
                        <InputLabeled label={t('PHONE_NUMBER')} type={'number'} value={phoneNumber} setValue={setPhoneNumber} hint={t('HINT_PHONE_NUMBER')} required={true}/>
                    </div>
                    <div className="col-md-4">
                        <InputLabeled label={`${t('PHONE_NUMBER')} ${t('WORK')}`} type={'number'} value={phoneNumberWork} setValue={setPhoneNumberWork} hint={t('HINT_PHONE_NUMBER')}/>
                    </div>
                    <div className="col-md-4">
                        <InputLabeled label={t('FAX')} type={'number'} value={fax} setValue={setFax} hint={t('HINT_PHONE_NUMBER_WORK')}/>
                    </div>
                </div>
                <div className="d-flex row">
                    <div className="col-md-4">
                        <InputLabeled label={t('EMAIL')} type={'text'} value={email} setValue={setEmail} hint={t('HINT_EMAIL')}/>
                    </div>
                    <div className="col-md-4">
                        <InputLabeled label={t('WEBSITE')} type={'text'} value={website} setValue={setWebsite} hint={t('HINT_WEBSITE')}/>
                    </div>
                    <div className="col-md-4">
                        <InputLabeled label={t('ADDRESS')} type={'text'} value={addressLine} setValue={setAddressLine} hint={t('HINT_ADDRESS')}/>
                    </div>
                </div>
            </form>
        </ModalU>
    );
}

export default Update