import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { Link, useNavigate } from "react-router-dom";
import { faSearch, faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../utils";
import Keys from "../../../../../utils/constants";
import FinanceWrapper from "../FinanceWrapper";
import InputField from "../../../../../components/form/InputField";
import TableStd from '../../../../../components/ui/TableStd';
import PaginationU from '../../../../../components/ui/PaginationU';
import LimitU from '../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../components/ui/DataNotFound';
import SortU from '../../../../../components/ui/SortU';
import ButtonU from "../../../../../components/ui/ButtonU";
import Filter from "./Filter";
import Status from "../../../../../components/ui/Status";
import PaymentTypes from "../../../../../components/ui/PaymentTypes";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Sales = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const PAYMENT_TYPES = PaymentTypes();

    const [sales, setSales] = useState([]);
    const [salesCount, setSalesCount] = useState();
    const [cashiers, setCashiers] = useState([]);

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['updatedAt', 'DESC']],
        filter: { createdAtLb: '', createdAtUb: '', cashierId: '' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('FINANCE'), link: '/finance/sales' } ,
        { title: t('SALES'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getCashiers();
        }
    }, [ready]);

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getSales();
        }
    }, [ready, query]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }

    
    const getCashiers = () => {
        setLoading(true);
        // const cashierQuery = {filter: { role: Keys.CASHIER}}
        axiosPrivate.get('/users').then((res) => {
            if (res.status == 200) {
                setCashiers(res.data.data);
            }
        }).finally(() => {setLoading(false)});
    }

    
    const getSales = () => {
        setLoading(true);
        axiosPrivate.get('/sales', { params: query }).then((res) => {
            if (res.status == 200) {
                setSales(res.data.data);
                setSalesCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery(prev => {
            return { ...prev,
                filter: { ...prev.filter,
                    number: find,
                }
            }
        })
    }, [find]);


    const refresh = () => { getSales() }
    
    const modelProps = [ 
        { 
            name: <SortU title={t('NUMBER')} attr={'number'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Link to={`/finance/sales/${prop.id}`} className='no-decor'>{prop?.number}</Link>) } 
        },
        {
            name: <SortU title={t('PAYMENT_TYPE')} attr={'paymentType'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{PAYMENT_TYPES.get(prop?.paymentType)}</>) }
        },
        {
            name: <SortU title={t('PAID')} attr={'paid'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{ prop?.paid?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: <SortU title={t('YOUR_CHANGE')} attr={'change'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.change?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: <SortU title={t('TOTAL')} attr={'total'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.total?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: <SortU title={t('DISCOUNT')} attr={'discount'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<span className="fg-orange-300">{prop?.discount?.toFixed(2)} {Keys.TMT}</span>) }
        },
        {
            name: <SortU title={t('REVENUE')} attr={'revenue'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<span className="fg-green-500">{prop?.revenue?.toFixed(2)} {Keys.TMT}</span>) }
        },
        {
            name: <SortU title={t('STATUS')} attr={'closed'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Status status={prop.closed}/>) }
        },
        {
            name: t('CASHIER'),
            value: (prop) => { return(<>{prop?.cashier?.username}</>) }
        },
        {
            name: <SortU title={t('NOTE')} attr={'note'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.note}</>) }
        },
        {
            name: <SortU title={t('UPDATED_AT')} attr={'updatedAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop?.updatedAt)}</>) }
        },
        {
            name: "",
            value: (prop) => {
                return (
                    <div className="d-flex flex-row">
                        <FontAwesomeIcon icon={faEye} className="rounded-2 p-1 bg-gray-50 hover-cursor"
                            onClick={() => {
                                navigate(`/finance/sales/${prop.id}`)
                            }}
                        />
                    </div>
                )
            }
        }
    ];

    return (
        <FinanceWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('SALES')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('SALE')} ${t('NUMBER')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} cashiers={cashiers}/>
                </div>
            </div>
            { salesCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={sales} count={salesCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={salesCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
        </FinanceWrapper>
    );
}

export default Sales;