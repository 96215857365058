import { Link, useLocation } from "react-router-dom"
import icToggleLeftPanel from "./../../../assets/svg/ic_toggle_panel_left.svg"
import Utils from './../../../utils';

const SideBar = ({...props}) => {
    const location = useLocation();

    const isActive = (links) => {
        let flag = links.some((link) => {
            const baseLink = link.split('/')[3];
            const path = location.pathname.split('/')[3];
            return (baseLink === path);
        });

        return flag;
    }

    return (
        <div className="side-bar side-bar-settings" id="side-bar">
            <div className="d-flex justify-content-between mb-2">
                <span></span>
                <img src={icToggleLeftPanel} alt="Toggle Left panel"
                    className="wh-30 hover-cursor border rounded-2 p-1"
                    onClick={Utils.toggleSidebar}
                />
            </div>
            { props.items.map((item) => {
                return(
                    <Link to={item.links[0]} className={"side-item"} key={item.id}>
                        <div className={"side-link " + (!isActive(item.links) && 'collapsed')} data-bs-target={`#${item.id}`} data-bs-toggle="collapse">
                            <img src={item.icon} className="wh-15 me-2"/>
                            <span className="side-item-name">{item.name}</span>
                        </div>
                    </Link>
                )
            }) }
        </div>
    );
}

export default SideBar;