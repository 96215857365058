import { useState, useEffect } from "react";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router';
import HomeWrapper from '../../pages/Main/HomeWrapper';
import icNewSell from '../../../../assets/svg/ic_new_sell.svg';
import icReturn from '../../../../assets/svg/ic_return.svg';
import icProduct from '../../../../assets/svg/ic_cube.svg';
import ToastU from "../../../../components/ui/ToastU";
import FindProduct from "../../components/ui/FindProduct";
import { useTranslation } from "react-i18next";

const Main = () => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const [modalFindProduct, setModalFindProduct] = useState(false);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        const handleKeyDown = (event) => {
            switch(event.key) {
                case 'F2': newSale(); break;
                case 'F8': newReturn(); break;
                case 'F4': setModalFindProduct(true); break;
                default: break;
            }
        }

        window.addEventListener('keydown', handleKeyDown);
        return () => { window.removeEventListener('keydown', handleKeyDown) };
    }, []);

    const newSale = () => {
        axiosPrivate.post('/sales/create').then((res) => {
            if (res.status == 200) {
                const sale = res.data.data;
                navigate(`/cp/sales/open/${sale.id}`);
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        });
    }

    const newReturn = () => {
        console.log('123')
        navigate('/cp/new-sale-item-return');
    }

    return (
        <HomeWrapper>
            <div className='row'>
                <div className='col-md-4'>
                    <div onClick={newSale} className='btn-card-8-col bg-orange-400'>
                        <span className='fs-5 bold'>{t('NEW_SALE')} [F2]</span>
                        <img src={icNewSell} alt={t('CREATE')} className='wh-30 mt-1' />
                    </div>
                </div>
                <div className='col-md-2'>
                    <div onClick={newReturn} className='btn-card-8-col bg-purple-400'>
                        <span className='fs-5 bold'>{t('RETURN')} [F8]</span>
                        <img src={icReturn} className='wh-30 mt-1' />
                    </div>
                </div>
                <div className='col-md-2'>
                    <div onClick={() => {setModalFindProduct(true);}} className='btn-card-8-col bg-yellow-400'>
                        <span className='fs-5 bold'>{t('PRODUCT')} {t('FIND')} [F4]</span>
                        <img src={icProduct} alt={t('FIND')} className='wh-30 mt-1' />
                    </div>
                </div>
                <div className='col-md-2'>
                    <div className='btn-card-8-col bg-gray'>
                        <span className='fs-5 bold'>{"Option 4"}</span>
                    </div>
                </div>
                <div className='col-md-2'>
                    <div className='btn-card-8-col bg-gray'>
                        <span className='fs-5 bold'>{"Option 5"}</span>
                    </div>
                </div>
                <div className='col-md-2'>
                    <div className='btn-card-8-col bg-gray'>
                        <span className='fs-5 bold'>{"Option 6"}</span>
                    </div>
                </div>
            </div>
            <FindProduct
                modalShow={modalFindProduct}
                setModalShow={setModalFindProduct}
            />
            <ToastU toast={toast} setToast={setToast}/>
        </HomeWrapper>
    );
}

export default Main;