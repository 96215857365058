import { useTranslation } from "react-i18next";

const MostSold = ({...props}) => {
    const { t } = useTranslation();
   
    const mostSold = props.mostSold;

    return (
        <div className="d-flex flex-column bg-yellow-100 rounded-1 ms-2 p-1 flex-fill">
            <span className="bg-yellow-200 medium rounded-1 border fg-dark py-1 px-2">{t('MOST_SOLD')}</span>
            <div className="d-flex flex-column table-scroll bg-white mt-2 0 rounded-1" style={{height: "360px"}}>
                { mostSold.map((item, index) => {
                    return(
                        <div key={index} className="d-flex flex-column mb-1">
                            <span onClick={() => props.addSaleItem(item.barcode)}
                                className="card-default p-1 fg-dark font-12">
                                {item.name}
                            </span>
                            <hr className="my-0 mx-1"/>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default MostSold;