import { useEffect, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import Utils from "../../../../utils";
import MainWrapper from "../../layouts/MainWrapper";
import SideBar from "../../../../layouts/SideBar";
import { useTranslation } from "react-i18next";
import Keys from "../../../../utils/constants";
import icChartColumn from "./../../../../assets/svg/ic_chart_column.svg";
import icCartShopping from "./../../../../assets/svg/ic_cart_shopping.svg";
import icBasketShopping from "./../../../../assets/svg/ic_basket_shopping.svg";
import icCartMinus from "./../../../../assets/svg/ic_cart_minus.svg";

const FinanceWrapper = (props) => {
    const { t } = useTranslation();
    const { ready, auth } = useAuth();
    const [allowed, setAllowed] = useState(false);

    useEffect(() => {
        if (ready) setAllowed(Utils.allowedRole(auth, '1005', 'read'));
    }, [ready, allowed]);

    const items = [
        { name: t('REPORTS'), id: "reports", icon: icChartColumn, links: Keys.FINANCE_REPORTS_LINKS},
        { name: t('SALES'), id: "sales", icon: icCartShopping, links: Keys.FINANCE_SALES_LINKS},
        { name: t('SALE_ITEMS'), id: "sales-items", icon: icBasketShopping, links: Keys.FINANCE_SALE_ITEMS_LINKS},
        { name: `${t('RETURNED')} ${t('SALES')}`, id: "returned-sales", icon: icCartMinus, links: Keys.FINANCE_RETURNED_SALES_LINKS},
    ];

    return (
        <MainWrapper loading={props.loading} breadcrumbs={props.breadcrumbs}>
            {allowed && <div className="main-page">
                <SideBar items={items}/>
                <div className="body-page">
                    { props.children }
                </div>
            </div>}
        </MainWrapper>
    );
};

export default FinanceWrapper;