import { useState, useEffect } from "react";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faGlobeAsia } from "@fortawesome/free-solid-svg-icons";
import ModalU from '../../../../../../components/ui/ModalU';
import ToastU from "../../../../../../components/ui/ToastU";
import LinearIndeterminant from "../../../../../../components/ui/PbLinearIndeterminant";
import Utils from "../../../../../../utils";
import { useTranslation } from "react-i18next";
import Config from "../../../../../../config";

const Synchronization = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const synchronize = () => {
        setLoading(true);
        axiosPrivate.put('/synchronize/products').then((res) => {
            if (res.status == 200) {
                props.setToast({ content: t('SUCCESS'), variant: 'primary', title: t('DONE'), show: true });
                props.setModalShow(false);
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => { setLoading(false) });
    }

    const handleSynchronization = () => {
        synchronize();
    }

    return (
        <ModalU show={props.modalShow}
            title={<span className="fg-blue-500"><FontAwesomeIcon icon={faArrowsRotate} className="me-2"/>{t('SYNCHRONIZE')}</span>}
            actionbtntext={t('CONFIRM')}
            actionbtnclassname={"bg-blue-700 font-14 medium text-white"}
            onSubmit={handleSynchronization} onHide={props.setModalShow}>
                <div className="d-flex flex-column p-3">
                    <span className="font-14 fg-blue-300"><FontAwesomeIcon icon={faGlobeAsia} className="me-2"/>{Config.SYNCH_TARGET_SERVER_URL}</span>
                    <div className="d-flex flex-row mt-2 fg-dark">
                        <span className="me-2">{t('TOTAL')} {t('PRODUCTS')}:</span>
                        <span className="bold">{props.productsCount}</span>
                    </div>
                    {loading && 
                        <div className="mt-2">
                            <LinearIndeterminant color={'secondary'}/>
                        </div>
                    }
                </div>
            <ToastU toast={toast} setToast={setToast}/>
        </ModalU>
    );
}

export default Synchronization