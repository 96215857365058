import { useEffect, useState } from "react";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../../components/ui/ModalU';
import Utils from "../../../../../../utils";
import InputLabeled from '../../../../../../components/form/InputLabeled';
import { useTranslation } from "react-i18next";
import FoundCategories from "./components/FoundCategories";

const Update = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [name, setName] = useState('');
    const [parentId, setParentId] = useState('');

    const [parentCategoryName, setParentCategoryName] = useState('');

    const [parentCategory, setParentCategory] = useState({});

    const [categories, setCategories] = useState([]);

    const [query, setQuery] = useState({
        limit:0, order: [['createdAt', 'ASC']], filter: { name: '' }
    });

    useEffect(() => {
        getCategories();
    }, [query]);
    
    const handleInputOnFocus = () => {
        setQuery({...query, limit: 20});
    };

    const handleInputOnBlur = () => {
        setCategories([]);
    };

    useEffect(() => {
        if (parentCategoryName?.length < 1) {
            setCategories([]);
            return;
        }
        if (parentCategoryName && parentCategory?.name != parentCategoryName)
            setQuery({...query, limit: 50, filter: { name: parentCategoryName }});
    }, [parentCategoryName]);

    useEffect(() => {
        if (!parentCategory?.id) return;
        setParentCategoryName(parentCategory?.name);
        setParentId(parentCategory?.id);
        setCategories([]);
    }, [parentCategory]);

    const getCategories = () => {
        axiosPrivate.get('/categories', { params: query }).then((res) => {
            if (res.status == 200) setCategories(res.data.data);
        });
    }

    useEffect(() => {
        console.log(item)
        setName(item.name);
        setParentId(item.parentId ? item.parentId : '');

        setParentCategory(item?.parentCategory)
        setParentCategoryName(item?.parentCategory?.name);
    }, [item])

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: name, value: t('NAME_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { name, parentId };
        axiosPrivate.put('/categories/update/'+item.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('CATEGORY')}
            actionbtntext={t('UPDATE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <InputLabeled label={t('NAME')} type={'text'} value={name} setValue={setName} hint={t('NAME')} required={true}/>
                <div className="">
                    <InputLabeled label={t('MOTHER')} type={'text'} value={parentCategoryName} setValue={setParentCategoryName} hint={`${t('CATEGORY')} ${t('FIND')}`} onFocus={handleInputOnFocus} onBlur={handleInputOnBlur} required={true}/>
                    <FoundCategories items={categories} setItems={setCategories} setItem={setParentCategory}/>
                </div>
            </form>
        </ModalU>
    );
}

export default Update