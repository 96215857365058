import { useNavigate } from "react-router-dom"
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../components/ui/ModalU';
import { useTranslation } from "react-i18next";

const Create = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        props.setLoading(true);
        axiosPrivate.post('/audits/create').then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
                const audit = res.data.data;
                navigate('/warehouse/audits/'+audit.id);
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('AUDIT')}
            actionbtntext={t('START')}
            actionbtnclassname={"bg-green-400 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
            <div className="d-flex flex-column align-items-center my-2">
                <span className="medium fg-red-300 font-18 mb-2">{t('START_AUDIT_CONFIRMATION')}</span>
                <span className="fg-dark me-1">{t('START_AUDIT_CONFIRMATION_DESC')}</span>
            </div>
        </ModalU>
    );
}

export default Create