import { useTranslation } from 'react-i18next';

const Months = () => {
    const { t } = useTranslation();

    const MONTHS = new Map([
        ['01', t('JANUARY')], ['02', t('FEBRUARY')], ['03', t('MARCH')], ['04', t('APRIL')], ['05', t('MAY')], ['06', t('JUNE')],
        ['07', t('JULY')], ['08', t('AUGUST')], ['09', t('SEPTEMBER')], ['10', t('OCTOBER')], ['11', t('NOVEMBER')], ['12', t('DECEMBER')], 
    ]);

    return MONTHS;
};

export default Months;