import React from "react";
import { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import useSignOut from '../../../hooks/useSignOut';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from "react-bootstrap";
import Keys from "../../../utils/constants";
import { useTranslation } from "react-i18next";

const DropdownSignout = () => {
  const { auth } = useAuth();
  const signOut = useSignOut();
  const { t } = useTranslation();

  const [username, setUsername] = useState('');

  useEffect(() => {
    if (auth.token != undefined && auth.token !== Keys.UNAUTHORIZED) {
        setUsername(auth.user.firstName);
    }
  }, [auth]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div className="d-flex flex-row align-items-center rounded-2 px-2 bg-light hover-cursor"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}>
      <div className="d-flex flex-row bg-white rounded-1 px-3">
          <span className="medium fg-sp-primary me-2">{t('CASHIER')}</span>
          <span className="medium">{username}</span>
      </div>
    </div>
  ));

  return (
    <Dropdown className="large">
      <Dropdown.Toggle as={CustomToggle}/>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => {signOut()}}>
          <div className="d-flex align-items-center justify-content-between bg-red-400 bg-gradient rounded-2 py-1 px-3">
            <span className="text-white">{t('EXIT')}</span>
            <FontAwesomeIcon className='text-white' icon={faArrowRightFromBracket}/>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownSignout;