import { useTranslation } from 'react-i18next';

const WeekDays = () => {
    const { t } = useTranslation();

    const WEEK_DAYS = new Map([
        [1, t('MONDAY')], [2, t('TUESDAY')], [3, t('WEDNESDAY')], [4, t('THURSDAY')], [5, t('FRIDAY')], [6, t('SATURDAY')], [7, t('SUNDAY')]
    ]);

    return WEEK_DAYS;
};

export default WeekDays;