import { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { faSearch, faArrowRotateRight, faAdd } from "@fortawesome/free-solid-svg-icons";
import Utils from "../../../../../utils";
import InvoiceWrapper from "../InvoiceWrapper";
import InputField from "../../../../../components/form/InputField";
import TableStd from '../../../../../components/ui/TableStd';
import PaginationU from '../../../../../components/ui/PaginationU';
import LimitU from '../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../components/ui/DataNotFound';
import ButtonU from "../../../../../components/ui/ButtonU";
import SortU from '../../../../../components/ui/SortU';
import Filter from "./Filter";
import ToastU from "../../../../../components/ui/ToastU";
import { useTranslation } from "react-i18next";
import Keys from "../../../../../utils/constants";

const InvoiceReturns = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [invoiceItemReturns, setInvoiceItemReturns] = useState([]);
    const [invoiceItemsCount, setInvoiceItemsCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['createdAt', 'DESC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('INVOICE'), link: '/invoice/invoices' } ,
        { title: t('INVOICE_RETURNS'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getInvoiceItemReturns();
        }
    }, [ready, query]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }
    
    const getInvoiceItemReturns = () => {
        setLoading(true);
        axiosPrivate.get('/invoice-item-returns', { params: query }).then((res) => {
            if (res.status == 200) {
                setInvoiceItemReturns(res.data.data);
                setInvoiceItemsCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery({...query, filter: { productName: find }});
    }, [find]);

    const createNew = () => { navigate('/invoice/invoice-returns/create'); }
    
    const refresh = () => { getInvoiceItemReturns() }

    const modelProps = [ 
        {
            name: t('BAR_CODE'),
            value: (prop) => { return(<>{prop?.invoiceItem?.product?.barcode}</>) }
        },
        {
            name: t('PRODUCT'),
            value: (prop) => { return(<Link to={`/product/products/${prop?.invoiceItem?.product?.id}`} className="no-decor">{prop?.invoiceItem?.product?.name}</Link>) }
        },
        {
            name: `${t('INVOICE')} ${t('NUMBER')}`,
            value: (prop) => { return(<Link to={`/invoice/invoices/${prop?.invoiceItem?.invoice?.id}`} className="no-decor">{prop?.invoiceItem?.invoice?.number}</Link>) }
        },
        {
            name: <SortU title={t('QUANTITY')} attr={'quantity'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.quantity?.toFixed(2)} {prop?.invoiceItem?.product?.unit?.name}</>) }
        },
        {
            name: t('UNIT_PRICE'),
            value: (prop) => { return(<>{prop?.invoiceItem?.unitPrice?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: t('SELLING_PRICE'),
            value: (prop) => { return(<>{prop?.invoiceItem?.sellingPrice?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: `${t('TOTAL')} ${t('UNIT_PRICE')}`,
            value: (prop) => { return(<>{(prop?.quantity * prop?.invoiceItem?.unitPrice).toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: <SortU title={t('DEFINITION')} attr={'description'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.description ? prop.description : '-'}</>) }
        },
        {
            name: <SortU title={t('REGISTRATION_DATE')} attr={'createdAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.createdAt)}</>) }
        },
    ];

    return (
        <InvoiceWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('INVOICE_RETURNS')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('PRODUCT')} ${t('NAME')}, ${t('BARCODE')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    <ButtonU onSubmit={createNew} className={'border-success fg-green-500'} icon={faAdd} text={t('ADD')}/>
                </div>
            </div>
            { invoiceItemsCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={invoiceItemReturns} count={invoiceItemsCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={invoiceItemsCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            <ToastU toast={toast} setToast={setToast}/>
        </InvoiceWrapper>
    );
}

export default InvoiceReturns;