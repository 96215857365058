import { useEffect, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import Utils from "../../../../utils";
import MainWrapper from "../../layouts/MainWrapper";
import SideBar from "../../../../layouts/SideBar";
import { useTranslation } from "react-i18next";
import Keys from "../../../../utils/constants";
import icCube from './../../../../assets/svg/ic_cube_outline.svg';

const ProductsWrapper = ({...props}) => {
    const { t } = useTranslation();
    const { ready, auth } = useAuth();
    const [allowed, setAllowed] = useState(false);

    useEffect(() => {
        if (ready) setAllowed(Utils.allowedRole(auth, '1001', 'read'));
    }, [ready, allowed]);

    const items = [
        { name: t('PRODUCTS'), id: "products", icon: icCube, links: Keys.PRODUCT_PRODUCTS_LINKS },
    ];

    return (
        <MainWrapper loading={props.loading} breadcrumbs={props.breadcrumbs}>
            {allowed && <div className="main-page">
                <SideBar items={items}/>
                <div className="body-page">
                    { props.children }
                </div>
            </div>}
        </MainWrapper>
    );
};

export default ProductsWrapper;