import { useTranslation } from "react-i18next";

const Status = ({...props}) => {
    const { t } = useTranslation();
    const status = props.status;
    const size = props.size;
    let style = '';

    if (status) style='fg-green-500'
    else style='fg-red-300'

    return (
        <span className={`rounded-1 medium ${size} ${style}`}>
            {status ? t('ACTIVE') : t('INACTIVE')}
        </span>
    );
}

export default Status;