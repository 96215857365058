import { useEffect, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import Utils from "../../../../utils";
import Wrapper from "../../../../layouts/Wrapper";
import SideBarData from "../../layouts/SideBarData";
import { useTranslation } from "react-i18next";
import Keys from "../../../../utils/constants";
import icObjectsColumn from "./../../../../assets/svg/ic_objects_column.svg";
import icUserLockAlt from "./../../../../assets/svg/ic_user_lock_alt.svg";

const DataWrapper = (props) => {
    const { t } = useTranslation();
    const { ready, auth } = useAuth();

    let items = [
        { name: t('GENERAL'), id: "1006", icon: icObjectsColumn, links: Keys.SETTINGS_DATA_GENERAL_LINKS},
        { name: t('ADMINISTRATION'), id: "1007", icon: icUserLockAlt, links: Keys.SETTINGS_DATA_ADMINISTRATION_LINKS},
    ];

    const [allowedItems, setAllowedItems] = useState([]);

    useEffect(() => {
        if (!ready) return;
        setAllowedItems(items.filter(item => Utils.allowedRole(auth, item.id, 'read')));
    }, [auth]);

    return (
        <Wrapper loading={props.loading} breadcrumbs={props.breadcrumbs}>
            <div className="main-page-settings">
                <SideBarData items={allowedItems}/>
                <div className="body-page-settings">
                    { props.children }
                </div>
            </div>
        </Wrapper>
    );
};

export default DataWrapper;