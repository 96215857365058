import { useEffect, useState } from "react";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../../components/ui/ModalU';
import InputLabeled from "../../../../../../components/form/InputLabeled";
import FoundProducts from "../../../../../Main/pages/Invoice/components/FoundProducts";
import { useTranslation } from "react-i18next";

const SearchProduct = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    
    const [productName, setProductName] = useState('');

    const [product, setProduct] = useState({});
    
    const [products, setProducts] = useState([]);

    const [query, setQuery] = useState({
        limit:0, order: [['createdAt', 'ASC']], filter: { name: '', quantityLb: 0.1 }
    });

    useEffect(() => {
        getProducts();
    }, [query]);

    useEffect(() => {
        if (productName.length < 3) {
            setProducts([]);
            return;
        }
        if (productName) {
            setQuery({
                ...query, limit: 50,
                filter: {...query.filter, name: productName}
            });
        }
        
    }, [productName]);

    useEffect(() => {
        if (!product.id) return;
        props.setBarcode(product.barcode)
        setProductName(product.id && `${product.name} [${t('BAR_CODE')}: ${product.barcode}]`);
        setProducts([]);
    }, [product]);

    const getProducts = () => {
        axiosPrivate.get('/products', { params: query }).then((res) => {
            if (res.status == 200) setProducts(res.data.data);
        });
    }

    const handleSubmit = () => {
        props.add(product.barcode);
        props.setModalShow(false);
        setProductName('');
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('PRODUCT')}
            actionbtntext={t('ADD')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <div className="col-md-12">
                    <InputLabeled label={`${t('PRODUCT')}/${t('BAR_CODE')}`} type={'text'} value={productName} setValue={setProductName} hint={`${t('PRODUCT')} ${t('NAME')}, ${t('BAR_CODE')}`} required={true}/>
                    <FoundProducts items={products} setItem={setProduct}/>
                </div>
            </form>
        </ModalU>
    );
}

export default SearchProduct