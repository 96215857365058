import { useState } from 'react';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import TableStd from '../../../../../../../components/ui/TableStd';
import { useTranslation } from "react-i18next";
import Edit from './Edit';
import Actions from "../../../../../../../components/ui/Actions";
import Chekbox from "@mui/material/Checkbox";

const InvoiceItems   = ({...props}) => {
    const { t } = useTranslation();

    const items = props.items;

    const [selectAll, setSelectAll] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);

    const [itemToBe, setItemToBe] = useState({});

    const handleSelectAll = (e) => {
        const flag = e.target.checked;
        setSelectAll(flag);

        const updatedItems = props.items.map((item) => {
            return {...item, checked: flag};
        });
        props.setItems(updatedItems);
    }

    const modelProps = [ 
        {
            name: t('BAR_CODE'),
            value: (prop) => {
                return(
                    <div className='d-flex flex-row align-items-center'>
                        <Chekbox checked={prop.checked} size="small" className='py-0'
                            onChange={(e) => {
                                const updatedItems = props.items.map((item) => {
                                    if (item.productId == prop.productId)
                                        return {...item, checked: e.target.checked};
                                    return item;
                                });
                                props.setItems(updatedItems);
                            }}/>
                        <span>{prop.product.barcode}</span>
                    </div>
                );
            }
        },
        { 
            name: t('PRODUCT'),
            value: (prop) => { return(<>{prop?.product?.name}</>) } 
        },
        {
            name: t('STOCK'),
            value: (prop) => { return(<>{parseFloat(prop.stock)}</>) }
        },
        {
            name: t('QUANTITY'),
            value: (prop) => { return(<>{parseFloat(prop.quantity)}</>) }
        },
        {
            name: t('UNIT'),
            value: (prop) => { return(<>{prop?.product?.unit?.shortName}</>) }
        },
        {
            name: "",
            value: (prop) => {
                const options =[
                    {
                        icon: faPenToSquare,
                        style: "bg-blue-700 text-white",
                        onClick: () => {
                            setItemToBe(prop);
                            setModalEdit(true);
                        }
                    }
                ]
                return (<Actions items={options}/>)
            }
        }
    ];

    return (
        <div className='d-flex flex-column bg-red-50 p-1'>
            <div className='d-flex flex-row align-items-center bg-light mb-2'>
                <Chekbox checked={selectAll} onChange={handleSelectAll} size="small" className='py-0'/>
                <span className='font-12 medium'>{t('ALL')}</span>
            </div>
            <TableStd modelProps={modelProps} data={items}/>
            { itemToBe.product && 
                <Edit
                    modalShow={modalEdit}
                    setModalShow={setModalEdit}
                    item={itemToBe}
                    items={props.items}
                    setItems={props.setItems}
                />
            }
        </div>
    )
}

export default InvoiceItems;