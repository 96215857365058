import { Link } from "react-router-dom";
import Config from "../../config";
import icLogo from "./../../assets/svg/ic_logo.png";
import DropdownLang from "./../../layouts/DropdownLang";

const Wrapper = (props) => {
    return (
        <div>
            <div className="col-12 login-header py-2">
                <Link to={'/auth/signin'} className="no-decor d-flex flex-row align-items-center ms-3">
                    <img src={icLogo} alt="logo" className="wh-50 me-3 hover-cursor"/>
                    <span className="fs-4 fg-dark bold ">{Config.APP_NAME}</span>
                </Link>
                <div className="d-flex flex-row align-items-center">
                    <DropdownLang/>
                    <span className="font-14">v{Config.VERSION}</span>
                </div>
            </div>
            <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center bg-gray-50">
                { props.children }
            </div>
        </div>
    );
}

export default Wrapper;