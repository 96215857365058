import { FormCheck } from "react-bootstrap";
import icPrinter from "../../../../../../assets/svg/ic_printer.svg";

const PrintReceiptCheckBox = ({...props}) => {
    const handleCheck = (event) => {
        console.log(event.target.checked)
        props.setValue(event.target.checked);
    }

    return (
        <div className="card-default">
            <div className={`rounded-1 pb-1 mb-1 mx-1 ${props.value ?' bg-blue-700' : 'bg-gray-200'}`}>
                <div className={`rounded-1 ${props.value ?' bg-blue-500' : 'bg-white'}`}>
                    <label className="d-flex align-items-center justify-content-between px-2 py-1 my-1">
                        <FormCheck 
                            value={props.value}
                            name={'printreceipt'}
                            type={'checkbox'}
                            checked={props.value}
                            onChange={handleCheck}
                        />
                        <span className="small-x medium ms-1">[Insert]</span>
                        <img src={icPrinter} alt="printer" className="wh-25 hover-cursor"/>
                    </label>
                </div>
            </div>
        </div>
    );
}

export default PrintReceiptCheckBox;