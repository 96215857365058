import { useTranslation } from 'react-i18next';

const PaymentTypes = () => {
    const { t } = useTranslation();

    const PAYMENT_TYPES = new Map([
        ['CASH', t('CASH')], ['CARD', t('CARD')], ['CASH+CARD', t('CASH_CARD')]
    ]);

    return PAYMENT_TYPES;
};

export default PaymentTypes;