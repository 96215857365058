import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const Instructions = ({...props}) => {
    return (
        <div style={{position: "absolute", zIndex: "900"}}>
            <p id={props.id} className={props.attrFocus && props.attr && !props.validAttr ? "instructions" : "hide"}>
                <FontAwesomeIcon className="me-2" icon={faInfoCircle} />
                {props.attrInstruction}
            </p>
        </div>
    );
}

export default Instructions;