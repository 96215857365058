import { useEffect, useState } from "react";

const InputLabelIcon = ({...props}) => {

    const [open, setOpen] = useState(false);
    const [inputType, setInputType] = useState(props.type);

    const handleChange = (event) => {
        event.preventDefault();
        props.setValue(event.target.value);
    }

    const toggleEye = () => { setOpen(!open); }

    useEffect(() => {
        setInputType(open ? 'text' : props.type);
    }, [open]);

    return (
        <div className={`${props.formClass}`}>
            <label htmlFor={props.id} className={`font-14 mb-1 medium ${props.labelClass}`}>{props.label}</label>
            <div className="input-field-container">
                <img src={props.icon} className={`input-field-icon ${props.iconClass}`} />
                <input
                    id={props.id}
                    type={inputType}
                    name={props.id}
                    ref={props.ref}
                    value={props.value}
                    onChange={handleChange}
                    className={`input-field-outline ${props.inputClass}`}
                    placeholder={props.hint}
                    disabled={props.disabled}
                    autoComplete={props.autoComplete}
                    autoFocus={props.autoFocus}
                    required={props.required}/>
                {props.iconRight && 
                    <img src={props.iconRight} onClick={toggleEye} className={`input-field-icon-right ${props.iconClass}`} />
                }
            </div>
        </div>
    );
}

export default InputLabelIcon;