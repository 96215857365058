import { useEffect, useState } from "react";
import Utils from "../../../utils";
import DropdownSignout from "./DropdownSignout";

const TopStatusBar = () => {
    const [date, setDate] = useState(Utils.getCurrentDate());
    const [time, setTime] = useState(Utils.getCurrentTime());

    useEffect(() => {
        setInterval(() => {
            setDate(Utils.getCurrentDate());
            setTime(Utils.getCurrentTime());
          }, 30000);
    }, []);

    return (
        <div className="d-flex justify-content-center">
            <div className="top-status-bar">
                <span className="text-white medium font-14">{date}</span>
                <span className="text-white bold font-18">{time}</span>
                <DropdownSignout />
            </div>
        </div>
    );
}

export default TopStatusBar;