import { useEffect, useState } from "react";
import useAuth from '../../../../../hooks/useAuth';
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import TableStd from '../../../../../components/ui/TableStd';
import DataNotFound from '../../../../../components/ui/DataNotFound';
import ButtonU from "../../../../../components/ui/ButtonU";
import Create from "./Create";
import Update from "./Update";
import Delete from "./Delete";
import Actions from "../../../../../components/ui/Actions";
import { useTranslation } from "react-i18next";

const AppPasswords = ({...props}) => {
    const { t } = useTranslation();
    const { ready, auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    
    const [appPasswords, setAppPasswords] = useState([]);
    const [appPasswordsCount, setAppPasswordsCount] = useState();

    const [modalCreate, setModalCreate] = useState(false);

    const [itemToBe, setItemToBe] = useState({});
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    
    useEffect(() => {
        if (ready) {
            getAppPasswords();
        }
    }, [ready, props.toast]);

    const getAppPasswords = () => {
        props.setLoading(true);
        axiosPrivate.post('/auth/app-passwords/'+auth.user.id).then((res) => {
            if (res.status == 200) {
                const appPasswords = res.data.data.appPasswords;
                if (appPasswords) {
                    setAppPasswords(appPasswords);
                    setAppPasswordsCount(appPasswords.length);
                }
            }
        }).finally(() => {props.setLoading(false)});
    }

    const createNew = () => { setModalCreate(true); }

    const modelProps = [
        { 
            name: t('NAME'),
            value: (prop) => { return(<>{prop.name}</>) } 
        },
        {
            name: t('PASSWORD'),
            value: (prop) => { return(<>{prop.password}</>) }
        },
        {
            name: "",
            value: (prop) => {
                const options =[
                    {
                        icon: faPenToSquare,
                        style: "bg-blue-700 text-white",
                        onClick: () => {
                            setItemToBe(prop);
                            setModalUpdate(true);
                        }
                    },
                    {
                        icon: faTrashCan,
                        style: "bg-red-400 text-white",
                        onClick: () => { 
                            setItemToBe(prop);
                            setModalDelete(true);
                        }
                    }
                ]
                return (<Actions items={options}/>)
            }
        }
    ];

    return (
        <div className="mt-4">
            <div className="d-flex flex-row align-items-center justify-content-between">
                <span className="fs-5 medium fg-dark">{t('APP_PASSWORDS')}</span>
                <ButtonU onSubmit={createNew} className={'bg-green-700 rounded-3 text-white'} icon={faAdd}/>
            </div>
            <div className="bg-light rounded-3 border border-gray mt-3">
                {appPasswordsCount ? <div className="mt-2">
                    <TableStd modelProps={modelProps} data={appPasswords}/>
                </div>
                : <DataNotFound/>}
            </div>
            <Create
                modalShow={modalCreate}
                setModalShow={setModalCreate}
                toast={props.toast}
                setToast={props.setToast}
                setLoading={props.setLoading}
            />
            <Update
                modalShow={modalUpdate}
                setModalShow={setModalUpdate}
                toast={props.toast}
                setToast={props.setToast}
                item={itemToBe}
                setLoading={props.setLoading}
            />
            <Delete
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                toast={props.toast}
                setToast={props.setToast}
                item={itemToBe}
                setLoading={props.setLoading}
            />
        </div>
    )
}

export default AppPasswords;