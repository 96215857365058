import ReactApexChart from 'react-apexcharts';
import { useTranslation } from "react-i18next";
import Keys from '../../../../../utils/constants';

const SaleRadialBarChart = ({...props}) => {
  const { t } = useTranslation();

  const avg = props.avgDailySales;
  const sum = props.sumTotalSale;

  const series = [(sum/avg*100)];

  const options = {
    chart: {
      height: 350,
      type: 'radialBar',
      toolbar: {
        show: true
      }
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
         hollow: {
          margin: 0,
          size: '70%',
          background: '#fff',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24
          }
        },
        track: {
          background: '#fff',
          strokeWidth: '67%',
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35
          }
        },
    
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: '#888',
            fontSize: '17px'
          },
          value: {
            formatter: function(val) {
              return parseInt(val || 0) + '%';
            },
            color: '#111',
            fontSize: '36px',
            show: true,
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#ABE5A1'],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    title: {
      text: `${t('TODAY')} - ${t('SALE')}`,
      style: {
        color:  '#505050'
      },
    },
    stroke: {
      lineCap: 'round'
    },
    labels: [props?.sumTotalSale?.toFixed(2) || 0],
  };



  return (
    <div className="col-md-6" id="chart">
      <div className='d-flex flex-column shadow-sm p-3'>
        <ReactApexChart options={options} series={series} type="radialBar" height={230}/>
        <div className='d-flex flex-row justify-content-center'>
          <div>
            <span className='fg-dark font-14'>{t('AVG_DAILY_SALES')}</span>
            <span className='fg-dark font-14 medium ms-2'>{props?.avgDailySales?.toFixed(2)} {Keys.TMT}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaleRadialBarChart;