import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsTk from './locales/tk.json';
import translationsRu from './locales/ru.json';
import translationsEn from './locales/en.json';

const locale = localStorage.getItem('locale');
const selected = locale ? JSON.parse(locale) : { lng: 'tk', code: 'TM'};

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationsEn },
    tk: { translation: translationsTk },
    ru: { translation: translationsRu }
  },
  lng: selected.lng, // default language
  fallbackLng: selected.lng, // fallback language
  interpolation: { escapeValue: false }
});

export default i18n;